import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './index.css';
import './terminalBg.css';
import './loading.css';
import reportWebVitals from './reportWebVitals';
import { Web3Provider } from './web3';
import { GraphQLProvider } from './graphql';
import StrategyProvider from 'contexts/strategyContext_new';
import AppDos from './AppDos';
import { CacheProvider } from 'data';

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <Suspense fallback='...'>
        <Web3Provider>
          <GraphQLProvider>
            <CacheProvider>
              <StrategyProvider>
                <AppDos />
              </StrategyProvider>
            </CacheProvider>
          </GraphQLProvider>
        </Web3Provider>
      </Suspense>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
