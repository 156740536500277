import { useState, useEffect } from 'react';
import { BigNumber, constants } from 'ethers';
import { useWeb3 } from '../web3';
import { IERC20MetadataUpgradeable, IWeth9 } from '../assets/typechain';
import { TypedEvent, TypedEventFilter } from '../assets/typechain/common';

const useUserBalances = (
  tokens: string[] | undefined,
  wethContract: IWeth9 | undefined,
  etherBalance: BigNumber,
  tokenContracts: { [address: string]: IERC20MetadataUpgradeable } | undefined
) => {
  const { account } = useWeb3();

  const [tokenBalances, setTokenBalances] = useState<
    { [address: string]: BigNumber } | undefined
  >();
  useEffect(() => {
    if (!account || !tokens || !wethContract || !tokenContracts) {
      setTokenBalances(undefined);
      return;
    }

    interface FilterListener {
      contract: IERC20MetadataUpgradeable;
      filter: TypedEventFilter<
        TypedEvent<
          [string, string, BigNumber],
          { from: string; to: string; value: BigNumber }
        >
      >;
      callback: (_: string, __: string, value: BigNumber, ___: any) => void;
    }
    const filterListeners: FilterListener[] = [];

    const increaseBalance = (
      tokens: { [address: string]: BigNumber } | undefined,
      address: string,
      increaseValue: BigNumber
    ) => {
      if (!tokens) return tokens;
      const allTokens = Object.assign({}, tokens);
      allTokens[address] = allTokens[address].add(increaseValue);
      return allTokens;
    };

    const decreaseBalance = (
      tokens: { [address: string]: BigNumber } | undefined,
      address: string,
      decreaseValue: BigNumber
    ) => {
      if (!tokens) return tokens;
      const allTokens = Object.assign({}, tokens);
      allTokens[address] = allTokens[address].sub(decreaseValue);
      return allTokens;
    };

    const increaseFilterCallback = (address: string) => {
      return (_: string, __: string, value: BigNumber, ___: any) => {
        return setTokenBalances((tokens) =>
          increaseBalance(tokens, address, value)
        );
      };
    };

    const decreaseFilterCallback = (address: string) => {
      return (_: string, __: string, value: BigNumber, ___: any) => {
        return setTokenBalances((tokens) =>
          decreaseBalance(tokens, address, value)
        );
      };
    };

    const increaseWethFilterCallback = (
      _: string,
      value: BigNumber,
      __: any
    ) => {
      return setTokenBalances((tokens) => {
        if (!tokens) return tokens;
        const allTokens = Object.assign({}, tokens);
        allTokens[wethContract.address.toLowerCase()] =
          allTokens[wethContract.address.toLowerCase()].add(value);
        return allTokens;
      });
    };

    const decreaseWethFilterCallback = (
      _: string,
      value: BigNumber,
      __: any
    ) => {
      return setTokenBalances((tokens) => {
        if (!tokens) return tokens;
        const allTokens = Object.assign({}, tokens);
        allTokens[wethContract.address.toLowerCase()] =
          allTokens[wethContract.address.toLowerCase()].sub(value);
        return allTokens;
      });
    };

    Promise.all(
      tokens
        .filter((token) => tokenContracts[token] !== undefined)
        .map((token) =>
          Promise.all([token, tokenContracts[token].balanceOf(account)])
        )
    )
      .then((data) => {
        setTokenBalances(
          data.reduce((p, [address, balance]) => {
            p[address] = balance;
            return p;
          }, {} as { [address: string]: BigNumber })
        );
      })
      .catch(console.error);

    // Listeners if user connected
    if (account) {
      tokens
        .filter((token) => tokenContracts[token] !== undefined)
        .forEach((token) => {
          const increaseBalanceFilter = tokenContracts[token].filters.Transfer(
            null,
            account,
            null
          );
          const increaseBalanceListener = increaseFilterCallback(token);
          tokenContracts[token].on(
            increaseBalanceFilter,
            increaseBalanceListener
          );
          filterListeners.push({
            contract: tokenContracts[token],
            filter: increaseBalanceFilter,
            callback: increaseBalanceListener,
          });

          const decreaseBalanceFilter = tokenContracts[token].filters.Transfer(
            account,
            null,
            null
          );
          const decreaseBalanceListener = decreaseFilterCallback(token);
          tokenContracts[token].on(
            decreaseBalanceFilter,
            decreaseBalanceListener
          );
          filterListeners.push({
            contract: tokenContracts[token],
            filter: decreaseBalanceFilter,
            callback: decreaseBalanceListener,
          });
        });

      const increaseWethBalanceFilter = wethContract.filters.Deposit(
        account,
        null
      );
      wethContract.on(increaseWethBalanceFilter, increaseWethFilterCallback);

      const decreaseWethBalanceFilter = wethContract.filters.Withdrawal(
        account,
        null
      );
      wethContract.on(decreaseWethBalanceFilter, decreaseWethFilterCallback);

      return () => {
        filterListeners.forEach((listener) => {
          listener.contract.removeListener(listener.filter, listener.callback);
        });
        wethContract.removeListener(
          increaseWethBalanceFilter,
          increaseWethFilterCallback
        );
        wethContract.removeListener(
          decreaseWethBalanceFilter,
          decreaseWethFilterCallback
        );
      };
    }
  }, [account, tokens, wethContract, tokenContracts]);

  const [balances, setBalances] = useState<
    { [address: string]: BigNumber } | undefined
  >();
  useEffect(() => {
    setBalances(
      Object.assign({}, tokenBalances, {
        [constants.AddressZero]: etherBalance,
      })
    );
  }, [tokenBalances, etherBalance]);

  return balances;
};

export { useUserBalances };
