import { errorToast, infoToast } from 'components/utils/toastHelper';
import { ethers } from 'ethers';
import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

interface ProviderRpcError extends Error {
  message: string;
  code: number;
  data?: unknown;
}

const useTransaction = () => {
  const [pending, setPending] = useState(false);

  // TODO: remove this when we remove old components
  const contractCall = useCallback(
    (
      contractFn: () => Promise<ethers.ContractTransaction>,
      pendingMessage: string,
      failedMessage: string,
      successMessage: string,
      failedCallback?: () => void,
      successCallback?: () => void,
      completedCallback?: () => void
    ) => {
      setPending(true);
      let toastId: React.ReactText;
      toastId = toast(pendingMessage, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      });
      contractFn()
        .then((txResponse: ethers.ContractTransaction) => {
          const wait =
            process.env.NODE_ENV !== 'development'
              ? 0
              : process.env.REACT_APP_DEVELOPMENT_TX_WAIT_MS
              ? parseInt(process.env.REACT_APP_DEVELOPMENT_TX_WAIT_MS)
              : 0;

          return Promise.all([
            new Promise((resolve) =>
              setTimeout(() => resolve(null), wait)
            ).then(() => txResponse.wait()),
            toastId,
          ]);
        })
        .then(([txReceipt, toastId]) => {
          setTimeout(() => {
            setPending(false);
          }, 5000);
          toast.dismiss(toastId);
          if (txReceipt.status === 0) {
            toast.error(failedMessage);
            if (failedCallback) failedCallback();
          } else if (txReceipt.status === 1) {
            toast(successMessage);
            if (successCallback) successCallback();
          } else {
            toast.error('Not sure what happened with that transaction');
            if (failedCallback) failedCallback();
          }
          if (completedCallback) completedCallback();
        })
        .catch((error: ProviderRpcError) => {
          console.error(error);
          setPending(false);
          toast.dismiss(toastId);
          if (error.code !== 4001) {
            toast.error(
              "There was an error! Check your browser's console logs for more details."
            );
          }
          if (failedCallback) failedCallback();
        });
    },
    []
  );

  const contractCallNew = useCallback(
    (
      contractFn: () => Promise<ethers.ContractTransaction>,
      pendingMessage: string,
      failedToast: () => void,
      successToast: () => void,
      failedCallback?: () => void,
      successCallback?: () => void,
      completedCallback?: () => void
    ) => {
      setPending(true);
      let toastId: React.ReactText;
      toastId = infoToast(pendingMessage, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      });
      contractFn()
        .then((txResponse: ethers.ContractTransaction) => {
          const wait =
            process.env.NODE_ENV !== 'development'
              ? 0
              : process.env.REACT_APP_DEVELOPMENT_TX_WAIT_MS
              ? parseInt(process.env.REACT_APP_DEVELOPMENT_TX_WAIT_MS)
              : 0;

          return Promise.all([
            new Promise((resolve) =>
              setTimeout(() => resolve(null), wait)
            ).then(() => txResponse.wait()),
            toastId,
          ]);
        })
        .then(([txReceipt, toastId]) => {
          setTimeout(() => {
            setPending(false);
          }, 5000);
          toast.dismiss(toastId);
          if (txReceipt.status === 0) {
            failedToast();
            if (failedCallback) failedCallback();
          } else if (txReceipt.status === 1) {
            successToast();
            if (successCallback) successCallback();
          } else {
            errorToast('Not sure what happened with that transaction', {
              toastId: `unknown-transaction-error`,
            });
            if (failedCallback) failedCallback();
          }
          if (completedCallback) completedCallback();
        })
        .catch((error: ProviderRpcError) => {
          console.error(error);
          setPending(false);
          toast.dismiss(toastId);
          if (error.code !== 4001) {
            errorToast(
              "There was an error! Check your browser's console logs for more details.",
              { toastId: `rpc-failure-error` }
            );
          }
          if (failedCallback) failedCallback();
        });
    },
    []
  );

  return { contractCall, pending, contractCallNew };
};

export { useTransaction };
