/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IOlympusTokenMigrator,
  IOlympusTokenMigratorInterface,
} from "../../../contracts/interfaces/IOlympusTokenMigrator";

const _abi = [
  {
    inputs: [
      {
        internalType: "enum IOlympusTokenMigrator.TYPE",
        name: "_to",
        type: "uint8",
      },
    ],
    name: "migrateAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IOlympusTokenMigrator__factory {
  static readonly abi = _abi;
  static createInterface(): IOlympusTokenMigratorInterface {
    return new utils.Interface(_abi) as IOlympusTokenMigratorInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IOlympusTokenMigrator {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as IOlympusTokenMigrator;
  }
}
