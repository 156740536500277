import { MODULES } from '@0xnodes/shared/addresses';
import Button from 'components/ui/button/Button';
import Disclaimer from 'components/ui/disclaimer/Disclaimer';
import { ArrowPrefix } from 'components/ui/prefixes/Prefix';
import { errorToast, infoToast } from 'components/utils/toastHelper';
import { useTransaction } from 'data/transactions';
import { ethers } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { useModuleAddress, useTokenContract } from 'hooks/useContract';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useWeb3 } from '../../../web3';
import { IAssetWithAmount } from '../ManageModalContainer';

const ManageModalFooter = ({
  totalValue,
  assetsWithAmounts,
  buttonEnabled,
  onClick,
  noAvailableFunds,
  isStrategyManage,
  isDepositing,
  pending,
}: {
  totalValue: number;
  assetsWithAmounts: IAssetWithAmount[];
  buttonEnabled: boolean;
  onClick: () => void;
  noAvailableFunds: boolean;
  isStrategyManage: boolean;
  isDepositing: boolean;
  pending: boolean;
}) => {
  const { account, chainId } = useWeb3();
  const { pending: approvalPending, contractCallNew } = useTransaction();
  const { data: userPositionsAddress } = useModuleAddress(
    MODULES.userPositions
  );
  const queryClient = useQueryClient();

  const isPending = approvalPending || pending;
  const assetToApprove = isStrategyManage
    ? undefined
    : assetsWithAmounts.find((each) => !each.approved);

  const token = useTokenContract(assetToApprove?.address);

  const approveAllowance = () => {
    if (userPositionsAddress && assetToApprove && token && account && chainId) {
      const { address, symbol } = assetToApprove;
      contractCallNew(
        () => token.approve(userPositionsAddress, ethers.constants.MaxUint256),
        `Approving ${symbol}`,
        () =>
          errorToast(`Error approving ${symbol}`, {
            toastId: `approve-${symbol}-success`,
          }),
        () =>
          infoToast(`${symbol} approved`, {
            toastId: `approve-${symbol}-failed`,
          }),
        undefined,
        () => {
          setTimeout(() => {
            queryClient.invalidateQueries([
              chainId,
              account,
              'systemAllowance',
              address,
            ]);
          }, 3000);
        }
      );
    }
  };

  const text = `Total ${isDepositing ? 'Deposit' : 'Withdraw'}`;

  const disclaimerTitle = `No required funds located in user ${
    isStrategyManage ? `(_Kernel)` : `wallet`
  }`;

  const disclaimerDesc = `Deposit your funds to ${
    isStrategyManage ? `the user (_Kernel)` : `your wallet`
  } before allocating to ${
    isStrategyManage ? `strategies` : `the user (_Kernel)`
  }`;

  const approveText = `Approve ${assetToApprove?.symbol}`;
  const actionText = `${
    isDepositing ? 'Deposit assets to' : 'Withdraw assets from'
  } ${isStrategyManage ? 'strategy' : '(_Kernel)'}`;

  const [buttonText, buttonAction] = assetToApprove
    ? [approveText, approveAllowance]
    : [actionText, onClick];

  const totalDisplayClassName =
    totalValue === 0 ? 'text-gray-lighter' : 'text-green';

  return (
    <div className={`rounded-bl-md rounded-br-md grid grid-cols-1 gap-8`}>
      {isDepositing && noAvailableFunds ? (
        <Disclaimer>
          <div className='text-green'>{disclaimerTitle}</div>
          <div className='text-gray-light'>{disclaimerDesc}</div>
        </Disclaimer>
      ) : (
        <div>
          <ArrowPrefix>{text}</ArrowPrefix>
          <div className={`ml-4 text-xl ${totalDisplayClassName}`}>
            {/* {dollar(totalValue)} */}
            <ListOfAssets assetsWithAmounts={assetsWithAmounts} />
          </div>
        </div>
      )}
      {isStrategyManage && isDepositing && noAvailableFunds ? (
        <Link to={'/eleven/kernel'}>
          <Button
            secondary
            className=''
            // TODO: open kernel deposit page or modal?
            onClick={() => {}}
          >
            First deposit funds to the user (_Kernel)
          </Button>
        </Link>
      ) : (
        <Button
          disabled={isPending || !buttonEnabled}
          secondary
          className=''
          onClick={buttonAction}
        >
          {isPending ? 'Transaction Pending' : buttonText}
        </Button>
      )}
    </div>
  );
};

const ListOfAssets = ({
  assetsWithAmounts,
}: {
  assetsWithAmounts: IAssetWithAmount[];
}) => (
  <>
    {assetsWithAmounts
      .filter((each) => each.inputAmount.gt(0))
      .map(({ address, icon, symbol, inputAmount, decimals }, i) => (
        <span key={address}>
          {i ? ' + ' : ''}
          <span className={`whitespace-nowrap`}>
            {icon ? (
              <img
                width={16}
                src={icon}
                className={`mr-2 inline`}
                alt={`${symbol}-icon`}
              />
            ) : null}
            {formatUnits(inputAmount, decimals)}
            <span className='ml-2 text-white'>{symbol}</span>
          </span>
        </span>
      ))}
  </>
);

export default ManageModalFooter;
