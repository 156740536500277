import { BigNumber } from 'ethers';

import {
  ModuleMap,
  Kernel,
  UserPositionsV2,
  YieldManager,
  IntegrationMap,
  BiosRewards,
  EtherRewards,
  SushiSwapTrader,
  UniswapTrader,
  IERC20MetadataUpgradeable,
  IWeth9,
  IIntegration,
  UniswapV3Integration,
  StrategyMap,
  SushiSwapIntegration,
  SushiSwapIntegrationV2,
} from '../assets/typechain';

import {
  useCurrentBlockNumber,
  useCurrentGasPrice,
  useEtherBalance,
} from './blocks';

import {
  useModuleMapContract,
  useKernelContract,
  useUserPositionsContract,
  useYieldManagerContract,
  useIntegrationMapContract,
  useBiosRewardsContract,
  useEtherRewardsContract,
  useSushiSwapTraderContract,
  useUniswapTraderContract,
  useStrategyMapContract,
  useBiosTokenContract,
  useWethTokenContract,
  useSushiSwapIntegrationContract,
  useSushiSwapIntegrationV2Contract,
  useSushiSwapIntegrationV2AContract,
} from './contracts';

import {
  useAssetPrices,
  useGetEthPrice,
  useGetBiosPrice,
  useAssetValue,
} from './prices';

import {
  AssetType,
  useSupportedTokenContracts,
  useTotalBiosRewardWeight,
  useReserveRatioDenominator,
  useSupportedTokensLength,
  useSupportedTokensAddresses,
  useUserEtherBalance,
  useSupportedAssets,
  useAcceptingDeposits,
  useAcceptingWithdrawals,
  useBiosRewardWeights,
  useReserveRatioNumerators,
  useUserAllowances,
  useSupportedAssetSymbols,
  useSupportedAssetNames,
  useSupportedAssetDecimals,
  useSupportedTokenIntegrationWeightSums,
  useSupportedAssetTypes,
} from './tokens';

import {
  useSupportedIntegrationsLength,
  useSupportedIntegrationsAddresses,
  useSupportedIntegrationsContracts,
  useSupportedIntegrationsNames,
  useStrategyRatios,
} from './integrations';

import { TokenIntegrationWeight } from './weights';

import {
  useTokenBalancesSet,
  useKernelEthAmount,
  useKernelBiosAmount,
  useKernelReservesValue,
  useTotalDepositedAmount,
  useTotalDepositedValue,
  useGlobalTotalDepositedAmounts,
  useGlobalTotalValueLockedValue,
  useUserTotalDepositedAmounts,
  useUserTotalValueLockedValue,
  useAmountValues,
} from './assetBalances';

import { useUserBalances } from './userBalances';

import { useUserDeposits } from './userDeposits';

import {
  useGlobalTotalPaidEthRewardsAmount,
  useGlobalTotalPaidBiosRewardsAmount,
  useUserTotalEthRewardsAmount,
  useUserTotalBiosRewardsAmount,
  useUserTotalPaidEthRewardsAmount,
  useUserTotalPaidBiosRewardsAmount,
  useTotalGlobalBiosRewardRate,
  useTotalGlobalDepositedValue,
  useSecondsPerYear,
  useBiosApy,
  useLastEthRewardsAmount,
  useTotalEtherGeneratingDepositedValue,
  useEtherRewardsTimePeriod,
  useEtherApy,
  useBiosRewardEpochTimestamp,
} from './rewards';

import { useTreasuryAccountAddress } from './bulkProcessing';

import {
  IStrategy,
  useLastKernelDeployment,
  useStrategies,
  useUserStrategiesBalances,
} from './strategies';
import { useQuery } from 'react-query';
import axios from 'axios';

export interface DataResponse {
  userAuth: {
    roles: {
      admin: string | undefined;
      owner: string | undefined;
      manager: string | undefined;
    };
    isRole: {
      isAdmin: boolean | undefined;
      isOwner: boolean | undefined;
      isManager: boolean | undefined;
    };
    isRoleAdmin: {
      isAdminAdmin: boolean | undefined;
      isOwnerAdmin: boolean | undefined;
      isManagerAdmin: boolean | undefined;
    };
    allMembers: {
      admins: string[] | undefined;
      owners: string[] | undefined;
      managers: string[] | undefined;
    };
  };

  prices: {
    ethPrice: number;
    biosPrice: number;
    gasPrice: number;
  };

  tokens: {
    allAssets: string[] | undefined;
    onlyTokens: string[] | undefined;
    contracts: { [address: string]: IERC20MetadataUpgradeable } | undefined;
    acceptingDeposits: { [address: string]: boolean } | undefined;
    acceptingWithdrawals: { [address: string]: boolean } | undefined;
    biosRewardWeights: { [address: string]: BigNumber } | undefined;
    reserveRatioNumerators: { [address: string]: BigNumber } | undefined;
    symbols: { [address: string]: string } | undefined;
    prices: { [address: string]: number } | undefined;
    otherPrices: { [address: string]: number } | undefined;
    names: { [address: string]: string } | undefined;
    decimals: { [address: string]: number } | undefined;
    integrationWeightSums: { [address: string]: BigNumber } | undefined;
    types: { [address: string]: AssetType } | undefined;
    userDeposits: { [address: string]: BigNumber } | undefined;
    userAllowances: { [address: string]: BigNumber } | undefined;
    userBalances: { [address: string]: BigNumber } | undefined;
    totalDepositAmounts: { [address: string]: BigNumber } | undefined;
    totalDepositValues: { [address: string]: number } | undefined;
  };

  integrations: {
    addresses: string[] | undefined;
    contracts: { [address: string]: IIntegration } | undefined;
    names: { [address: string]: string } | undefined;
    ratios: { [address: string]: number } | undefined;
    totalIntegrationWeights: { [address: string]: BigNumber } | undefined;
  };

  tokenIntegrationWeights: TokenIntegrationWeight[] | undefined;

  systemAssetBalances: {
    reserveRatioDenominator: number;
    treasuryValue: number;
    reservesValue: number;
    global: {
      totalEthDepositedAmount: number;
      totalEthDepositedValue: number;
      totalBiosDepositedAmount: number;
      totalBiosDepositedValue: number;
      totalStablesDepositedAmount: number;
      totalStablesDepositedValue: number;
      totalAltsDepositedAmount: number;
      totalAltsDepositedValue: number;
      totalValueLockedValue: number;
    };
    user: {
      totalEthDepositedAmount: number;
      totalEthDepositedValue: number;
      totalBiosDepositedAmount: number;
      totalBiosDepositedValue: number;
      totalStablesDepositedAmount: number;
      totalStablesDepositedValue: number;
      totalAltsDepositedAmount: number;
      totalAltsDepositedValue: number;
      totalValueLockedValue: number;
    };
  };

  rewards: {
    global: {
      totalPaidEthRewardsAmount: number;
      totalPaidEthRewardsValue: number;
      totalPaidBiosRewardsAmount: number;
      totalPaidBiosRewardsValue: number;
      totalBiosRewardWeight: BigNumber;
      biosApy: number;
      etherApy: number;
      epochTimestamp: number;
    };
    user: {
      totalEthRewardsAmount: number;
      totalEthRewardsValue: number;
      totalBiosRewardsAmount: number;
      totalBiosRewardsValue: number;
      totalPaidEthRewardsAmount: number;
      totalPaidEthRewardsValue: number;
      totalPaidBiosRewardsAmount: number;
      totalPaidBiosRewardsValue: number;
    };
  };

  bulkProcessing: {
    gasAccount: {
      address: string;
      balance: number;
      target: number;
    };
    treasury: {
      address: string;
      balance: number;
    };
    ethDistributionWeights: {
      biosBuyBackEthWeight: number;
      treasuryEthWeight: number;
      protocolFeeEthWeight: number;
      rewardsEthWeight: number;
    };
    biosRewardsDuration: number;
    sushi: {
      slippageDenominator: number;
      slippageNumerator: number;
    };
  };

  lastKernelDeployTimestamp: number;

  uniswapV3: {
    integration: UniswapV3Integration | undefined;
    lpBaseStablecoin: IERC20MetadataUpgradeable | undefined;
    feeDenominator: number | undefined;
    slippageDenominator: number | undefined;
    pairs: { token0: string; token1: string }[] | undefined;
    pairsPools: { [pair: string]: number[] } | undefined;
    poolsSlippageNumerators: { [pool: string]: number } | undefined;
    poolsSlots: { [pool: string]: number } | undefined;
    poolsLPs:
      | { [pair: string]: { tickLower: number; tickUpper: number }[] }
      | undefined;
    lpsWeights: { [lp: string]: BigNumber } | undefined;
    lpsMinteds: { [lp: string]: boolean } | undefined;
    lpsIds: { [lp: string]: BigNumber } | undefined;
    lpsIndexes: { [lp: string]: number } | undefined;
  };

  strategies: {
    strategies: IStrategy[];
    userBalances:
      | { [strategyId: number]: { [address: string]: BigNumber } }
      | undefined;
  };

  currentBlockNumber: number;
  currentGasPrice: number;
}

const useSushiSwapIntegrationContracts = (
  integrationMapContract: IntegrationMap | undefined
) => {
  const sushiSwapIntegration = useSushiSwapIntegrationContract(
    integrationMapContract
  );
  const sushiSwapIntegrationV2 = useSushiSwapIntegrationV2Contract(
    integrationMapContract
  );
  const sushiSwapIntegrationV2A = useSushiSwapIntegrationV2AContract(
    integrationMapContract
  );
  return {
    sushiSwapIntegration,
    sushiSwapIntegrationV2,
    sushiSwapIntegrationV2A,
  };
};

export interface Data extends DataResponse {
  contracts: {
    moduleMap: ModuleMap | undefined;
    kernel: Kernel | undefined;
    userPositions: UserPositionsV2 | undefined;
    yieldManager: YieldManager | undefined;
    integrationMap: IntegrationMap | undefined;
    biosRewards: BiosRewards | undefined;
    etherRewards: EtherRewards | undefined;
    sushiSwapTrader: SushiSwapTrader | undefined;
    uniswapTrader: UniswapTrader | undefined;
    strategyMap: StrategyMap | undefined;
    biosToken: IERC20MetadataUpgradeable | undefined;
    wethToken: IWeth9 | undefined;
    sushiSwapIntegration: SushiSwapIntegration | undefined;
    sushiSwapIntegrationV2: SushiSwapIntegrationV2 | undefined;
    sushiSwapIntegrationV2A: SushiSwapIntegrationV2 | undefined;
  };
}

const dataDefaults: Data = {
  contracts: {
    moduleMap: undefined,
    kernel: undefined,
    userPositions: undefined,
    yieldManager: undefined,
    integrationMap: undefined,
    biosRewards: undefined,
    etherRewards: undefined,
    sushiSwapTrader: undefined,
    uniswapTrader: undefined,
    strategyMap: undefined,
    biosToken: undefined,
    wethToken: undefined,
    sushiSwapIntegration: undefined,
    sushiSwapIntegrationV2: undefined,
    sushiSwapIntegrationV2A: undefined,
  },

  userAuth: {
    roles: {
      admin: undefined,
      owner: undefined,
      manager: undefined,
    },
    isRole: {
      isAdmin: undefined,
      isOwner: undefined,
      isManager: undefined,
    },
    isRoleAdmin: {
      isAdminAdmin: undefined,
      isOwnerAdmin: undefined,
      isManagerAdmin: undefined,
    },
    allMembers: {
      admins: undefined,
      owners: undefined,
      managers: undefined,
    },
  },

  prices: {
    ethPrice: 0,
    biosPrice: 0,
    gasPrice: 0,
  },

  tokens: {
    allAssets: undefined,
    onlyTokens: undefined,
    contracts: undefined,
    acceptingDeposits: undefined,
    acceptingWithdrawals: undefined,
    biosRewardWeights: undefined,
    reserveRatioNumerators: undefined,
    symbols: undefined,
    prices: undefined,
    otherPrices: undefined,
    names: undefined,
    decimals: undefined,
    integrationWeightSums: undefined,
    types: undefined,
    userDeposits: undefined,
    userAllowances: undefined,
    userBalances: undefined,
    totalDepositAmounts: undefined,
    totalDepositValues: undefined,
  },

  integrations: {
    addresses: undefined,
    contracts: undefined,
    names: undefined,
    ratios: undefined,
    totalIntegrationWeights: undefined,
  },

  tokenIntegrationWeights: undefined,

  systemAssetBalances: {
    reserveRatioDenominator: 0,
    treasuryValue: 0,
    reservesValue: 0,
    global: {
      totalEthDepositedAmount: 0,
      totalEthDepositedValue: 0,
      totalBiosDepositedAmount: 0,
      totalBiosDepositedValue: 0,
      totalStablesDepositedAmount: 0,
      totalStablesDepositedValue: 0,
      totalAltsDepositedAmount: 0,
      totalAltsDepositedValue: 0,
      totalValueLockedValue: 0,
    },
    user: {
      totalEthDepositedAmount: 0,
      totalEthDepositedValue: 0,
      totalBiosDepositedAmount: 0,
      totalBiosDepositedValue: 0,
      totalStablesDepositedAmount: 0,
      totalStablesDepositedValue: 0,
      totalAltsDepositedAmount: 0,
      totalAltsDepositedValue: 0,
      totalValueLockedValue: 0,
    },
  },

  rewards: {
    global: {
      totalPaidEthRewardsAmount: 0,
      totalPaidEthRewardsValue: 0,
      totalPaidBiosRewardsAmount: 0,
      totalPaidBiosRewardsValue: 0,
      totalBiosRewardWeight: BigNumber.from(0),
      biosApy: 0,
      etherApy: 0,
      epochTimestamp: 0,
    },
    user: {
      totalEthRewardsAmount: 0,
      totalEthRewardsValue: 0,
      totalBiosRewardsAmount: 0,
      totalBiosRewardsValue: 0,
      totalPaidEthRewardsAmount: 0,
      totalPaidEthRewardsValue: 0,
      totalPaidBiosRewardsAmount: 0,
      totalPaidBiosRewardsValue: 0,
    },
  },

  bulkProcessing: {
    gasAccount: {
      address: '',
      balance: 0,
      target: 0,
    },
    treasury: {
      address: '',
      balance: 0,
    },
    ethDistributionWeights: {
      biosBuyBackEthWeight: 0,
      protocolFeeEthWeight: 0,
      rewardsEthWeight: 0,
      treasuryEthWeight: 0,
    },
    biosRewardsDuration: 0,
    sushi: {
      slippageDenominator: 0, //sushiSlippageDenominator,
      slippageNumerator: 0, //sushiSlippageNumerator,
    },
  },

  lastKernelDeployTimestamp: 0,

  uniswapV3: {
    integration: undefined, //uniswapIntegrationContract,
    lpBaseStablecoin: undefined, //uniswapBaseStablecoin,
    feeDenominator: undefined, //uniswapV3FeeDenominator,
    slippageDenominator: undefined, //uniswapV3SlippageDenominator,
    pairs: undefined, //uniswapV3Pairs,
    pairsPools: undefined, //uniswapV3PairPools,
    poolsSlots: undefined, //uniswapV3PoolsSlots,
    poolsSlippageNumerators: undefined, //uniswapV3PoolsSlippageNumerators,
    poolsLPs: undefined, // uniswapV3PoolsLPs,
    lpsWeights: undefined, //uniswapV3LPsWeights,
    lpsMinteds: undefined, // uniswapV3LPsMinteds,
    lpsIds: undefined, //uniswapV3LPsLPIDs,
    lpsIndexes: undefined, //uniswapV3LPsIndexes,
  },

  strategies: {
    strategies: [],
    userBalances: [],
  },

  currentBlockNumber: 0,
  currentGasPrice: 0,
};

const otherAssetTokens = [
  '0x383518188C0C6d7730D91b2c03a03C837814a899',
  '0x2e9d63788249371f1DFC918a52f8d799F4a38C94',
  '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
  '0x47252A63C723889814AeBcAC0683E615624ceC64',
  '0xccC8cb5229B0ac8069C51fd58367Fd1e622aFD97',
];
const otherAssetSymbols = {
  '0x383518188C0C6d7730D91b2c03a03C837814a899': 'OHM',
  '0x2e9d63788249371f1DFC918a52f8d799F4a38C94': 'TOKE',
  '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2': 'SUSHI',
  '0x47252A63C723889814AeBcAC0683E615624ceC64': 'NIL',
  '0xccC8cb5229B0ac8069C51fd58367Fd1e622aFD97': 'GODS',
};
const baseUrl =
  process.env.REACT_APP_API_BASE_URL ||
  'https://us-central1-system11-mainnet.cloudfunctions.net/';

const useCachedData = (chainId?: number) => {
  return useQuery(
    [chainId, 'system', 'data'],
    async () => {
      const { data } = await axios.get<DataResponse>(
        `${baseUrl}systemData?chainId=${chainId}`
      );
      return data;
    },
    {
      staleTime: 120000, // 2min
      enabled: !!chainId,
    }
  );
};

function useSystemData() {
  const currentBlockNumber = useCurrentBlockNumber();
  const currentGasPrice = useCurrentGasPrice();

  const moduleMapContract = useModuleMapContract();
  const kernelContract = useKernelContract(moduleMapContract);
  const userPositionsContract = useUserPositionsContract(moduleMapContract);
  const yieldManagerContract = useYieldManagerContract(moduleMapContract);
  const integrationMapContract = useIntegrationMapContract(moduleMapContract);
  const biosRewardsContract = useBiosRewardsContract(moduleMapContract);
  const etherRewardsContract = useEtherRewardsContract(moduleMapContract);
  const sushiSwapTraderContract = useSushiSwapTraderContract(moduleMapContract);
  const uniswapTraderContract = useUniswapTraderContract(moduleMapContract);
  const strategyMapContract = useStrategyMapContract(moduleMapContract);
  const biosTokenContract = useBiosTokenContract(integrationMapContract);
  const wethTokenContract = useWethTokenContract(integrationMapContract);

  const sushiSwapContracts = useSushiSwapIntegrationContracts(
    integrationMapContract
  );

  const reserveRatioDenominator = useReserveRatioDenominator(
    integrationMapContract
  );
  const supportedTokensLength = useSupportedTokensLength(
    integrationMapContract,
    kernelContract
  );
  const supportedTokensAddresses = useSupportedTokensAddresses(
    integrationMapContract,
    supportedTokensLength
  );
  const supportedAssets = useSupportedAssets(supportedTokensAddresses);
  const supportedTokenContracts = useSupportedTokenContracts(
    supportedTokensAddresses
  );
  const supportedAssetSymbols = useSupportedAssetSymbols(
    supportedTokenContracts
  );
  const supportedAssetNames = useSupportedAssetNames(supportedTokenContracts);
  const supportedAssetDecimals = useSupportedAssetDecimals(
    supportedTokenContracts
  );
  const supportedTokenIntegrationWeightSums =
    useSupportedTokenIntegrationWeightSums(
      supportedTokensAddresses,
      integrationMapContract
    );
  const userEtherBalance = useUserEtherBalance(currentBlockNumber);
  const supportedTokenAcceptingDeposits = useAcceptingDeposits(
    supportedTokensAddresses,
    integrationMapContract,
    wethTokenContract
  );
  const supportedTokenAcceptingWithdrawals = useAcceptingWithdrawals(
    supportedTokensAddresses,
    integrationMapContract,
    wethTokenContract
  );
  const supportedTokenBiosRewardWeights = useBiosRewardWeights(
    supportedTokensAddresses,
    integrationMapContract
  );
  const totalBiosRewardWeight = useTotalBiosRewardWeight(
    supportedTokensAddresses,
    supportedTokenBiosRewardWeights
  );
  const supportedTokenReserveRatioNumerators = useReserveRatioNumerators(
    supportedTokensAddresses,
    integrationMapContract
  );
  const supportedAssetTypes = useSupportedAssetTypes(
    supportedTokensAddresses,
    supportedTokenBiosRewardWeights,
    wethTokenContract,
    biosTokenContract
  );

  const supportedIntegrationsLength = useSupportedIntegrationsLength(
    integrationMapContract
  );
  const supportedIntegrationsAddresses = useSupportedIntegrationsAddresses(
    integrationMapContract,
    supportedIntegrationsLength
  );
  const supportedIntegrationsContracts = useSupportedIntegrationsContracts(
    supportedIntegrationsAddresses
  );
  const supportedIntegrationsNames = useSupportedIntegrationsNames(
    integrationMapContract,
    supportedIntegrationsContracts
  );

  const tokenIntegrationWeights = undefined; //useTokenIntegrationWeights(integrationMapContract, kernelContract, supportedIntegrationsAddresses, supportedTokensAddresses);
  const totalIntegrationWeights = {}; //useTotalIntegrationWeights(tokenIntegrationWeights);

  const supportedAssetPrices = useAssetPrices(
    supportedTokensAddresses,
    supportedAssetSymbols
  );
  const otherAssetPrices = useAssetPrices(otherAssetTokens, otherAssetSymbols);
  const ethPrice = useGetEthPrice(supportedAssetPrices);
  const biosPrice = useGetBiosPrice(supportedAssetPrices, biosTokenContract);

  const strategyRatios = useStrategyRatios(
    supportedTokensAddresses,
    supportedIntegrationsAddresses,
    supportedIntegrationsContracts,
    supportedAssetPrices,
    supportedAssetDecimals,
    kernelContract
  );

  const kernelEthBalance = useKernelEthAmount(kernelContract);
  const kernelEthValue = useAssetValue(kernelEthBalance, ethPrice);
  const kernelBiosBalance = useKernelBiosAmount(
    kernelContract,
    biosTokenContract
  );
  const kernelBiosValue = useAssetValue(kernelBiosBalance, biosPrice);
  const treasuryAddress = useTreasuryAccountAddress(
    yieldManagerContract,
    kernelContract
  );
  const treasuryEthBalance = useEtherBalance(
    treasuryAddress,
    currentBlockNumber
  );
  const treasuryEthValue = useAssetValue(treasuryEthBalance, ethPrice);
  const kernelReservesValue = useKernelReservesValue(
    supportedTokensAddresses,
    yieldManagerContract,
    supportedAssetPrices,
    supportedAssetDecimals
  );

  const globalTotalDepositedAmounts = useGlobalTotalDepositedAmounts(
    supportedTokensAddresses,
    userPositionsContract,
    kernelContract
  );
  const globalTotalDepositedValues = useAmountValues(
    globalTotalDepositedAmounts,
    supportedAssetDecimals,
    supportedAssetPrices
  );
  const globalTotalDepositedValue = useTotalDepositedValue(
    globalTotalDepositedAmounts,
    supportedAssetDecimals,
    supportedAssetPrices
  );
  const allGlobalEthBalances = useTokenBalancesSet(
    globalTotalDepositedAmounts,
    AssetType.ETH,
    supportedAssetTypes
  );
  const allGlobalBiosBalances = useTokenBalancesSet(
    globalTotalDepositedAmounts,
    AssetType.BIOS,
    supportedAssetTypes
  );
  const allGlobalStablesBalances = useTokenBalancesSet(
    globalTotalDepositedAmounts,
    AssetType.Stable,
    supportedAssetTypes
  );
  const allGlobalAltsBalances = useTokenBalancesSet(
    globalTotalDepositedAmounts,
    AssetType.Alt,
    supportedAssetTypes
  );
  const globalTotalEthDepositedAmount = useTotalDepositedAmount(
    allGlobalEthBalances,
    supportedAssetDecimals
  );
  const globalTotalEthDepositedValue = useTotalDepositedValue(
    allGlobalEthBalances,
    supportedAssetDecimals,
    supportedAssetPrices
  );
  const globalTotalBiosDepositedAmount = useTotalDepositedAmount(
    allGlobalBiosBalances,
    supportedAssetDecimals
  );
  const globalTotalBiosDepositedValue = useTotalDepositedValue(
    allGlobalBiosBalances,
    supportedAssetDecimals,
    supportedAssetPrices
  );
  const globalTotalStablesDepositedAmount = useTotalDepositedAmount(
    allGlobalStablesBalances,
    supportedAssetDecimals
  );
  const globalTotalStablesDepositedValue = useTotalDepositedValue(
    allGlobalStablesBalances,
    supportedAssetDecimals,
    supportedAssetPrices
  );
  const globalTotalAltsDepositedAmount = useTotalDepositedAmount(
    allGlobalAltsBalances,
    supportedAssetDecimals
  );
  const globalTotalAltsDepositedValue = useTotalDepositedValue(
    allGlobalAltsBalances,
    supportedAssetDecimals,
    supportedAssetPrices
  );
  const globalTotalValueLockedValue = useGlobalTotalValueLockedValue(
    kernelEthValue,
    kernelBiosValue,
    globalTotalBiosDepositedValue,
    globalTotalDepositedValue
  );

  const userTotalDepositedAmounts = useUserTotalDepositedAmounts(
    supportedTokensAddresses,
    userPositionsContract,
    kernelContract,
    strategyMapContract,
    currentBlockNumber
  );
  const allUserEthBalances = useTokenBalancesSet(
    userTotalDepositedAmounts,
    AssetType.ETH,
    supportedAssetTypes
  );
  const allUserBiosBalances = useTokenBalancesSet(
    userTotalDepositedAmounts,
    AssetType.BIOS,
    supportedAssetTypes
  );
  const allUserStablesBalances = useTokenBalancesSet(
    userTotalDepositedAmounts,
    AssetType.Stable,
    supportedAssetTypes
  );
  const allUserAltsBalances = useTokenBalancesSet(
    userTotalDepositedAmounts,
    AssetType.Alt,
    supportedAssetTypes
  );
  const userTotalEthDepositedAmount = useTotalDepositedAmount(
    allUserEthBalances,
    supportedAssetDecimals
  );
  const userTotalEthDepositedValue = useTotalDepositedValue(
    allUserEthBalances,
    supportedAssetDecimals,
    supportedAssetPrices
  );
  const userTotalBiosDepositedAmount = useTotalDepositedAmount(
    allUserBiosBalances,
    supportedAssetDecimals
  );
  const userTotalBiosDepositedValue = useTotalDepositedValue(
    allUserBiosBalances,
    supportedAssetDecimals,
    supportedAssetPrices
  );
  const userTotalStablesDepositedAmount = useTotalDepositedAmount(
    allUserStablesBalances,
    supportedAssetDecimals
  );
  const userTotalStablesDepositedValue = useTotalDepositedValue(
    allUserStablesBalances,
    supportedAssetDecimals,
    supportedAssetPrices
  );
  const userTotalAltsDepositedAmount = useTotalDepositedAmount(
    allUserAltsBalances,
    supportedAssetDecimals
  );
  const userTotalAltsDepositedValue = useTotalDepositedValue(
    allUserAltsBalances,
    supportedAssetDecimals,
    supportedAssetPrices
  );
  const userTotalValueLockedValue = useUserTotalValueLockedValue(
    userTotalEthDepositedValue,
    userTotalBiosDepositedValue,
    userTotalStablesDepositedValue,
    userTotalAltsDepositedValue
  );

  const userAllowances = useUserAllowances(
    userPositionsContract,
    supportedTokenContracts,
    wethTokenContract
  );
  const userBalances = useUserBalances(
    supportedTokensAddresses,
    wethTokenContract,
    userEtherBalance,
    supportedTokenContracts
  );
  const userDeposits = useUserDeposits(
    kernelContract,
    userPositionsContract,
    supportedTokensAddresses,
    wethTokenContract
  );

  const globalTotalPaidEthRewardsAmount = useGlobalTotalPaidEthRewardsAmount(
    kernelContract,
    etherRewardsContract
  );
  const globalTotalPaidEthRewardsValue = useAssetValue(
    globalTotalPaidEthRewardsAmount,
    ethPrice
  );
  const globalTotalPaidBiosRewardsAmount = useGlobalTotalPaidBiosRewardsAmount(
    kernelContract,
    biosRewardsContract,
    supportedTokensAddresses,
    supportedAssetDecimals,
    supportedAssetTypes
  );
  const globalTotalPaidBiosRewardsValue = useAssetValue(
    globalTotalPaidBiosRewardsAmount,
    biosPrice
  );
  const userTotalEthRewardsAmount = useUserTotalEthRewardsAmount(
    etherRewardsContract,
    kernelContract
  );
  const userTotalEthRewardsValue = useAssetValue(
    userTotalEthRewardsAmount,
    ethPrice
  );
  const userTotalBiosRewardsAmount = useUserTotalBiosRewardsAmount(
    biosRewardsContract,
    supportedTokensAddresses,
    supportedAssetDecimals,
    supportedAssetTypes
  );
  const userTotalBiosRewardsValue = useAssetValue(
    userTotalBiosRewardsAmount,
    biosPrice
  );
  const userTotalPaidEthRewardsAmount = useUserTotalPaidEthRewardsAmount(
    kernelContract,
    etherRewardsContract
  );
  const userTotalPaidEthRewardsValue = useAssetValue(
    userTotalPaidEthRewardsAmount,
    ethPrice
  );
  const userTotalPaidBiosRewardsAmount = useUserTotalPaidBiosRewardsAmount(
    kernelContract,
    biosRewardsContract,
    supportedTokensAddresses,
    supportedAssetDecimals,
    supportedAssetTypes
  );
  const userTotalPaidBiosRewardsValue = useAssetValue(
    userTotalPaidBiosRewardsAmount,
    biosPrice
  );

  const totalGlobalBiosRewardRateAmount = useTotalGlobalBiosRewardRate(
    biosRewardsContract,
    supportedTokensAddresses,
    kernelContract,
    supportedAssetDecimals,
    supportedAssetTypes
  );
  const totalGlobalBiosRewardRateValue = useAssetValue(
    totalGlobalBiosRewardRateAmount,
    biosPrice
  );
  const totalGlobalDepositedValue = useTotalGlobalDepositedValue(
    globalTotalEthDepositedValue,
    globalTotalBiosDepositedValue,
    globalTotalStablesDepositedValue,
    globalTotalAltsDepositedValue
  );
  const secondsPerYear = useSecondsPerYear();
  const biosApy = useBiosApy(
    totalGlobalBiosRewardRateValue,
    secondsPerYear,
    totalGlobalDepositedValue
  );
  const epochTimestamp = useBiosRewardEpochTimestamp(
    biosRewardsContract,
    supportedAssetNames
  );
  const lastEthRewardsAmount = useLastEthRewardsAmount(
    yieldManagerContract,
    kernelContract
  );
  const lastEthRewardsValue = useAssetValue(lastEthRewardsAmount, ethPrice);
  const totalEtherGeneratingDepositedValue =
    useTotalEtherGeneratingDepositedValue(
      globalTotalEthDepositedValue,
      globalTotalBiosDepositedValue,
      globalTotalStablesDepositedValue
    );
  const etherRewardsTimePeriod = useEtherRewardsTimePeriod(kernelContract);
  const etherApy = useEtherApy(
    lastEthRewardsValue,
    secondsPerYear,
    totalEtherGeneratingDepositedValue,
    etherRewardsTimePeriod
  );

  // const SAALMainPendingYield = useSAALMainPendingYield(
  //   sushiSwapIntegration,
  //   sushiSwapIntegrationV2,
  //   { ...supportedAssetPrices, ...otherAssetPrices }
  // );

  // const SAALGnbPendingYield = useSAALGnbPendingYield(sushiSwapIntegrationV2A, {
  //   ...supportedAssetPrices,
  //   ...otherAssetPrices,
  // });

  const { data: strategies = [] } = useStrategies();
  const userStrategyBalances = useUserStrategiesBalances(
    userPositionsContract,
    strategyMapContract,
    strategies
  );
  const lastKernelDeployTimestamp = useLastKernelDeployment(kernelContract);

  const dataOld = {
    // userAuth: {
    //   roles: {
    //     admin: undefined,
    //     owner: undefined,
    //     manager: undefined,
    //   },
    //   isRole: {
    //     isAdmin: undefined,
    //     isOwner: undefined,
    //     isManager: undefined,
    //   },
    //   isRoleAdmin: {
    //     isAdminAdmin: undefined,
    //     isOwnerAdmin: undefined,
    //     isManagerAdmin: undefined,
    //   },
    //   allMembers: {
    //     admins: undefined,
    //     owners: undefined,
    //     managers: undefined,
    //   },
    // },

    prices: {
      ethPrice,
      biosPrice,
      gasPrice: currentGasPrice,
    },

    tokens: {
      allAssets: supportedAssets,
      onlyTokens: supportedTokensAddresses,
      contracts: supportedTokenContracts,
      acceptingDeposits: supportedTokenAcceptingDeposits,
      acceptingWithdrawals: supportedTokenAcceptingWithdrawals,
      biosRewardWeights: supportedTokenBiosRewardWeights,
      reserveRatioNumerators: supportedTokenReserveRatioNumerators,
      symbols: supportedAssetSymbols,
      prices: supportedAssetPrices,
      otherPrices: otherAssetPrices,
      names: supportedAssetNames,
      decimals: supportedAssetDecimals,
      integrationWeightSums: supportedTokenIntegrationWeightSums,
      types: supportedAssetTypes,
      userDeposits,
      userAllowances,
      userBalances,
      totalDepositAmounts: globalTotalDepositedAmounts,
      totalDepositValues: globalTotalDepositedValues,
    },

    integrations: {
      addresses: supportedIntegrationsAddresses,
      contracts: supportedIntegrationsContracts,
      names: supportedIntegrationsNames,
      ratios: strategyRatios,
      totalIntegrationWeights,
    },

    tokenIntegrationWeights: tokenIntegrationWeights,

    systemAssetBalances: {
      reserveRatioDenominator,
      treasuryValue: treasuryEthValue,
      reservesValue: kernelReservesValue,
      global: {
        totalEthDepositedAmount: globalTotalEthDepositedAmount,
        totalEthDepositedValue: globalTotalEthDepositedValue,
        totalBiosDepositedAmount: globalTotalBiosDepositedAmount,
        totalBiosDepositedValue: globalTotalBiosDepositedValue,
        totalStablesDepositedAmount: globalTotalStablesDepositedAmount,
        totalStablesDepositedValue: globalTotalStablesDepositedValue,
        totalAltsDepositedAmount: globalTotalAltsDepositedAmount,
        totalAltsDepositedValue: globalTotalAltsDepositedValue,
        totalValueLockedValue: globalTotalValueLockedValue,
      },
      user: {
        totalEthDepositedAmount: userTotalEthDepositedAmount,
        totalEthDepositedValue: userTotalEthDepositedValue,
        totalBiosDepositedAmount: userTotalBiosDepositedAmount,
        totalBiosDepositedValue: userTotalBiosDepositedValue,
        totalStablesDepositedAmount: userTotalStablesDepositedAmount,
        totalStablesDepositedValue: userTotalStablesDepositedValue,
        totalAltsDepositedAmount: userTotalAltsDepositedAmount,
        totalAltsDepositedValue: userTotalAltsDepositedValue,
        totalValueLockedValue: userTotalValueLockedValue,
      },
    },

    rewards: {
      global: {
        totalPaidEthRewardsAmount: globalTotalPaidEthRewardsAmount,
        totalPaidEthRewardsValue: globalTotalPaidEthRewardsValue,
        totalPaidBiosRewardsAmount: globalTotalPaidBiosRewardsAmount,
        totalPaidBiosRewardsValue: globalTotalPaidBiosRewardsValue,
        totalBiosRewardWeight,
        biosApy,
        etherApy,
        epochTimestamp: epochTimestamp,
      },
      user: {
        totalEthRewardsAmount: userTotalEthRewardsAmount,
        totalEthRewardsValue: userTotalEthRewardsValue,
        totalBiosRewardsAmount: userTotalBiosRewardsAmount,
        totalBiosRewardsValue: userTotalBiosRewardsValue,
        totalPaidEthRewardsAmount: userTotalPaidEthRewardsAmount,
        totalPaidEthRewardsValue: userTotalPaidEthRewardsValue,
        totalPaidBiosRewardsAmount: userTotalPaidBiosRewardsAmount,
        totalPaidBiosRewardsValue: userTotalPaidBiosRewardsValue,
      },
    },

    bulkProcessing: {
      gasAccount: {
        address: '',
        balance: 0,
        target: 0,
      },
      treasury: {
        address: treasuryAddress,
        balance: treasuryEthBalance,
      },
      ethDistributionWeights: {
        biosBuyBackEthWeight: 0,
        protocolFeeEthWeight: 0,
        rewardsEthWeight: 0,
        treasuryEthWeight: 0,
      },
      biosRewardsDuration: 0,
      sushi: {
        slippageDenominator: 0, //sushiSlippageDenominator,
        slippageNumerator: 0, //sushiSlippageNumerator,
      },
    },

    lastKernelDeployTimestamp,

    uniswapV3: {
      integration: undefined, //uniswapIntegrationContract,
      lpBaseStablecoin: undefined, //uniswapBaseStablecoin,
      feeDenominator: undefined, //uniswapV3FeeDenominator,
      slippageDenominator: undefined, //uniswapV3SlippageDenominator,
      pairs: undefined, //uniswapV3Pairs,
      pairsPools: undefined, //uniswapV3PairPools,
      poolsSlots: undefined, //uniswapV3PoolsSlots,
      poolsSlippageNumerators: undefined, //uniswapV3PoolsSlippageNumerators,
      poolsLPs: undefined, // uniswapV3PoolsLPs,
      lpsWeights: undefined, //uniswapV3LPsWeights,
      lpsMinteds: undefined, // uniswapV3LPsMinteds,
      lpsIds: undefined, //uniswapV3LPsLPIDs,
      lpsIndexes: undefined, //uniswapV3LPsIndexes,
    },

    strategies: {
      strategies: strategies,
      userBalances: userStrategyBalances,
    },
  };

  const data: Data = {
    ...dataDefaults,
    ...dataOld,
    contracts: {
      moduleMap: moduleMapContract,
      kernel: kernelContract,
      userPositions: userPositionsContract,
      yieldManager: yieldManagerContract,
      integrationMap: integrationMapContract,
      biosRewards: biosRewardsContract,
      etherRewards: etherRewardsContract,
      sushiSwapTrader: sushiSwapTraderContract,
      uniswapTrader: uniswapTraderContract,
      strategyMap: strategyMapContract,
      biosToken: biosTokenContract,
      wethToken: wethTokenContract,
      ...sushiSwapContracts,
    },
  };

  return data;
}

export { useCachedData, useSystemData };
