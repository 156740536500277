import { isAddressZero } from '@0xnodes/shared/utils';
import { useUserPositionsContract } from 'hooks/useContract';
import { useQuery as useReactQuery } from 'react-query';
import { useWeb3 } from '../../web3';

export const useSystemTotalDeposits = (token: string) => {
  const { chainId } = useWeb3();
  const userPositions = useUserPositionsContract();
  const ready = !!(chainId && userPositions && token && !isAddressZero(token));

  return useReactQuery(
    [chainId, 'systemDeposits', token],
    () => {
      if (!ready) throw new Error('useSystemTotalDeposits is not ready!');
      return userPositions.totalTokenBalance(token);
    },
    { enabled: ready }
  );
};
