import {
  greenDefault,
  blueDefault,
  pinkDefault,
  orangeDefault,
  redDefault,
} from './colors';

const theme = {
  boxShadow: {
    glow: `0 0 2em -0.5em ${greenDefault}`,
    'glow-big': `0 0 5em -0.5em ${greenDefault}`,
  },
  width: {
    '1/4': '25%',
    '3/4': '75%',
  },
  maxWidth: {
    '2/3': '66%',
  },
  maxHeight: {
    '60vh': '60vh',
    '75vh': '75vh',
  },
  colors: {
    gray: {
      lighter: '#60606080',
      light: '#606060',
      DEFAULT: '#888888',
      dark: '#2e2e2e',
      darker: '#212121',
      darkest: '#1c1c1c',
    },
    green: {
      bright: '#39FFA5',
      DEFAULT: greenDefault,
      dark: '#00C56B',
      darker: '#008C4C',
      opaque: '#00FF8B1A',
    },
    blue: {
      DEFAULT: blueDefault,
      dark: '#3ACBB1',
    },
    pink: {
      DEFAULT: pinkDefault,
    },
    orange: {
      DEFAULT: orangeDefault,
      dark: `${orangeDefault}66`,
      darker: `${orangeDefault}33`,
      darkest: `${orangeDefault}1A`,
    },
    black: {
      DEFAULT: '#111111',
    },
    white: '#ffffff',
    red: {
      DEFAULT: redDefault,
    },
  },
  fontSize: {
    xs: '0.675rem',
    sm: '0.875rem',
    base: ['14px', '20px'],
    lg: '1.125rem',
    xl: '1.3125rem',
    '2xl': '1.625rem',
    '3xl': '1.8125rem',
    '4xl': '2rem',
    '5xl': '2.625rem',
  },
  extend: {
    backgroundImage: () => ({
      sushiswap: "url('./assets/images/sushiswap_logo.svg')",
      honeycomb: "url('./assets/images/honeycomb.svg')",
    }),
    fontFamily: {
      sans: ['nb_architektbold'],
    },
    skew: {
      '-20': '-20deg',
      20: '20deg',
    },
  },
};
export default theme;
