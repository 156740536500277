/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  SyntheticViewer,
  SyntheticViewerInterface,
} from "../../../contracts/mock/SyntheticViewer";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_synthetic",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "distributeAccordingToStrategiesDebt",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getDebts",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b506040516101f73803806101f783398101604081905261002f91610054565b600080546001600160a01b0319166001600160a01b0392909216919091179055610082565b600060208284031215610065578081fd5b81516001600160a01b038116811461007b578182fd5b9392505050565b610166806100916000396000f3fe608060405234801561001057600080fd5b50600436106100365760003560e01c8063c836ef2e1461003b578063c866cd3f1461005a575b600080fd5b6001546002546040805192835260208301919091520160405180910390f35b61006d6100683660046100f5565b61006f565b005b60005460405163c866cd3f60e01b8152600481018390526001600160a01b039091169063c866cd3f906024016040805180830381600087803b1580156100b457600080fd5b505af11580156100c8573d6000803e3d6000fd5b505050506040513d601f19601f820116820180604052508101906100ec919061010d565b60025560015550565b600060208284031215610106578081fd5b5035919050565b6000806040838503121561011f578081fd5b50508051602090910151909290915056fea26469706673582212208e25ebaab67fc91043f86898ba58f5d7161d5087c64622a4d17ae5878eb6129b64736f6c63430008040033";

type SyntheticViewerConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: SyntheticViewerConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class SyntheticViewer__factory extends ContractFactory {
  constructor(...args: SyntheticViewerConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    _synthetic: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<SyntheticViewer> {
    return super.deploy(
      _synthetic,
      overrides || {}
    ) as Promise<SyntheticViewer>;
  }
  override getDeployTransaction(
    _synthetic: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(_synthetic, overrides || {});
  }
  override attach(address: string): SyntheticViewer {
    return super.attach(address) as SyntheticViewer;
  }
  override connect(signer: Signer): SyntheticViewer__factory {
    return super.connect(signer) as SyntheticViewer__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): SyntheticViewerInterface {
    return new utils.Interface(_abi) as SyntheticViewerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): SyntheticViewer {
    return new Contract(address, _abi, signerOrProvider) as SyntheticViewer;
  }
}
