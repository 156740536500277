import { isAddressZero } from '@0xnodes/shared/utils';
import { useIsTokenized } from 'hooks/network/useIsTokenized';
import { useUserTokenBalance } from 'hooks/tokens/useUserTokenBalance';
import {
  useStrategy1155Contract,
  useUserPositionsV2Contract,
} from 'hooks/useContract';
import { useQuery as useReactQuery, useQueries } from 'react-query';
import { useWeb3 } from '../../web3';

export const useStrategyTokenAddress = (strategyId: number | undefined) => {
  const { chainId } = useWeb3();
  const strategy1155 = useStrategy1155Contract();
  const ready = !!(strategyId !== undefined && chainId && strategy1155);

  return useReactQuery(
    [chainId, 'strategyTokenAddress', strategyId],
    () => {
      if (!ready) throw new Error('not ready!');

      return strategy1155.idToERC20(strategyId);
    },
    { enabled: ready }
  );
};

export const useUserStrategyTokenBalance = (strategyId: number | undefined) => {
  const tokenized = useIsTokenized();
  const tokenAddress = useStrategyTokenAddress(
    tokenized ? strategyId : undefined
  );

  return useUserTokenBalance(tokenized ? tokenAddress.data : undefined);
};

export const useUserStrategyBalance = (strategyId: number, token: string) => {
  const { account, chainId } = useWeb3();
  const tokenized = useIsTokenized();
  const userPositions = useUserPositionsV2Contract();
  const ready = !!(
    account &&
    chainId &&
    tokenized === false &&
    userPositions &&
    strategyId &&
    token &&
    !isAddressZero(token)
  );

  return useReactQuery(
    [chainId, account, 'strategy', strategyId, 'balance', token],
    () => {
      if (!ready) throw new Error('useUserStrategyBalance is not ready!');
      return userPositions.getUserStrategyBalanceByToken(
        strategyId,
        token,
        account
      );
    },
    { enabled: ready }
  );
};

export const useUserStrategyBalances = (
  data: { strategyId: number; token: string }[] = []
) => {
  const { account, chainId } = useWeb3();
  const userPositions = useUserPositionsV2Contract();
  const tokenized = useIsTokenized();
  const ready = !!(
    account &&
    chainId &&
    tokenized === false &&
    userPositions &&
    data
  );

  return useQueries(
    data.map(({ strategyId, token }) => ({
      queryKey: [chainId, account, 'strategy', strategyId, 'balance', token],
      queryFn: () => {
        if (!ready || !strategyId || !token)
          throw new Error('useUserStrategyBalances is not ready!');
        return userPositions.getUserStrategyBalanceByToken(
          strategyId,
          token,
          account
        );
      },
      enabled: ready && !!(strategyId && token),
    }))
  );
};

export default useUserStrategyBalance;
