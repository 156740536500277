const FillIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='20' cy='20' r='19.5' stroke={color ? color : '#E84142'} />
      <path
        d='M25.9748 21.3098C26.5616 20.2963 27.5085 20.2963 28.0953 21.3098L31.7496 27.7247C32.3364 28.7383 31.8562 29.5652 30.6826 29.5652H23.3208C22.1605 29.5652 21.6804 28.7383 22.2539 27.7247L25.9748 21.3098ZM18.9064 8.96014C19.4932 7.94656 20.4268 7.94656 21.0136 8.96014L21.8271 10.4272L23.7476 13.8013C24.2144 14.7616 24.2144 15.8952 23.7476 16.8554L17.306 28.0182C16.7192 28.925 15.7456 29.4985 14.6654 29.5652H9.31738C8.14375 29.5652 7.66364 28.7517 8.25045 27.7247L18.9064 8.96014Z'
        fill={color ? color : '#E84142'}
      />
    </svg>
  );
};

export default FillIcon;
