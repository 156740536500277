import { useState, useEffect } from 'react';
import { BigNumber, utils } from 'ethers';
import {
  YieldManager,
  Kernel,
  SushiSwapTrader,
  BiosRewards,
} from '../assets/typechain';
import { useWeb3 } from '../web3';

const useGasAccountAddress = (
  yieldManager: YieldManager | undefined,
  kernel: Kernel | undefined,
  isPrivileged: boolean
) => {
  const [gasAccount, setGasAccount] = useState('');
  const { account } = useWeb3();

  useEffect(() => {
    if (!yieldManager || !kernel || !isPrivileged) {
      setGasAccount('');
      return;
    }

    yieldManager.getGasAccount().then(setGasAccount).catch(console.error);

    // Listeners if user connected
    if (account) {
      const gasAccountUpdated = (address: string, _: any) => {
        setGasAccount(address);
      };

      const filter = kernel.filters.GasAccountUpdated();
      kernel.on(filter, gasAccountUpdated);

      return () => {
        kernel.removeListener(filter, gasAccountUpdated);
      };
    }
  }, [yieldManager, kernel, isPrivileged, account]);

  return gasAccount;
};

const useGasAccountTargetBalance = (
  yieldManager: YieldManager | undefined,
  kernel: Kernel | undefined,
  isPrivileged: boolean
) => {
  const [target, setTarget] = useState(0);
  const { account } = useWeb3();

  useEffect(() => {
    if (!yieldManager || !kernel || !isPrivileged) {
      setTarget(0);
      return;
    }

    yieldManager
      .getGasAccountTargetEthBalance()
      .then((target) => setTarget(Number(utils.formatEther(target))))
      .catch(console.error);

    // Listeners if user connected
    if (account) {
      const updateTarget = (target: BigNumber, _: any) => {
        setTarget(Number(utils.formatEther(target)));
      };

      const filter = kernel.filters.GasAccountTargetEthBalanceUpdated();
      kernel.on(filter, updateTarget);

      return () => {
        kernel.removeListener(filter, updateTarget);
      };
    }
  }, [yieldManager, kernel, isPrivileged, account]);

  return target;
};

const useTreasuryAccountAddress = (
  yieldManager: YieldManager | undefined,
  kernel: Kernel | undefined
) => {
  const [treasury, setTreasury] = useState('');
  const foo = useWeb3();
  const { account } = foo;

  useEffect(() => {
    if (!yieldManager || !kernel) {
      setTreasury('');
      return;
    }

    yieldManager.getTreasuryAccount().then(setTreasury).catch(console.error);

    // Listeners if user connected
    if (account) {
      const updateAddress = (address: string, _: any) => {
        setTreasury(address);
      };

      const filter = kernel.filters.TreasuryAccountUpdated();
      kernel.on(filter, updateAddress);

      return () => {
        kernel.removeListener(filter, updateAddress);
      };
    }
  }, [yieldManager, kernel, account]);

  return treasury;
};

const useEthDistributionWeights = (
  yieldManager: YieldManager | undefined,
  kernel: Kernel | undefined,
  isPrivileged: boolean
) => {
  const { account } = useWeb3();
  const [weights, setWeights] = useState<{
    biosBuyBackEthWeight: number;
    treasuryEthWeight: number;
    protocolFeeEthWeight: number;
    rewardsEthWeight: number;
  }>({
    biosBuyBackEthWeight: 0,
    treasuryEthWeight: 0,
    protocolFeeEthWeight: 0,
    rewardsEthWeight: 0,
  });

  useEffect(() => {
    if (!yieldManager || !kernel || !isPrivileged) {
      setWeights({
        biosBuyBackEthWeight: 0,
        treasuryEthWeight: 0,
        protocolFeeEthWeight: 0,
        rewardsEthWeight: 0,
      });
      return;
    }

    yieldManager
      .getEthDistributionWeights()
      .then(
        ([
          biosBuyBackEthWeight,
          treasuryEthWeight,
          protocolFeeEthWeight,
          rewardsEthWeight,
        ]) => {
          setWeights({
            biosBuyBackEthWeight,
            treasuryEthWeight,
            protocolFeeEthWeight,
            rewardsEthWeight,
          });
        }
      )
      .catch(console.error);

    // Listeners if user connected
    if (account) {
      const updateEthDistributionWeights = (
        biosBuyBackEthWeight: number,
        treasuryEthWeight: number,
        protocolFeeEthWeight: number,
        rewardsEthWeight: number,
        _: any
      ) => {
        setWeights({
          biosBuyBackEthWeight,
          treasuryEthWeight,
          protocolFeeEthWeight,
          rewardsEthWeight,
        });
      };

      const filter = kernel.filters.EthDistributionWeightsUpdated();
      kernel.on(filter, updateEthDistributionWeights);

      return () => {
        kernel.removeListener(filter, updateEthDistributionWeights);
      };
    }
  }, [yieldManager, kernel, isPrivileged, account]);

  return weights;
};

const useBiosRewardsDuration = (
  biosRewards: BiosRewards | undefined,
  kernel: Kernel | undefined,
  isPrivileged: boolean
) => {
  const { account } = useWeb3();
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (!biosRewards || !kernel || !isPrivileged) {
      setDuration(0);
      return;
    }

    biosRewards.getBiosRewardsDuration().then(setDuration).catch(console.error);

    // Listeners if user connected
    if (account) {
      const updateDuration = (duration: number, _: any) => {
        setDuration(duration);
      };

      const filter = kernel.filters.SetBiosRewardsDuration();
      kernel.on(filter, updateDuration);

      return () => {
        kernel.removeListener(filter, updateDuration);
      };
    }
  }, [biosRewards, kernel, isPrivileged, account]);

  return duration;
};

const useSushiSlippageDenominator = (
  sushi: SushiSwapTrader | undefined,
  isPrivileged: boolean
) => {
  const [slippageDenominator, setSlippageDenominator] = useState(0);

  useEffect(() => {
    if (!sushi || !isPrivileged) {
      setSlippageDenominator(0);
      return;
    }

    sushi
      .getSlippageDenominator()
      .then(setSlippageDenominator)
      .catch(console.error);
  }, [sushi, isPrivileged]);

  return slippageDenominator;
};

const useSushiSlippageNumerator = (
  sushi: SushiSwapTrader | undefined,
  isPrivileged: boolean
) => {
  const { account } = useWeb3();
  const [slippageNumerator, setSlippageNumerator] = useState(0);

  useEffect(() => {
    if (!sushi || !isPrivileged) {
      setSlippageNumerator(0);
      return;
    }

    sushi
      .getSlippageNumerator()
      .then(setSlippageNumerator)
      .catch(console.error);

    // Listeners if user connected
    if (account) {
      const updateSlippageNumerator = (numerator: number, _: any) => {
        setSlippageNumerator(numerator);
      };

      const filter = sushi.filters.SushiSwapSlippageNumeratorUpdated();
      sushi.on(filter, updateSlippageNumerator);

      return () => {
        sushi.removeListener(filter, updateSlippageNumerator);
      };
    }
  }, [sushi, isPrivileged, account]);

  return slippageNumerator;
};

export {
  useGasAccountAddress,
  useGasAccountTargetBalance,
  useTreasuryAccountAddress,
  useEthDistributionWeights,
  useBiosRewardsDuration,
  useSushiSlippageDenominator,
  useSushiSlippageNumerator,
};
