import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from 'components/ui/modal/Modal';
import { BigNumber } from 'ethers';
import {
  IDepositableAssetGroups,
  TTokenAddress,
  TTokenSymbol,
} from '@0xnodes/shared/types';
import ManageModalTabBar from './TabBar/ManageModalTabBar';
import ManageModalTokenList from './TokenList/ManageModalTokenList';
import ManageModalFooter from './ManageModalFooter/ManageModalFooter';

export interface IAssetWithAmount {
  address: TTokenAddress;
  canDeposit: boolean;
  canWithdraw: boolean;
  inputAmount: BigNumber;
  allowance?: BigNumber;
  decimals: number;
  symbol: TTokenSymbol;
  icon?: string;
  tokenPrice: number;
  approved: boolean;
}

export interface ITotalObj {
  address: string;
  value: number;
}

export enum ManageModalState {
  closed = 0,
  deposit,
  withdraw,
}

const ManageModalContainer = ({
  state,
  setState,
  pending,
  enterContractCall,
  exitContractCall,
  isStrategyManage,
  title,
  children,
  assetGroups,
}: {
  state: ManageModalState;
  setState: Dispatch<SetStateAction<ManageModalState>>;
  pending: boolean;
  enterContractCall: (amounts: IAssetWithAmount[]) => void;
  exitContractCall: (amounts: IAssetWithAmount[]) => void;
  isStrategyManage: boolean;
  title: string | React.ReactNode;
  children?: React.ReactNode;
  assetGroups?: IDepositableAssetGroups;
}) => {
  const isInDepositingTab = state === ManageModalState.deposit;

  const [assetsWithAmounts, setAssetsWithAmounts] = useState<
    IAssetWithAmount[]
  >([]);

  const buttonEnabled = isInDepositingTab
    ? !assetsWithAmounts.some((t) => !t.canDeposit) &&
      assetsWithAmounts.some((t) => t.inputAmount.gt(0))
    : !assetsWithAmounts.some((t) => !t.canWithdraw) &&
      assetsWithAmounts.some((t) => t.inputAmount.gt(0));

  const [total, setTotal] = useState<number>(1);
  const [totals, setTotals] = useState<ITotalObj[]>([]);

  useEffect(() => {
    setTotal(totals.reduce((c, n) => c + n.value, 0));
  }, [totals]);

  useEffect(() => {
    if (!state) {
      setAssetsWithAmounts([]);
      setTotals([]);
    }
  }, [isInDepositingTab, state]);

  const performAction = () => {
    if (
      (isInDepositingTab && !enterContractCall) ||
      (!isInDepositingTab && !exitContractCall)
    ) {
      return;
    }

    if (isInDepositingTab) {
      enterContractCall(assetsWithAmounts);
    } else {
      exitContractCall(assetsWithAmounts);
    }
  };

  // TODO: get this in props
  const noAvailableFunds = false;

  if (!isStrategyManage && !assetGroups) return <></>;

  return (
    <Modal
      open={!!state}
      title={title}
      closeFunction={() => {
        setState && setState(ManageModalState.closed);
      }}
      footer={
        <ManageModalFooter
          totalValue={total}
          assetsWithAmounts={assetsWithAmounts}
          buttonEnabled={buttonEnabled}
          noAvailableFunds={noAvailableFunds}
          onClick={performAction}
          isStrategyManage={isStrategyManage}
          isDepositing={isInDepositingTab}
          pending={pending}
        />
      }
    >
      <div className='grid grid-cols-1 gap-8'>
        <ManageModalTabBar
          isInDepositingTab={isInDepositingTab}
          setState={(value) => {
            setState && setState(value);
          }}
          isStrategyManage={isStrategyManage}
        />

        {children && children}

        {isStrategyManage ? (
          <ManageModalTokenList
            isInDepositingTab={isInDepositingTab}
            isStrategyManage={isStrategyManage}
            setAssetsWithAmounts={setAssetsWithAmounts}
            setTotals={setTotals}
          />
        ) : (
          <ManageModalTokenList
            isInDepositingTab={isInDepositingTab}
            isStrategyManage={isStrategyManage}
            setAssetsWithAmounts={setAssetsWithAmounts}
            setTotals={setTotals}
            assetGroups={assetGroups}
          />
        )}
      </div>
    </Modal>
  );
};

export default ManageModalContainer;
