import { IStrategy, TTokenAddress, TTokenSymbol } from '@0xnodes/shared/types';
import {
  IAssetWithAmount,
  ITotalObj,
} from 'components/ManageModal/ManageModalContainer';
import { useIsTokenized } from 'hooks/network/useIsTokenized';
import {
  useUserStrategyBalance,
  useUserStrategyTokenBalance,
} from 'hooks/strategies/useUserStrategyBalance';
import useAssetIcon from 'hooks/tokens/useAssetIcon';
import { useUserKernelBalance } from 'hooks/tokens/useUserKernelBalance';
import { useState } from 'react';
import ManageModalInputLine from './ManageModalInputLine';

const StrategyManageModalInputLineContainer = ({
  strategy,
  symbol,
  address,
  decimals,
  isInDepositingTab,
  setAssetsWithAmounts,
  setTotals,
}: {
  strategy: IStrategy;
  symbol: TTokenSymbol;
  address: TTokenAddress;
  decimals: number;
  isInDepositingTab: boolean;
  setAssetsWithAmounts: React.Dispatch<
    React.SetStateAction<IAssetWithAmount[]>
  >;
  setTotals: React.Dispatch<React.SetStateAction<ITotalObj[]>>;
}) => {
  const strategyId = parseInt(strategy.id.split('#')[1], 10);
  const tokenized = useIsTokenized();
  const { data: strategyBalance } = useUserStrategyBalance(strategyId, address);
  const { data: strategyTokenBalance } =
    useUserStrategyTokenBalance(strategyId);
  const { data: kernelBalance } = useUserKernelBalance(address);
  const icon = useAssetIcon(symbol);

  // TODO: get real data
  const [tokenPrice] = useState<number>(1);

  return (
    <ManageModalInputLine
      address={address}
      icon={icon}
      symbol={symbol}
      decimals={decimals}
      tokenPrice={tokenPrice}
      insideBalance={tokenized ? strategyTokenBalance : strategyBalance}
      outsideBalance={kernelBalance}
      isInDepositingTab={isInDepositingTab}
      setAssetsWithAmounts={setAssetsWithAmounts}
      setTotals={setTotals}
    />
  );
};

export default StrategyManageModalInputLineContainer;
