export interface StatusCircleProps {
  on: boolean;
}

const StatusCircle = ({ on }: StatusCircleProps) => (
  <div
    className={`relative mr-2 top-1.5 h-3 w-3 border border-gray rounded-full ${
      on ? 'bg-green border-green' : ''
    }`}
  ></div>
);

export default StatusCircle;
