import { MODULES } from '@0xnodes/shared/addresses';
import { isAddressZero } from '@0xnodes/shared/utils';
import { useModuleAddress, useTokenContract } from 'hooks/useContract';
import { useQuery as useReactQuery } from 'react-query';
import { useWeb3 } from '../../web3';

export const useUserSystemAllowance = (token: string) => {
  const { account, chainId } = useWeb3();
  const contract = useTokenContract(token);
  const { data: userPositionsAddress } = useModuleAddress(
    MODULES.userPositions
  );
  const ready = !!(
    account &&
    chainId &&
    contract &&
    userPositionsAddress &&
    !isAddressZero(userPositionsAddress) &&
    token &&
    !isAddressZero(token)
  );

  return useReactQuery(
    [chainId, account, 'systemAllowance', token],
    () => {
      if (!ready) throw new Error('useUserSystemAllowance is not ready!');
      return contract.allowance(account, userPositionsAddress);
    },
    { enabled: ready }
  );
};
