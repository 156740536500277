import { useState, useEffect } from 'react';
import { utils } from 'ethers';
import { useWeb3 } from '../web3';

const useCurrentBlockNumber = () => {
  const [currentBlock, setCurrentBlock] = useState(0);
  const { provider, account } = useWeb3();

  useEffect(() => {
    if (!provider) {
      setCurrentBlock(0);
      return;
    }
    if (currentBlock) {
      return;
    }

    const setBlockNumber = (blockNumber: number) => {
      setCurrentBlock(blockNumber);
    };

    provider.getBlockNumber().then(setBlockNumber).catch(console.error);

    // Listeners if user connected
    if (account) {
      console.log('listening to block!');
      provider.on('block', setBlockNumber);

      return () => {
        provider.removeListener('block', setBlockNumber);
      };
    }
  }, [provider, account, currentBlock]);

  return currentBlock;
};

const useEtherBalance = (address: string, currentBlockNumber: number) => {
  const { provider } = useWeb3();
  const [etherBalance, setEtherBalance] = useState(0);

  useEffect(() => {
    if (!address || !provider) {
      setEtherBalance(0);
      return;
    }

    provider
      .getBalance(address)
      .then((balance) => setEtherBalance(Number(utils.formatEther(balance))))
      .catch(console.error);
  }, [address, provider, currentBlockNumber]);

  return etherBalance;
};

const useCurrentGasPrice = () => {
  const blocknumber = useCurrentBlockNumber();
  const [currentGasPrice, setCurrentGasPrice] = useState<number>(0);
  const { provider } = useWeb3();

  useEffect(() => {
    if (!provider || !blocknumber) {
      setCurrentGasPrice(0);
      return;
    }

    if (!currentGasPrice) {
      provider
        .getGasPrice()
        .then((gasPrice) =>
          setCurrentGasPrice(
            Math.floor(Number(utils.formatUnits(gasPrice, 'gwei')))
          )
        )
        .catch(console.error);
    }
  }, [blocknumber, currentGasPrice, provider]);

  return currentGasPrice;
};

export { useCurrentBlockNumber, useEtherBalance, useCurrentGasPrice };
