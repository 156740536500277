import numeral from 'numeral';

export function dollar(amount: number | string) {
  return numeral(amount).format('$0,0.00');
}

export function assetFormat(amount: number | string, decimals = 5) {
  return numeral(amount).format(`0,0.${'0'.repeat(decimals)}`);
}

export function percentFormat(amount: number | string) {
  return numeral(amount).format('0,0.00%');
}
