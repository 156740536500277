import { useState, useEffect } from 'react';
import { BigNumber } from 'ethers';
import { IntegrationMap, Kernel } from '../assets/typechain';

export interface TokenIntegrationWeight {
  integrationAddress: string;
  tokenAddress: string;
  weight: BigNumber;
}

const biosRewardWeightSum = (biosRewardRates: {
  [address: string]: BigNumber | undefined;
}) => {
  return Object.keys(biosRewardRates).reduce((p, c) => {
    const toAdd = biosRewardRates[c];
    if (toAdd) {
      return p.add(toAdd);
    } else {
      return p;
    }
  }, BigNumber.from(0));
};

const integrationWeightsSums = (
  tokenIntegrationWeights: { integrationAddress: string; weight: BigNumber }[]
) => {
  if (tokenIntegrationWeights.length === 0) {
    return;
  }

  return tokenIntegrationWeights.reduce((p, c) => {
    if (!p[c.integrationAddress]) {
      p[c.integrationAddress] = BigNumber.from(0);
    }
    p[c.integrationAddress] = p[c.integrationAddress].add(c.weight);
    return p;
  }, {} as { [address: string]: BigNumber });
};

const useTokenIntegrationWeights = (
  integrationMapContract: IntegrationMap | undefined,
  kernel: Kernel | undefined,
  supportedIntegrationAddresses: string[] | undefined,
  supportedTokenAddresses: string[] | undefined
) => {
  const [weights, setWeights] = useState<TokenIntegrationWeight[]>();

  useEffect(() => {
    if (
      !integrationMapContract ||
      !kernel ||
      !supportedIntegrationAddresses ||
      !supportedTokenAddresses
    ) {
      setWeights(undefined);
      return;
    }
    setWeights(undefined);
    return;

    //const allPairs = supportedIntegrationAddresses.map(integrationAddress => supportedTokenAddresses.map(tokenAddress => ({ integrationAddress, tokenAddress }))).flat();

    // console.error(
    //   'no action taken',
    //   'integrationMapContract.getTokenIntegrationWeight has moved'
    // );
    // /*
    // Promise.all(allPairs.map(pair => Promise.all([pair, integrationMapContract.getTokenIntegrationWeight(pair.integrationAddress, pair.tokenAddress)])))
    //   .then(weights => setWeights(weights.map(([pair, weight]) => ({ integrationAddress: pair.integrationAddress, tokenAddress: pair.tokenAddress, weight }))))
    //   .catch(console.error);
    // */
    // const updateWeight = (
    //   tokenAddress: string,
    //   integrationAddress: string,
    //   weight: BigNumber,
    //   _: any
    // ) => {
    //   setWeights((weights) => {
    //     if (!weights) return;
    //     const allWeights = [...weights];
    //     const index = allWeights.findIndex(
    //       (w) =>
    //         w.tokenAddress.toLowerCase() === tokenAddress.toLowerCase() &&
    //         w.integrationAddress.toLowerCase() ===
    //           integrationAddress.toLowerCase()
    //     );
    //     if (index === -1) return allWeights;
    //     allWeights[index].weight = weight;
    //     return allWeights;
    //   });
    // };

    // const tokenIntegrationWeightUpdatedFilter =
    //   kernel.filters.TokenIntegrationWeightUpdated(null, null, null);
    // kernel.on(tokenIntegrationWeightUpdatedFilter, updateWeight);

    // return () => {
    //   kernel.removeListener(tokenIntegrationWeightUpdatedFilter, updateWeight);
    // };
  }, [
    integrationMapContract,
    kernel,
    supportedIntegrationAddresses,
    supportedTokenAddresses,
  ]);

  return weights;
};

const useTotalIntegrationWeights = (
  weights: TokenIntegrationWeight[] | undefined
) => {
  const [sum, setSum] = useState<
    { [address: string]: BigNumber } | undefined
  >();

  useEffect(() => {
    if (!weights) {
      setSum(undefined);
      return;
    }

    const sums = integrationWeightsSums(weights);
    setSum(sums);
  }, [weights]);

  return sum;
};

export {
  biosRewardWeightSum,
  integrationWeightsSums,
  useTokenIntegrationWeights,
  useTotalIntegrationWeights,
};
