/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ISyntheticERC20,
  ISyntheticERC20Interface,
} from "../../../contracts/interfaces/ISyntheticERC20";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_desiredLiquidity",
        type: "uint256",
      },
    ],
    name: "adjustLiquidityAccordingToUnderlyingDebt",
    outputs: [
      {
        internalType: "uint256",
        name: "_underlyingLiquidity",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_syntheticLiquidity",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "burn",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "syntheticSpended",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "underlyingReceived",
        type: "uint256",
      },
    ],
    name: "changePoolDebt",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_gotFromWithdrawal",
        type: "uint256",
      },
    ],
    name: "distributeAccordingToStrategiesDebt",
    outputs: [
      {
        internalType: "uint256",
        name: "hold_",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "depositToPool_",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getAndDeleteDelayedRedemptionOfSynthetic",
    outputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getAndDeleteStrategiesPoolDebt",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getHedgeDeployArgs",
    outputs: [
      {
        internalType: "address",
        name: "integration_",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "ammPoolID_",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "syntheticToken_",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getHedgePercent",
    outputs: [
      {
        internalType: "uint128",
        name: "numerator_",
        type: "uint128",
      },
      {
        internalType: "uint128",
        name: "denominator_",
        type: "uint128",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getPoolLimit",
    outputs: [
      {
        internalType: "uint32",
        name: "underlying_",
        type: "uint32",
      },
      {
        internalType: "uint32",
        name: "synthetic_",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "increaseRedemptionOfSynthetic",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_account",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "mint",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class ISyntheticERC20__factory {
  static readonly abi = _abi;
  static createInterface(): ISyntheticERC20Interface {
    return new utils.Interface(_abi) as ISyntheticERC20Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ISyntheticERC20 {
    return new Contract(address, _abi, signerOrProvider) as ISyntheticERC20;
  }
}
