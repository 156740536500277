import { StrategyContext } from 'contexts/strategyContext_new';
import { useTransaction } from 'data/transactions';
import { BigNumber, constants, ethers } from 'ethers';
import { StrategyCard } from 'pages/StrategiesPage/StrategiesSection/StrategyCard';
import { Dispatch, SetStateAction, useContext } from 'react';
import ManageModalContainer, {
  IAssetWithAmount,
  ManageModalState,
} from './ManageModalContainer';
import {
  failedStrategyDepositToast,
  failedStrategyWithdrawToast,
  successfulStrategyDepositToast,
  successfulStrategyWithdrawToast,
} from 'components/utils/toastHelper';
import {
  useUserPositionsContract,
  useUserPositionsV2Contract,
} from 'hooks/useContract';
import { useIsTokenized } from 'hooks/network/useIsTokenized';

export const StrategyManageModal = ({
  state,
  setState,
}: {
  state: ManageModalState;
  setState: Dispatch<SetStateAction<ManageModalState>>;
}) => {
  const { selectedStrategy } = useContext(StrategyContext);
  const { contractCallNew, pending } = useTransaction();
  const userPositions = useUserPositionsContract();
  const userPositionsV2 = useUserPositionsV2Contract();
  const tokenized = useIsTokenized();

  const enterStrategy = (assetsWithAmounts: IAssetWithAmount[]) => {
    if (selectedStrategy) {
      const depositAssets = assetsWithAmounts.filter((a) =>
        a.inputAmount.gt(0)
      );
      const id = BigNumber.from(
        parseInt(selectedStrategy.id.split('#')[1], 10)
      );
      const ether = depositAssets.find(
        (a) => a.address === constants.AddressZero
      );
      const tokens = ether
        ? depositAssets.filter((a) => a.address !== constants.AddressZero)
        : [...depositAssets];
      const tokenAmounts = tokens.map((t) => ({
        token: t.address,
        amount: t.inputAmount,
      }));

      if (
        userPositions &&
        userPositionsV2 &&
        selectedStrategy &&
        tokenized !== undefined
      ) {
        contractCallNew(
          () =>
            tokenized
              ? userPositions.enterStrategy(id, tokenAmounts[0].amount)
              : (userPositionsV2.enterStrategy(
                  id,
                  tokenAmounts
                ) as Promise<ethers.ContractTransaction>),
          'Entering strategy',
          () =>
            failedStrategyDepositToast(
              assetsWithAmounts,
              { toastId: `failed-with-${selectedStrategy.id}` },
              selectedStrategy.name
            ),
          () =>
            successfulStrategyDepositToast(
              assetsWithAmounts,
              { toastId: `succeeded-with-${selectedStrategy.id}` },
              selectedStrategy.name
            ),
          undefined,
          () => {
            setState(ManageModalState.closed);
          }
        );
      }
    }
  };

  const exitStrategy = (assetsWithAmounts: IAssetWithAmount[]) => {
    if (userPositions && selectedStrategy) {
      const id = BigNumber.from(
        parseInt(selectedStrategy.id.split('#')[1], 10)
      );
      const withdrawAssets = assetsWithAmounts.filter((a) =>
        a.inputAmount.gt(0)
      );
      const withdrawEther = withdrawAssets.find(
        (a) => a.address === constants.AddressZero
      );
      const ether = withdrawEther
        ? Object.assign({}, withdrawEther)
        : undefined;
      const tokensWithoutEther = ether
        ? withdrawAssets.filter((a) => a.address !== constants.AddressZero)
        : [...withdrawAssets];

      // TODO: get real data weth token address
      const wethToken = { address: 'a' };

      if (ether) ether.address = wethToken.address.toLowerCase();
      const tokens = ether
        ? [...tokensWithoutEther, ether]
        : [...tokensWithoutEther];
      const tokenAmounts = tokens.map((t) => ({
        token: t.address,
        amount: t.inputAmount,
      }));
      if (
        userPositions &&
        userPositionsV2 &&
        selectedStrategy &&
        tokenized !== undefined
      ) {
        contractCallNew(
          () =>
            tokenized
              ? userPositions.exitStrategy(id, tokenAmounts[0].amount)
              : userPositionsV2.exitStrategy(id, tokenAmounts),
          'Exiting from strategy',
          () =>
            failedStrategyWithdrawToast(
              assetsWithAmounts,
              { toastId: `failed-with-${selectedStrategy.id}` },
              selectedStrategy.name
            ),
          () =>
            successfulStrategyWithdrawToast(
              assetsWithAmounts,
              { toastId: `succeeded-with-${selectedStrategy.id}` },
              selectedStrategy.name
            ),
          undefined,
          () => {
            setState(ManageModalState.closed);
          }
        );
      }
    }
  };

  if (!selectedStrategy) return <></>;
  return (
    <ManageModalContainer
      state={state}
      setState={setState}
      pending={pending}
      enterContractCall={enterStrategy}
      exitContractCall={exitStrategy}
      isStrategyManage={true}
      title={selectedStrategy?.name}
    >
      <StrategyCard.TokenContent
        tokens={selectedStrategy.farms.map((t) => t.symbol)}
        accepts={selectedStrategy.accepts}
        returns={selectedStrategy.returns}
      />
    </ManageModalContainer>
  );
};

export default StrategyManageModal;
