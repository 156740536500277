import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import { supportedChainNames, supportedChains } from './chains';
import { errorToast, infoToast } from 'components/utils/toastHelper';

const STORAGE_KEY = 'currentChainId';

async function switchChain() {
  // switch to first supported chain
  const chainId = supportedChains().filter((id) => supportedChainNames(id))[0];
  await window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: `0x${chainId.toString(16)}` }],
  });
}

const useListeners = (
  provider: ethers.providers.Provider | undefined,
  web3Modal: Web3Modal
) => {
  const [myProvider, setMyProvider] =
    useState<ethers.providers.Web3Provider | null>(null);

  useEffect(() => {
    // subscribe to connect events
    web3Modal.on('connect', async (provider) => {
      if (!supportedChains().includes(parseInt(provider.chainId))) {
        try {
          await switchChain();
          web3Modal.clearCachedProvider();
          const web3Provider = new ethers.providers.Web3Provider(provider);
          setMyProvider(web3Provider);
          window.location.reload();
        } catch (error) {
          const networks = supportedChains()
            .map(supportedChainNames)
            .filter((l) => l)
            .join(', ');
          errorToast(`Switch to a supported network: ${networks}`, {
            toastId: 'switchNetwork',
          });
        }
      } else {
        const web3Provider = new ethers.providers.Web3Provider(provider);
        setMyProvider(web3Provider);
        infoToast('Connected', { toastId: 'connected' });
        if (localStorage.getItem(STORAGE_KEY) !== provider.chainId) {
          localStorage.setItem(STORAGE_KEY, provider.chainId);
          window.location.reload();
        }
      }
    });

    return () => {
      web3Modal.off('connect');
    };
  }, [web3Modal]);

  useEffect(() => {
    if (!provider) return;

    // subscribe to Network events
    provider.on('chainChanged', async (chainId: string) => {
      if (!supportedChains().includes(parseInt(chainId))) {
        try {
          await switchChain();
          window.location.reload();
        } catch (error) {
          const networks = supportedChains()
            .map(supportedChainNames)
            .filter((l) => l)
            .join(', ');
          errorToast(`Switch to a supported network: ${networks}`, {
            toastId: 'switchNetwork',
          });
        }
      } else {
        infoToast('Network changed', { toastId: 'switchNetwork' });
        const web3Provider = new ethers.providers.Web3Provider(provider as any);
        setMyProvider(web3Provider);
        // avoid page crash when switching network
        window.location.reload();
      }
    });

    // subscribe to account change events
    provider.on('accountsChanged', (accounts: string[]) => {
      if (accounts.length === 0) {
        infoToast('Account disconnected', { toastId: 'disconnected' });
        web3Modal.clearCachedProvider();
        setMyProvider(null);
        // reset to mainnet
        localStorage.setItem(STORAGE_KEY, '0x1');
        window.location.reload();
      } else {
        infoToast('Account changed', { toastId: 'connected' });
        web3Modal.connect();
        window.location.reload();
      }
    });

    // subscribe to provider disconnection
    provider.on('disconnect', () => {
      infoToast('Account disconnected', { toastId: 'disconnected' });
      web3Modal.clearCachedProvider();
      setMyProvider(null);
      // reset to mainnet
      localStorage.setItem(STORAGE_KEY, '0x1');
      window.location.reload();
    });

    // unsubscribe
    return () => {
      provider.removeAllListeners();
    };
  }, [provider, web3Modal]);

  return myProvider;
};

export { useListeners };
