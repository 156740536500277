export interface ExpandButtonProps {
  arrowUp?: boolean;
  onClick?: () => void;
  black?: boolean;
  border?: boolean;
  className?: string;
  size?: number;
}

export default function ExpandButton({
  arrowUp,
  onClick,
  black,
  border,
  className,
  size = 5,
}: ExpandButtonProps) {
  return (
    <div
      onClick={onClick}
      className={`${
        className ? className : ''
      } double-arrow-icon-container cursor-pointer text-center self-center text-lg on-hover-pink ${
        border ? ' p-4 border border-green hover:border-pink' : ''
      }`}
    >
      <div className={`w-${size} h-${size}`}>
        <div className={`double-arrow-icon ${arrowUp ? 'up' : ''}`}>
          <div className='arrow'>
            <div className='side left'></div>
            <div className='side right'></div>
          </div>
          <div className='arrow higher'>
            <div className='side left'></div>
            <div className='side right'></div>
          </div>
        </div>
      </div>
    </div>
  );
}
