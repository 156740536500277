import {
  IPFAStats,
  IPlatformStats,
  IQueryResponse,
  ITokenTotalDeposits,
} from '@0xnodes/shared/types';
import { gql, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
// import PlatformTvlContainer from './PlatformTvlContainer';
import NetworkTvlContainer from './NetworkTvlContainer';
import NetworkStatusContainer from './NetworkStatusContainer';
import PlatformFeeContainer from './PlatformFeeContainer';
import { useWeb3 } from '../../../web3';
import { BigNumber } from 'ethers';
import { errorToast } from 'components/utils/toastHelper';
import Skeletons from 'components/ui/skeletonContainer/Skeletons';
import { networks } from '@0xnodes/shared/networks';
import { useGlobalDepositAmount } from 'data/assetBalances';
import { tokenAddresses } from '@0xnodes/shared/addresses';
import { useNativeSymbol } from 'hooks/tokens/useNativeSymbol';
import { useTotalRewardsByStrategy } from 'hooks/rewards/useTotalRewardsByStrategy';
import YieldDistributionContainer from './YieldDistributionContainer';

/**
 * GraphQL Query
 */
export const GET_STAT_CONTAINER_ROW_DATA = gql`
  query GetStatContainerRowData {
    platformStats {
      networks {
        id
        totalDeposits {
          token
          amount
        }
        tvl
        rewards
        apr
      }
      tvl
      rewards
    }
  }
`;

/**
 * Data Fetching Hook
 */
export interface IStatContainerRowData extends IQueryResponse {
  platformStats: IPlatformStats | null | undefined;
  pfaStats: IPFAStats | null | undefined;
}

export const useStatContainerRowData = (): IStatContainerRowData => {
  const { chainId } = useWeb3();
  const nativeSymbol = useNativeSymbol();
  const { data: biosAmount } = useGlobalDepositAmount(
    chainId ? tokenAddresses[chainId]?.BIOS : ''
  );
  const { data: rewardAmount } = useTotalRewardsByStrategy(0);
  const { loading, error, data } = useQuery(GET_STAT_CONTAINER_ROW_DATA);
  const [platformStats, setPlatformStatsAll] = useState<IPlatformStats>();
  const [PFAStats, setPFAStats] = useState<IPFAStats>();

  useEffect(() => {
    if (!(biosAmount && nativeSymbol && rewardAmount)) return;
    setPFAStats({
      rewardAsset: nativeSymbol,
      rewardAmount: rewardAmount,
      stakedAsset: 'BIOS',
      stakedAmount: biosAmount,
    });
  }, [biosAmount, nativeSymbol, rewardAmount, setPFAStats]);

  useEffect(() => {
    if (!data) return;
    const _data = data as { platformStats: IPlatformStats };
    setPlatformStatsAll({
      networks: _data.platformStats.networks.map((n) => ({
        ...n,
        totalDeposits: n.totalDeposits?.map((d: ITokenTotalDeposits) => ({
          ...d,
          amount: BigNumber.from(d.amount),
        })),
        tvl: BigNumber.from(n.tvl),
        rewards: BigNumber.from(n.rewards),
        apr: 0, // disable APR for now
      })),
      tvl: BigNumber.from(_data.platformStats.tvl),
      rewards: BigNumber.from(_data.platformStats.rewards),
    });
  }, [data, setPlatformStatsAll]);

  return { loading, error, platformStats, pfaStats: PFAStats };
};

/**
 * React Component
 */
const StatContainerRow = () => {
  const { chainId } = useWeb3();
  const { loading, error, platformStats, pfaStats } = useStatContainerRowData();

  useEffect(() => {
    if (error !== undefined && loading) {
      errorToast(
        <div>
          <div>Error getting stats</div>
          <div className='text-sm'>{error.message}</div>
        </div>,
        { toastId: 'statsError' }
      );
    }
  }, [error, loading]);

  return (
    <div>
      {error || loading ? (
        <Skeletons
          gridClassNames={`grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-2`}
          name={'Stats'}
          error={error !== undefined}
          height={200}
          quantity={4}
        />
      ) : (
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-2 center'>
          {/* <PlatformTvlContainer tvl={platformStats?.tvl} /> */}
          <NetworkStatusContainer networkStats={platformStats?.networks} />
          <NetworkTvlContainer chainId={chainId} />
          <YieldDistributionContainer chainId={chainId} />
          <PlatformFeeContainer
            networkStats={platformStats?.networks?.find(
              ({ id }) => networks[id] === chainId
            )}
            pfaStats={pfaStats}
          />
        </div>
      )}
    </div>
  );
};

export default StatContainerRow;
