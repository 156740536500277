const FillIcon = ({
  color: inputColor,
  lined = true,
}: {
  color?: string;
  lined?: boolean;
}) => {
  const color = inputColor ? inputColor : '#00FF8B';

  return lined ? (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='20' cy='20' r='19.5' stroke={color} />
      <path
        d='M20.1663 8L20.0054 8.54689V24.4162L20.1663 24.5769L27.5326 20.2226L20.1663 8Z'
        fill={color}
      />
      <path
        d='M20.1662 8L12.7998 20.2226L20.1662 24.5769V16.8744V8Z'
        fill={color}
      />
      <path
        d='M20.1664 25.9717L20.0757 26.0822V31.7352L20.1664 32L27.5372 21.6196L20.1664 25.9717Z'
        fill={color}
      />
      <path d='M20.1662 32V25.9716L12.7998 21.6195L20.1662 32Z' fill={color} />
      <path
        d='M20.166 24.5769L27.5323 20.2227L20.166 16.8745V24.5769Z'
        fill={color}
      />
      <path
        d='M12.7998 20.2226L20.1661 24.5768V16.8744L12.7998 20.2226Z'
        fill={color}
      />
    </svg>
  ) : (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='20' cy='20' r='19.5' stroke={color} />
      <path
        d='M20.1663 8L20.0054 8.54689V24.4162L20.1663 24.5769L27.5326 20.2226L20.1663 8Z'
        fill={color}
      />
      <path
        d='M20.1662 8L12.7998 20.2226L20.1662 24.5769V16.8744V8Z'
        fill={color}
      />
      <path
        d='M20.1664 25.9717L20.0757 26.0822V31.7352L20.1664 32L27.5372 21.6196L20.1664 25.9717Z'
        fill={color}
      />
      <path d='M20.1662 32V25.9716L12.7998 21.6195L20.1662 32Z' fill={color} />
      <path
        d='M20.166 24.5769L27.5323 20.2227L20.166 16.8745V24.5769Z'
        fill={color}
      />
      <path
        d='M12.7998 20.2226L20.1661 24.5768V16.8744L12.7998 20.2226Z'
        fill={color}
      />
    </svg>
  );
};

export default FillIcon;
