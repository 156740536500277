import { useQuery as useReactQuery } from 'react-query';
import { useEtherRewardsContract } from 'hooks/useContract';
import { useWeb3 } from '../../web3';

export const useTotalRewardsByStrategy = (strategyId?: number) => {
  const { chainId } = useWeb3();
  const etherRewards = useEtherRewardsContract();

  const ready = !!(chainId && etherRewards && typeof strategyId === 'number');

  return useReactQuery(
    [chainId, 'global', 'rewards', 'total', 'byStrategy', strategyId],
    async () => {
      if (!ready) throw new Error('useTotalRewardsByStrategy is not ready!');

      return (await etherRewards.getStrategyInfo(strategyId)).totalRewards;
    },
    { enabled: ready }
  );
};

export default useTotalRewardsByStrategy;
