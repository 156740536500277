import AnimatedEllipsis from 'components/ui/AnimatedEllipsis';

const SkeletonContainerMessage = ({
  name,
  error,
}: {
  name: string;
  error: boolean;
}) => {
  return (
    <div className='text-2xl absolute flex justify-center w-full top-2/4 z-20'>
      <div className='p-6 bg-gray-darker rounded-md -mt-10'>
        {!error ? (
          <div className='text-white'>
            Loading {name} <AnimatedEllipsis />
          </div>
        ) : (
          <div className='text-orange'>Error Getting {name}</div>
        )}
      </div>
    </div>
  );
};

export default SkeletonContainerMessage;
