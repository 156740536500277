/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IBiosEmitter,
  IBiosEmitterInterface,
} from "../../../contracts/interfaces/IBiosEmitter";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_depositor",
        type: "address",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_token",
        type: "address",
      },
    ],
    name: "getEligibleToken",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_token",
        type: "address",
      },
      {
        internalType: "address",
        name: "_harvester",
        type: "address",
      },
    ],
    name: "harvest",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_harvester",
        type: "address",
      },
    ],
    name: "harvestAll",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_token",
        type: "address",
      },
    ],
    name: "updatePool",
    outputs: [
      {
        components: [
          {
            internalType: "uint128",
            name: "accBiosPerShare",
            type: "uint128",
          },
          {
            internalType: "uint64",
            name: "lastRewardBlock",
            type: "uint64",
          },
          {
            internalType: "uint64",
            name: "allocPoint",
            type: "uint64",
          },
          {
            internalType: "bool",
            name: "initialized",
            type: "bool",
          },
        ],
        internalType: "struct IBiosEmitter.PoolInfo",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_withdrawer",
        type: "address",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IBiosEmitter__factory {
  static readonly abi = _abi;
  static createInterface(): IBiosEmitterInterface {
    return new utils.Interface(_abi) as IBiosEmitterInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IBiosEmitter {
    return new Contract(address, _abi, signerOrProvider) as IBiosEmitter;
  }
}
