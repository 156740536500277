import { networks, supportedChainIds } from '@0xnodes/shared/networks';
import { IChainRewards, TChainId } from '@0xnodes/shared/types';
import SectionTitle from 'components/ui/sectionTitle/SectionTitle';
import { ArrowPrefix } from 'components/ui/prefixes/Prefix';
import {
  BiosRewardBox,
  OtherNetworkRewardBoxContainer,
  OtherNetworkRewardContainer,
  RewardBox,
} from './RewardBox/RewardBox';
import { useWeb3 } from '../../../web3';
import RewardsModal from './RewardsModal/RewardsModal';
import { useState } from 'react';
import { BigNumber } from 'ethers';
import { useUserClaimableEthRewardsAmounts } from 'hooks/rewards/useUserClaimableEthRewardsAmount';
import useUserClaimedRewardsTotal from 'hooks/rewards/useUserClaimedRewardsTotal';
import Skeletons from 'components/ui/skeletonContainer/Skeletons';
import NetworkSpecific from 'components/ui/NetworkSpecific';
import { useUserClaimableBiosRewardsAmount } from 'hooks/rewards/useUserClaimableBiosRewardsAmount';

/**
 * GraphQL Query
 */
// export const GET_KERNEL_REWARDS_DATA = gql`
//   query GetKernelRewardsData($chainId: Int!) {
//     chainRewards(chainId: $chainId) {
//       userPendingRewards
//       userRewardsEarned
//       roi
//     }
//   }
// `;

// /**
//  * Data Fetching Hook
//  */
// export interface IKernelRewardsData extends IQueryResponse {
//   chainRewards: IChainRewards | undefined;
// }

export const useKernelRewardsData = (
  chainId?: TChainId
): IChainRewards | undefined => {
  const queries = useUserClaimableEthRewardsAmounts();
  const amount = queries.reduce<BigNumber | undefined>(
    (acc, each) => (acc && each.isSuccess ? acc.add(each.data) : undefined),
    BigNumber.from(0)
  );

  const { data: totalClaimed } = useUserClaimedRewardsTotal();
  const { data: pendingBios } = useUserClaimableBiosRewardsAmount();

  return amount &&
    totalClaimed &&
    (pendingBios || chainId !== networks.ethereum) // BiosEmitter is only on ethereum
    ? {
        userPendingRewards: amount,
        userRewardsEarned: totalClaimed,
        userBiosRewardsPending: pendingBios || BigNumber.from(0),
        userBiosRewardsEarned: pendingBios || BigNumber.from(0),
        roi: 420,
      }
    : undefined;
};

/**
 * React Component
 */
const KernelRewards = () => {
  const { chainId } = useWeb3();
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(!openModal);

  const chainReward = useKernelRewardsData(chainId);

  const ready = chainId !== undefined && chainReward;

  return (
    <div>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-12'>
        <div className='flex flex-col gap-4'>
          <SectionTitle
            title={'(_Kernel) Rewards'}
            subtitle={undefined}
            description={
              <ArrowPrefix textColor='gray-light'>
                pending rewards are regularly harvested and made available to
                claim
              </ArrowPrefix>
            }
          />

          {ready ? (
            <>
              <RewardBox
                openModal={() => setOpenModal(true)}
                chainId={chainId}
                userPendingRewards={chainReward.userPendingRewards}
                userPendingRewardsValue={BigNumber.from(123)}
                userRewardsEarned={chainReward.userRewardsEarned}
                userRewardsEarnedValue={BigNumber.from(234)}
                roi={chainReward.roi}
              />
              <NetworkSpecific ethereum>
                {/* TODO: implement */}
                <BiosRewardBox
                  openModal={() => setOpenModal(true)}
                  chainId={chainId}
                  userPendingRewards={chainReward.userBiosRewardsPending}
                  userPendingRewardsValue={BigNumber.from(123)}
                  userRewardsEarned={chainReward.userBiosRewardsEarned}
                  userRewardsEarnedValue={BigNumber.from(234)}
                  roi={chainReward.roi}
                />
              </NetworkSpecific>
            </>
          ) : (
            <>
              <Skeletons
                name='KernelRewards'
                error={false}
                quantity={1}
                height={260}
                gridClassNames='gap-8 grid-cols-1'
              />
              <NetworkSpecific ethereum>
                <Skeletons
                  name='KernelRewards'
                  error={false}
                  quantity={1}
                  height={260}
                  gridClassNames='gap-8 grid-cols-1'
                />
              </NetworkSpecific>
            </>
          )}
        </div>
        <div className='flex flex-col gap-4 mt-4'>
          <SectionTitle
            small
            title={'Other network (_Kernel) rewards'}
            subtitle={undefined}
            description={
              <ArrowPrefix textColor='gray-light'>
                connect to a network to claim
              </ArrowPrefix>
            }
          />
          <OtherNetworkRewardContainer>
            {supportedChainIds
              .filter((x) => x !== chainId)
              .map((supportedChainId) => (
                <OtherNetworkRewardBoxContainer
                  key={supportedChainId}
                  chainId={supportedChainId}
                />
              ))}
          </OtherNetworkRewardContainer>
        </div>
      </div>
      <RewardsModal open={openModal} handleClose={handleClose} />
    </div>
  );
};

export default KernelRewards;
