import { SVGProps, ReactElement } from 'react';

const DEFAULT_FILL = '#00FF8B';

function Logo(
  p: SVGProps<SVGSVGElement> = { fill: DEFAULT_FILL }
): ReactElement {
  const color = p.fill || DEFAULT_FILL;
  return (
    <svg
      width='175'
      height='48'
      viewBox='0 0 175 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_1915_37557)'>
        <path
          d='M79.8379 35.9795V12.7192H83.1253L92.3751 29.322V12.7192H95.6625V35.9795H92.4159L83.1253 19.3768V35.9795H79.8379Z'
          fill={color}
        />
        <path
          d='M139.175 35.9795V12.7192H155.02V16.0275H142.483V22.7055H155.02V26.0343H142.483V32.6507H155.02V35.9795H139.175Z'
          fill={color}
        />
        <path
          d='M20.8073 12.7192L9.29102 23.0343V25.6439L20.8685 35.959H26.0753V34.0275L15.7434 24.5549V24.0823L26.0753 14.8151V12.7192H20.8073Z'
          fill={color}
        />
        <path
          d='M44.5127 12.7192V14.8151L54.8651 24.0823V24.5549L44.5127 34.0275V35.959H49.7399L61.3174 25.6439V23.0343L49.7808 12.7192H44.5127Z'
          fill={color}
        />
        <path
          d='M37.1009 12.6986L28.1982 35.9794H33.6501L42.4302 12.6986H37.1009Z'
          fill={color}
        />
        <path
          d='M68.0765 0H2.2665L0 2.46575V45.637L2.32775 48H68.1378L70.5881 45.637V2.5274L68.0765 0ZM65.565 42.3904L64.9729 42.9452H5.49268L5.04346 42.5548V5.54795L5.49268 5.05479H64.9116L65.565 5.54795V42.3904Z'
          fill={color}
        />
        <path
          d='M102.911 31.0068C102.912 31.2269 102.958 31.4444 103.046 31.6459C103.133 31.8474 103.262 32.0286 103.422 32.1781C103.733 32.4834 104.151 32.6531 104.586 32.6507H110.507C110.94 32.6448 111.355 32.4762 111.671 32.1781C111.982 31.8683 112.158 31.4471 112.161 31.0068V17.7123C112.161 17.269 111.988 16.8435 111.678 16.5281C111.368 16.2127 110.948 16.0328 110.507 16.0274H104.586C104.369 16.0254 104.153 16.0681 103.953 16.1529C103.753 16.2377 103.572 16.3628 103.422 16.5205C103.26 16.6732 103.131 16.8578 103.043 17.0629C102.955 17.2679 102.911 17.489 102.911 17.7123V31.0068ZM99.6241 17.7123C99.6214 17.0558 99.7478 16.4053 99.9962 15.7983C100.245 15.1913 100.61 14.6398 101.071 14.1756C101.533 13.7114 102.081 13.3437 102.684 13.0937C103.287 12.8437 103.933 12.7164 104.586 12.7192H110.507C111.158 12.7192 111.802 12.8485 112.403 13.0996C113.004 13.3508 113.55 13.7189 114.009 14.1829C114.468 14.6468 114.831 15.1974 115.078 15.8031C115.326 16.4087 115.451 17.0576 115.449 17.7123V31.0068C115.449 32.3257 114.928 33.5905 114.001 34.523C113.075 35.4555 111.818 35.9794 110.507 35.9794H104.586C103.933 35.9885 103.285 35.8638 102.681 35.613C102.077 35.3622 101.53 34.9905 101.074 34.5205C100.603 34.0679 100.232 33.5218 99.9821 32.917C99.7325 32.3121 99.6106 31.6616 99.6241 31.0068V17.7123Z'
          fill={color}
        />
        <path
          d='M122.697 32.6507H130.292C130.509 32.6535 130.723 32.6129 130.924 32.5316C131.124 32.4502 131.307 32.3295 131.461 32.1766C131.615 32.0237 131.737 31.8415 131.82 31.6408C131.903 31.44 131.946 31.2245 131.946 31.0069V17.7124C131.946 17.2691 131.773 16.8436 131.463 16.5282C131.154 16.2128 130.733 16.0329 130.292 16.0275H122.697V32.6507ZM119.409 35.9795V12.7192H130.292C131.603 12.7192 132.86 13.2431 133.787 14.1757C134.713 15.1082 135.234 16.373 135.234 17.6918V31.0069C135.234 32.3257 134.713 33.5905 133.787 34.5231C132.86 35.4556 131.603 35.9795 130.292 35.9795H119.409Z'
          fill={color}
        />
        <path
          d='M162.268 30.1849C162.266 30.5112 162.329 30.8345 162.456 31.1349C162.582 31.4354 162.769 31.7065 163.004 31.9315C163.478 32.3979 164.117 32.6564 164.78 32.6507H169.048C169.373 32.6553 169.696 32.5941 169.998 32.4705C170.3 32.347 170.574 32.1637 170.804 31.9315C171.034 31.6957 171.216 31.4163 171.338 31.1095C171.461 30.8027 171.522 30.4745 171.518 30.1438C171.518 25.5411 158.961 25.952 158.961 18.5548C158.958 17.7877 159.106 17.0276 159.397 16.3184C159.687 15.6091 160.114 14.9647 160.653 14.4223C161.192 13.8799 161.832 13.4501 162.537 13.1578C163.242 12.8655 163.997 12.7164 164.76 12.7192H169.007C170.536 12.7191 172.002 13.329 173.085 14.4151C174.169 15.5012 174.78 16.975 174.785 18.5137H171.518C171.521 18.1874 171.457 17.8641 171.331 17.5636C171.204 17.2632 171.018 16.9921 170.783 16.7671C170.563 16.5268 170.294 16.3365 169.995 16.2089C169.695 16.0814 169.372 16.0195 169.048 16.0274H164.76C164.432 16.0192 164.106 16.0808 163.803 16.2083C163.5 16.3358 163.228 16.5262 163.004 16.7671C162.767 16.9991 162.579 17.2773 162.453 17.5847C162.326 17.892 162.264 18.2221 162.268 18.5548C162.268 23.1575 174.806 22.7465 174.806 30.1438C174.82 30.9144 174.676 31.6796 174.38 32.3907C174.085 33.1018 173.646 33.7432 173.09 34.2739C172.561 34.8235 171.926 35.2583 171.224 35.5516C170.521 35.8449 169.767 35.9905 169.007 35.9794H164.78C164.017 35.9885 163.26 35.842 162.555 35.5489C161.849 35.2558 161.21 34.8221 160.676 34.2739C160.124 33.7494 159.686 33.115 159.391 32.411C159.095 31.707 158.949 30.9489 158.961 30.1849H162.268Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_1915_37557'>
          <rect width='174.806' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logo;
