/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ISwapManager,
  ISwapManagerInterface,
} from "../../../contracts/interfaces/ISwapManager";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "ratioX1000",
        type: "uint256",
      },
    ],
    name: "biosBuyBack",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_tokenIn",
        type: "address",
      },
      {
        internalType: "address",
        name: "_tokenOut",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "_ratioIn",
        type: "uint32",
      },
      {
        internalType: "uint32",
        name: "_ratioOut",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "_recipient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_desiredAmountIn",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_desiredMinAmountOut",
        type: "uint256",
      },
    ],
    name: "gentleSwapExactIn",
    outputs: [
      {
        internalType: "uint256",
        name: "spendedAmountIn_",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_tokenIn",
        type: "address",
      },
      {
        internalType: "address",
        name: "_tokenOut",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_amountIn",
        type: "uint256",
      },
    ],
    name: "getBestAmountOut",
    outputs: [
      {
        internalType: "bool",
        name: "isPoolExist_",
        type: "bool",
      },
      {
        internalType: "uint256",
        name: "bestPrice_",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_tokenIn",
        type: "address",
      },
      {
        internalType: "address",
        name: "_tokenOut",
        type: "address",
      },
      {
        internalType: "address",
        name: "_recipient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_amountIn",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_minAmountOut",
        type: "uint256",
      },
    ],
    name: "swapExactIn",
    outputs: [
      {
        internalType: "bool",
        name: "tradeSuccess_",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_tokenIn",
        type: "address",
      },
      {
        internalType: "address",
        name: "_tokenOut",
        type: "address",
      },
      {
        internalType: "address",
        name: "_recipient",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_amountIn",
        type: "uint256",
      },
    ],
    name: "swapExactIn",
    outputs: [
      {
        internalType: "bool",
        name: "tradeSuccess_",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class ISwapManager__factory {
  static readonly abi = _abi;
  static createInterface(): ISwapManagerInterface {
    return new utils.Interface(_abi) as ISwapManagerInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ISwapManager {
    return new Contract(address, _abi, signerOrProvider) as ISwapManager;
  }
}
