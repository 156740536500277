import { StatContainerRow } from './StatContainerRow';
import StrategiesSection from './StrategiesSection/StrategiesSection';

const StrategiesPage = () => {
  return (
    <div className='grid grid-cols-1 gap-12'>
      <StrategiesSection />
      <StatContainerRow />
    </div>
  );
};

export default StrategiesPage;
