import { useState } from 'react';
import { useNetworkName } from 'hooks/network/useNetworkName';
import { useWeb3 } from '../../../web3';
import { supportedChains, supportedChainNames } from 'web3/chains';
import Button from '../button/Button';
import ExpandButton from '../ExpandButton';
import { networkStyling, TChainStylingInfo } from '../../../data/chainData';
import SlashesPrefix from './../SlashesPrefix';
import { switchChainHelper } from './../../utils/metamaskHelper';
import { infoToast } from 'components/utils/toastHelper';

const METAMASK = 'metamask';

const ChainButton = () => {
  const { account, provider } = useWeb3();
  const networkName = useNetworkName();

  const [showChainList, setShowChainList] = useState(false);

  async function switchChain(id: number) {
    if (account) {
      setShowChainList(false);
      switchChainHelper(id);
    } else {
      infoToast('You must connect your wallet to switch networks', {
        toastId: 'connectWalletToSwitch',
      });
    }
  }

  const provider2 = provider as any;
  const allowDropdown = !account || provider2?.connection.url === METAMASK;
  if (!allowDropdown && showChainList) {
    setShowChainList(false);
  }

  const supportedChainList = supportedChains();

  return (
    <div className={`flex`}>
      <Button
        active={showChainList}
        color='white'
        onClick={() => {
          if (allowDropdown) {
            setShowChainList(!showChainList);
          }
        }}
        className={'w-full md:w-auto flex'}
      >
        <div>{networkName ? networkName : 'networks'}</div>

        {allowDropdown && supportedChainList.length > 0 && (
          <ExpandButton
            black
            arrowUp={showChainList}
            className='ml-2'
            size={4}
          />
        )}
      </Button>

      <br />
      {showChainList && (
        <ChainSelectionMenu
          switchChain={switchChain}
          setShowChainList={setShowChainList}
          supportedChainList={supportedChainList}
        />
      )}
    </div>
  );
};

const ChainSelectionMenu = ({
  switchChain,
  setShowChainList,
  supportedChainList,
}: {
  switchChain: Function;
  setShowChainList: Function;
  supportedChainList: any[];
}) => {
  const { chainId } = useWeb3();

  return (
    <div className='relative'>
      <div
        onClick={() => setShowChainList(false)}
        className='w-screen h-screen fixed left-0 top-0'
      ></div>
      <div
        className={`flex-col absolute mt-8 top-6 right-0 p-4 bg-gray-darker w-max rounded border-2 border-gray-dark grid grid-cols-1 gap-2 z-10`}
      >
        {supportedChainList.map((id) => (
          <ChainSelectionOption
            key={id}
            chainId={id}
            chainName={supportedChainNames(id)}
            selected={id === chainId}
            switchChain={switchChain}
          />
        ))}
      </div>
    </div>
  );
};
ChainButton.ChainMenu = ChainSelectionMenu;

const ChainSelectionOption = ({
  chainId,
  chainName,
  selected,
  switchChain,
}: {
  chainId: number;
  chainName: string | null;
  selected: boolean;
  switchChain: Function;
}) => {
  let chainStyling: TChainStylingInfo = networkStyling[chainId];

  const [isHover, setIsHover] = useState(false);

  if (!chainStyling) {
    console.log(`WARNING: no chain data found for chainId: ${chainId}`);
    return <></>;
  }

  return (
    <button
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={selected ? undefined : () => switchChain(chainId)}
      className={`p-2 rounded text-left w-64 text-white flex ${
        selected ? 'font-black' : ''
      }`}
      style={{
        backgroundColor: chainStyling.color + (isHover ? '44' : '0D'),
        border: `${selected ? '2px solid' : '1px dashed'} ${
          chainStyling.color
        }`,
      }}
    >
      <div className='h-7 w-7 mr-2'>
        <chainStyling.Icon />
      </div>

      <div className='flex flex-col justify-center h-full'>
        <SlashesPrefix slashColor='green'>
          <div>{chainName}</div>
        </SlashesPrefix>
      </div>

      {selected ? (
        <div className='ml-auto mr-2 h-full flex flex-col justify-center'>
          <div
            className={`h-2 w-2 border rounded-full`}
            style={{
              backgroundColor: chainStyling.color,
              borderColor: chainStyling.color,
            }}
          />
        </div>
      ) : null}
    </button>
  );
};

export default ChainButton;
