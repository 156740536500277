// https://github.com/Uniswap/interface/blob/main/src/hooks/useContract.ts

import { MODULES, wrappedNativeTokens } from '@0xnodes/shared/addresses';
import { Provider } from '@ethersproject/providers';
import {
  BiosEmitterV0,
  BiosEmitterV0__factory,
  BiosRewards,
  BiosRewards__factory,
  EtherRewards,
  EtherRewards__factory,
  IERC20MetadataUpgradeable,
  IERC20MetadataUpgradeable__factory,
  IntegrationMap,
  IntegrationMap__factory,
  Interconnects,
  Interconnects__factory,
  IUserPositionsV2,
  IWeth9,
  IWeth9__factory,
  Kernel,
  Kernel__factory,
  ModuleMap,
  ModuleMap__factory,
  Strategy1155,
  Strategy1155__factory,
  StrategyManager,
  StrategyManager__factory,
  StrategyMap,
  StrategyMap__factory,
  SushiSwapTrader,
  SushiSwapTrader__factory,
  SwapManager,
  SwapManager__factory,
  SyntheticMap,
  SyntheticMap__factory,
  UniswapTrader,
  UniswapTrader__factory,
  Unwrapper,
  Unwrapper__factory,
  UserPositions,
  UserPositionsV2__factory,
  UserPositions__factory,
  YieldManager,
  YieldManager__factory,
} from 'assets/typechain';
import { networkStyling } from 'data/chainData';
import { Contract, constants, Signer, ethers } from 'ethers';
import { getAddress } from 'ethers/lib/utils';
import { useMemo } from 'react';
import { useQuery as useReactQuery } from 'react-query';
import { useWeb3 } from '../web3';
import { useModuleMapAddress } from '../web3/chains';

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  library: Provider | Signer
): Contract {
  if (!isAddress(address) || address === constants.AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, library);
}

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any
): T | null {
  const { signerOrProvider, chainId } = useWeb3();

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !signerOrProvider || !chainId)
      return null;
    let address: string | undefined;
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap;
    else address = addressOrAddressMap[chainId];
    if (!address) return null;
    try {
      // we do this to satisfy typescript and to ensure the contracts get refreshed on account change
      // if (account || !account)
      return getContract(address, ABI, signerOrProvider);
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [addressOrAddressMap, ABI, signerOrProvider, chainId]) as T;
}

export function useContractByModule<T extends Contract = Contract>(
  module: number,
  ABI: any
): T | null {
  const { data: address } = useModuleAddress(module);
  return useContract<T>(address, ABI);
}

export function useTokenContract(tokenAddress?: string) {
  return useContract<IERC20MetadataUpgradeable>(
    tokenAddress,
    IERC20MetadataUpgradeable__factory.abi
  );
}

export function useWrappedNativeContract() {
  const { chainId } = useWeb3();
  return useContract<IWeth9>(
    chainId ? wrappedNativeTokens[chainId]?.wrappedAddress : undefined,
    IWeth9__factory.abi
  );
}

export function useModuleMapContract() {
  const { chainId } = useWeb3();
  const moduleMapAddress = useModuleMapAddress(chainId);
  return useContract<ModuleMap>(moduleMapAddress, ModuleMap__factory.abi);
}

export function useModuleAddress(module?: number) {
  const { chainId } = useWeb3();
  const moduleMap = useModuleMapContract();

  const ready = !!(chainId && moduleMap && typeof module === 'number');

  return useReactQuery(
    [chainId, 'moduleAddress', module],
    () => {
      if (!ready) {
        throw new Error('useModuleAddress is not ready!');
      }

      return moduleMap.getModuleAddress(module);
    },
    {
      enabled: ready,
    }
  );
}

export function useModuleAddressByChain(
  module: number,
  chainId: number | undefined
) {
  const moduleMapAddress = useModuleMapAddress(chainId);
  const rpcUrl = chainId && networkStyling[chainId]?.rpcUrl;

  const ready = !!(
    chainId &&
    moduleMapAddress &&
    rpcUrl &&
    typeof module === 'number'
  );

  return useReactQuery(
    [chainId, 'moduleAddress', module],
    () => {
      if (!ready) {
        throw new Error('useModuleAddress is not ready!');
      }

      const provider = new ethers.providers.JsonRpcBatchProvider(rpcUrl);
      const moduleMap = ModuleMap__factory.connect(moduleMapAddress, provider);

      return moduleMap.getModuleAddress(module);
    },
    {
      enabled: ready,
    }
  );
}

export function useKernelContract() {
  return useContractByModule<Kernel>(MODULES.kernel, Kernel__factory.abi);
}

export function useUserPositionsContract() {
  return useContractByModule<UserPositions>(
    MODULES.userPositions,
    UserPositions__factory.abi
  );
}

// prior to tokenization
export function useUserPositionsV2Contract() {
  return useContractByModule<IUserPositionsV2>(
    MODULES.userPositions,
    UserPositionsV2__factory.abi
  );
}

export function useYieldManagerContract() {
  return useContractByModule<YieldManager>(
    MODULES.yieldManager,
    YieldManager__factory.abi
  );
}

export function useIntegrationMapContract() {
  return useContractByModule<IntegrationMap>(
    MODULES.integrationMap,
    IntegrationMap__factory.abi
  );
}

/**
 * NOTE this is for the legacy BiosRewards, you probably want {@link useBiosEmitterContract}!
 *
 * @deprecated
 */
export function useBiosRewardsContract(): BiosRewards | null {
  return useContractByModule<BiosRewards>(
    MODULES.biosRewards,
    BiosRewards__factory.abi
  );
}

export function useEtherRewardsContract() {
  return useContractByModule<EtherRewards>(
    MODULES.etherRewards,
    EtherRewards__factory.abi
  );
}

export function useSushiSwapTraderContract() {
  return useContractByModule<SushiSwapTrader>(
    MODULES.sushiSwapTrader,
    SushiSwapTrader__factory.abi
  );
}

export function useUniswapTraderContract() {
  return useContractByModule<UniswapTrader>(
    MODULES.uniswapTrader,
    UniswapTrader__factory.abi
  );
}

export function useStrategyMapContract() {
  return useContractByModule<StrategyMap>(
    MODULES.strategyMap,
    StrategyMap__factory.abi
  );
}

export function useStrategyManagerContract() {
  return useContractByModule<StrategyManager>(
    MODULES.strategyManager,
    StrategyManager__factory.abi
  );
}

export function useInterconnectsContract() {
  return useContractByModule<Interconnects>(
    MODULES.interconnects,
    Interconnects__factory.abi
  );
}

export function useSwapManagerContract() {
  return useContractByModule<SwapManager>(
    MODULES.swapManager,
    SwapManager__factory.abi
  );
}

export function useUnwrapperContract() {
  return useContractByModule<Unwrapper>(
    MODULES.unwrapper,
    Unwrapper__factory.abi
  );
}

export function useBiosEmitterContract() {
  return useContractByModule<BiosEmitterV0>(
    MODULES.biosEmitter,
    BiosEmitterV0__factory.abi
  );
}

export function useStrategy1155Contract() {
  return useContractByModule<Strategy1155>(
    MODULES.strategy1155,
    Strategy1155__factory.abi
  );
}

export function useSyntheticMapContract() {
  return useContractByModule<SyntheticMap>(
    MODULES.syntheticMap,
    SyntheticMap__factory.abi
  );
}
