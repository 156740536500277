/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ICurveFiFactory,
  ICurveFiFactoryInterface,
} from "../../../contracts/mock/ICurveFiFactory";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_pool",
        type: "address",
      },
    ],
    name: "deploy_gauge",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_base_pool",
        type: "address",
      },
      {
        internalType: "string",
        name: "_name",
        type: "string",
      },
      {
        internalType: "string",
        name: "_symbol",
        type: "string",
      },
      {
        internalType: "address",
        name: "_coin",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_A",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_fee",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_implementation_idx",
        type: "uint256",
      },
    ],
    name: "deploy_metapool",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_name",
        type: "string",
      },
      {
        internalType: "string",
        name: "_symbol",
        type: "string",
      },
      {
        internalType: "address[4]",
        name: "_coins",
        type: "address[4]",
      },
      {
        internalType: "uint256",
        name: "_A",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_fee",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_asset_type",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_implementation_index",
        type: "uint256",
      },
    ],
    name: "deploy_plain_pool",
    outputs: [
      {
        internalType: "address",
        name: "deployedPool_",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_from",
        type: "address",
      },
      {
        internalType: "address",
        name: "_to",
        type: "address",
      },
    ],
    name: "find_pool_for_coins",
    outputs: [
      {
        internalType: "address",
        name: "findedPool_",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_pool",
        type: "address",
      },
    ],
    name: "get_gauge",
    outputs: [
      {
        internalType: "address",
        name: "_gauge",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class ICurveFiFactory__factory {
  static readonly abi = _abi;
  static createInterface(): ICurveFiFactoryInterface {
    return new utils.Interface(_abi) as ICurveFiFactoryInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ICurveFiFactory {
    return new Contract(address, _abi, signerOrProvider) as ICurveFiFactory;
  }
}
