import { IToken, TChainId, TTokenAddress } from '@0xnodes/shared/src/types';
import { useQuery } from '@apollo/client';
import { GET_KERNEL_MANAGE_MODAL_DATA } from 'components/ManageModal/KernelManageModal';
import { ethers } from 'ethers';
import { useWeb3 } from '../../web3';

export const useAssetData = (address?: string) => {
  const { chainId } = useWeb3();
  return useAssetDataByChain(chainId, address);
};

export const useAssetDataByChain = (
  chainId?: TChainId,
  address?: TTokenAddress
) => {
  const { data } = useQuery<{ assets: { tokens: IToken[] } }>(
    GET_KERNEL_MANAGE_MODAL_DATA,
    {
      variables: { chainId },
      skip: typeof chainId === undefined,
    }
  );

  //   const ready = !!(address && chainId && assetsData);
  if (address && chainId && data) {
    const target = ethers.utils.getAddress(address);
    return data.assets.tokens.find(
      (e) => target === ethers.utils.getAddress(e.address)
    );
  } else {
    return undefined;
  }
};

export default useAssetData;
