import { networkColors, networks } from '@0xnodes/shared/networks';

import EthOutline from 'assets/icons/Network/Ethereum/EthereumOutline';
import EthIcon from 'assets/icons/assets/eth_icon.svg';

import PolOutline from 'assets/icons/Network/Polygon/PolygonOutline';
import MaticIcon from 'assets/icons/assets/matic_icon.svg';

import FanOutline from 'assets/icons/Network/Fantom/FantomOutline';
import FtmIcon from 'assets/icons/assets/ftm_icon.svg';

import AvaOutline from 'assets/icons/Network/Avalanche/AvalancheOutline';
import AvaxIcon from 'assets/icons/assets/avax_icon.svg';

import BSCOutline from 'assets/icons/Network/BSC/BSCOutline';
import BnbIcon from 'assets/icons/assets/bnb_icon.svg';

import MetisOutline from 'assets/icons/Network/Metis/MetisOutline';
import MetisIcon from 'assets/icons/assets/metis_icon.svg';

import { TChainId } from '@0xnodes/shared/types';
import imgSrcHelper from 'components/utils/imgSrcHelpepr';

export type TChainStylingInfo = {
  label: string;
  color: string;
  bgImage: string;
  Icon: any;
  nativeAssetIcon: any;
  rpcUrl: string;
};

const makeBgImage = (slug: string) =>
  imgSrcHelper(require(`assets/images/chainOutlines/${slug}_big_outline.png`));

const ethereumStyling: TChainStylingInfo = {
  label: 'Ethereum',
  color: networkColors[networks.ethereum],
  bgImage: makeBgImage('ethereum'),
  Icon: EthOutline,
  nativeAssetIcon: EthIcon,
  rpcUrl: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`,
};

const polygonStyling: TChainStylingInfo = {
  label: 'Polygon',
  color: networkColors[networks.polygon],
  bgImage: makeBgImage('polygon'),
  Icon: PolOutline,
  nativeAssetIcon: MaticIcon,
  rpcUrl: 'https://polygon-rpc.com',
};

const fantomStyling: TChainStylingInfo = {
  label: 'Fantom',
  color: networkColors[networks.fantom],
  bgImage: makeBgImage('fantom'),
  Icon: FanOutline,
  nativeAssetIcon: FtmIcon,
  rpcUrl: 'https://rpc.ftm.tools',
};

const avalancheStyling: TChainStylingInfo = {
  label: 'Avalanche',
  color: networkColors[networks.avalanche],
  bgImage: makeBgImage('avalanche'),
  Icon: AvaOutline,
  nativeAssetIcon: AvaxIcon,
  rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
};

const bscStyling: TChainStylingInfo = {
  label: 'BSC',
  color: networkColors[networks.bsc],
  bgImage: makeBgImage('bsc'),
  Icon: BSCOutline,
  nativeAssetIcon: BnbIcon,
  rpcUrl: 'https://bsc-dataseed.binance.org/',
};

const metisStyling: TChainStylingInfo = {
  label: 'Andromeda',
  color: networkColors[networks.metis],
  bgImage: makeBgImage('metis'),
  Icon: MetisOutline,
  nativeAssetIcon: MetisIcon,
  rpcUrl: 'https://andromeda.metis.io/?owner=1088',
};

export const networkStyling: Record<TChainId, TChainStylingInfo> = {
  [networks.ethereum]: ethereumStyling,
  [networks.localhost]: ethereumStyling,

  [networks.polygon]: polygonStyling,
  [networks.local_polygon]: polygonStyling,

  [networks.fantom]: fantomStyling,
  [networks.local_fantom]: fantomStyling,

  [networks.avalanche]: avalancheStyling,
  [networks.local_avalanche]: avalancheStyling,

  [networks.bsc]: bscStyling,
  [networks.local_bsc]: bscStyling,

  [networks.metis]: metisStyling,
  [networks.local_metis]: metisStyling,
};
