import { nativeCurrencies, tokenAddresses } from '@0xnodes/shared/addresses';
import { TChainId } from '@0xnodes/shared/types';
import { getNetworkName } from '@0xnodes/shared/utils';
import { assetFormat } from 'components/ui/numberFormats';
import SmallContainer from 'components/ui/smallContainer/SmallContainer';
import { formatUnits } from 'ethers/lib/utils';
import { useSystemTotalDeposits } from 'hooks/tokens/useSystemTotalDeposits';

export interface NetworkTvlContainerProps {
  chainId?: TChainId;
}

// const NetworkTvlContainer = ({ networkStats }: NetworkTvlContainerProps) => {
//   return (
//     <SmallContainer title={'Network TVL'}>
//       {networkStats &&
//         networkStats.map((stats) => {
//           const tvlValue = formatEther(stats.tvl);
//           return (
//             <div key={stats.id} className='flex justify-between'>
//               <div className='flex'>
//                 <div className='mr-2 text-green'>
//                   {getNetworkName(networks[stats.id])}
//                 </div>
//                 <div className='text-white'>{assetFormat(tvlValue)}</div>
//               </div>
//               <div className='text-gray-light'>{dollar(tvlValue)}</div>
//             </div>
//           );
//         })}
//     </SmallContainer>
//   );
// };

const NetworkTvlContainer = ({ chainId }: NetworkTvlContainerProps) => {
  const { data: wnativeAmount } = useSystemTotalDeposits(
    chainId ? nativeCurrencies[chainId]?.wrappedAddress : ''
  );
  const { data: biosAmount } = useSystemTotalDeposits(
    chainId ? tokenAddresses[chainId]?.BIOS : ''
  );
  return (
    <SmallContainer title={'Network TVL'}>
      {chainId ? (
        <div className='flex-col justify-between'>
          <div className='flex'>
            <div className='mr-2 text-green'>{getNetworkName(chainId)}</div>
            <div className='text-white'>
              {wnativeAmount
                ? `${assetFormat(
                    formatUnits(
                      wnativeAmount,
                      nativeCurrencies[chainId]?.decimals
                    )
                  )} ${nativeCurrencies[chainId]?.symbol}`
                : '---'}
            </div>
          </div>
          <div className='flex'>
            <div className='mr-2' style={{ color: 'transparent' }}>
              {getNetworkName(chainId)}
            </div>
            <div className='text-white'>
              {biosAmount
                ? `${assetFormat(formatUnits(biosAmount))} BIOS`
                : '---'}
            </div>
          </div>
        </div>
      ) : (
        'Loading...'
      )}
    </SmallContainer>
  );
};

export default NetworkTvlContainer;
