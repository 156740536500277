import { networkColors } from '@0xnodes/shared/networks';

const HamburgerMenuIcon = () => {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 64 64'
      fill={networkColors[1]}
      xmlSpace='preserve'
    >
      <g>
        <g id='Navicon' transform='translate(330.000000, 232.000000)'>
          <polygon
            className='st0'
            points='-321.8,-219 -274.3,-219 -274.3,-212.7 -321.8,-212.7'
          />
          <polygon
            className='st0'
            points='-321.8,-203.2 -274.3,-203.2 -274.3,-196.8 -321.8,-196.8'
          />
          <polygon
            className='st0'
            points='-321.8,-187.3 -274.3,-187.3 -274.3,-181 -321.8,-181'
          />
        </g>
      </g>
    </svg>
  );
};

export default HamburgerMenuIcon;
