import { useEffect, useState } from 'react';
import { BigNumber } from 'ethers';
import { gql, useQuery } from '@apollo/client';
import { supportedChainIds } from '@0xnodes/shared/networks';
import Card from 'components/ui/card/Card';
import { assetFormat } from 'components/ui/numberFormats';
import { SlashPrefix } from '../../../components/ui/prefixes/Prefix';
import StrategyManageModal from 'components/ManageModal/StrategyManageModal';
import { IQueryResponse, IStrategy, TChainId } from '@0xnodes/shared/types';
import { errorToast } from 'components/utils/toastHelper';
import Skeletons from 'components/ui/skeletonContainer/Skeletons';
import { useWeb3 } from '../../../web3';
import { useUserStrategyBalances } from 'hooks/strategies/useUserStrategyBalance';
import { useNativeSymbol } from 'hooks/tokens/useNativeSymbol';
import { formatEther } from 'ethers/lib/utils';
import AnimatedEllipsis from 'components/ui/AnimatedEllipsis';
import { ManageModalState } from 'components/ManageModal/ManageModalContainer';

/**
 * GraphQL Query
 */
export const GET_STRATEGY_KERNEL_DATA = gql`
  query GetStrategyKernelData($chainId: Int!) {
    strategies(chainId: $chainId) {
      id
      chainId
      name
      description
      farms {
        symbol
        averageEntry
      }
      apy
      availableRewards
      globalDeposits
      pendingYield
    }
    # activeStrategies(chainId: $chainId) {
    #   availableRewards
    #   positions
    #   strategies {
    #     id
    #     name
    #     description
    #     farms {
    #       symbol
    #       averageEntry
    #     }
    #     apy
    #     availableRewards
    #     userPosition
    #   }
    # }
  }
`;

/**
 * Data Fetching Hook
 */
export interface IStrategyKernelData extends IQueryResponse {
  strategies: IStrategy[] | undefined;
  totalPositions: BigNumber;
  activeStrategies: number[];
}

export const useStrategyKernelData = (
  chainId?: TChainId
): IStrategyKernelData => {
  const { loading, error, data } = useQuery(GET_STRATEGY_KERNEL_DATA, {
    variables: { chainId },
    skip: typeof chainId === 'undefined',
  });
  const balances = useUserStrategyBalances(
    chainId &&
      data?.strategies
        ?.filter((each: IStrategy) => each.chainId === chainId)
        .map((each: IStrategy) => ({
          strategyId: parseInt(each.id.split('#')[1], 10),
          token: each.underlying,
        }))
  );

  const { strategies = [] } =
    (data as { strategies: IStrategy[] | undefined }) || {};

  const { totalPositions, activeStrategies } = balances.reduce(
    (acc, each, index) => ({
      totalPositions: acc.totalPositions.add(each.data || 0),
      activeStrategies: each.data?.gt(0)
        ? [
            ...acc.activeStrategies,
            parseInt(strategies[index].id.split('#')[1], 10),
          ]
        : acc.activeStrategies,
    }),
    { totalPositions: BigNumber.from(0), activeStrategies: [] as number[] }
  );

  return {
    loading,
    error,
    strategies,
    totalPositions,
    activeStrategies,
  };
};

/**
 * React Component
 */
const StrategyKernel = () => {
  const { chainId } = useWeb3();
  const [strategyModalState, setStrategyState] = useState(
    ManageModalState.closed
  );
  const { loading, error, strategies, totalPositions, activeStrategies } =
    useStrategyKernelData(chainId);
  const symbol = useNativeSymbol();

  // const handleManageClick = (chainId: number) => {
  //   if (!strategies) return;
  //   // setSelectedStrategy(activeStrategies[chainId].strategies[0]);
  //   setStrategyState(true);
  // };

  useEffect(() => {
    if (error !== undefined && loading) {
      errorToast(
        <div>
          <div>Error getting strategies</div>
          <div className='text-sm'>{error.message}</div>
        </div>,
        { toastId: 'strategyKernelError' }
      );
    }
  }, [error, loading]);

  return (
    <Card
      lineBackground
      title={
        <div className='w-full flex justify-between'>
          <div>
            Active Stategies [
            <span className='text-green'>{activeStrategies?.length || 0}</span>]
          </div>
          <div>
            <SlashPrefix className='text-sm text-right' textColor='gray-light'>
              Total Positions
            </SlashPrefix>
            <div className='-my-2 text-white text-2xl text-right'>
              {assetFormat(formatEther(totalPositions))} {symbol}
            </div>
          </div>
        </div>
      }
    >
      <h6 className='text-xl'>
        portfolio view coming soon
        <AnimatedEllipsis />
      </h6>
      <div className='flex flex-col gap-8'>
        {loading || error ? (
          <Skeletons
            gridClassNames={`gap-8 grid-cols-1`}
            name={'Strategies'}
            error={error !== undefined}
            height={124}
            quantity={3}
          />
        ) : (
          strategies &&
          supportedChainIds
            .filter((x: TChainId) => strategies[x])
            .map((chainId) => (
              // <StrategyKernelNetworkContainer
              //   chainId={chainId}
              //   activeStrategyData={strategies}
              //   handleManageClick={handleManageClick}
              // />
              <span key={chainId}></span>
            ))
        )}
      </div>
      <StrategyManageModal
        state={strategyModalState}
        setState={setStrategyState}
      />
    </Card>
  );
};

export default StrategyKernel;
