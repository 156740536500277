import * as React from 'react';
import ReactGA from 'react-ga4';
import { useWeb3 } from './web3';
import Navbar from 'components/routing/Navbar';
import StrategyProvider from 'contexts/strategyContext_new';
import linePattern from 'assets/images/linePattern.png';
import { ToastContainer } from 'react-toastify';
import ApplicationRoutes from './pages';
import BackgroundStuff from 'components/BackgroundStuff/BackgroundStuff';

const TRACKING_ID = 'G-L2P8GS1BFR';

const TERMINAL_THEMES: Record<number, string> = {
  1: 'terminal-theme-mainnet',
  31337: 'terminal-theme-mainnet',

  137: 'terminal-theme-polygon',
  31338: 'terminal-theme-polygon',

  43114: 'terminal-theme-avalanche',
  31339: 'terminal-theme-avalanche',

  250: 'terminal-theme-fantom',
  31340: 'terminal-theme-fantom',

  56: 'terminal-theme-bsc',
  31341: 'terminal-theme-bsc',

  1088: 'terminal-theme-metis',
  31343: 'terminal-theme-metis',
};

function AppDos() {
  React.useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
    });
  }, []);
  const { chainId } = useWeb3();
  const terminalTheme =
    (chainId && TERMINAL_THEMES[chainId]) || 'terminal-theme-mainnet';

  return (
    <StrategyProvider>
      <div
        className={`relative bg-black bg-right-top bg-no-repeat justify-between text-white text-animation terminal-bg ${terminalTheme} pb-96`}
        style={{
          background: `url(${linePattern}), #111111`,
          minHeight: '1800px',
        }}
      >
        <div className='terminal-line'></div>
        <div className='relative z-10 pb-16'>
          <Navbar />
          <div className='pt-40 md:pt-0 mx-4 md:mx-8 flex justify-center '>
            <div style={{ maxWidth: '1440px' }} className='py-8 w-full'>
              <ApplicationRoutes />
            </div>
          </div>
        </div>
        <BackgroundStuff />
      </div>
      <ToastContainer
        position='bottom-right'
        closeButton={false}
        newestOnTop={false}
        toastClassName={() =>
          'relative my-2 min-h-10 justify-between overflow-hidden cursor-pointer'
        }
        bodyClassName={() => 'text-sm bg-gray-darkest'}
      />
    </StrategyProvider>
  );
}

export default AppDos;
