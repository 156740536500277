import { supportedChainIds } from '@0xnodes/shared/networks';
import { TChainId } from '@0xnodes/shared/types';
import { ManageModalState } from 'components/ManageModal/ManageModalContainer';
import Button from 'components/ui/button/Button';
import DropdownButton from 'components/ui/dropdown/Dropdown';
// import imgSrcHelper from 'components/utils/imgSrcHelpepr';
import { Dispatch, SetStateAction } from 'react';
import { networkStyling } from 'data/chainData';

// TODO: img source is sloppy. look for cleaner implementation
// const cardViewIcon = imgSrcHelper(require('assets/icons/card_view_icon.png'));
// const tableViewIcon = imgSrcHelper(require('assets/icons/table_view_icon.png'));

export const StrategiesHeader = ({
  tableView,
  setTableView,
  filteredChains,
  handleChainClick,
  currentSort,
  handleSortClick,
  sorts,
  setModalState,
}: {
  tableView: boolean;
  setTableView: Function;
  filteredChains: number[];
  handleChainClick: Function;
  currentSort: any;
  handleSortClick: Function;
  sorts: any[];
  setModalState: Dispatch<SetStateAction<ManageModalState>>;
}) => {
  const strategyChainOptions = supportedChainIds.map((chainId: TChainId) => {
    return {
      label: networkStyling[chainId].label,
      value: chainId,
      bgColor: networkStyling[chainId].color,
      icon: networkStyling[chainId].Icon,
    };
  });

  return (
    <div className='grid grid-cols-1 gap-4 md:gap-0 md:flex md:justify-between'>
      <div className='flex gap-4'>
        <Button
          secondary
          onClick={() => setModalState(ManageModalState.deposit)}
        >
          Deposit
        </Button>
        <Button
          secondary
          onClick={() => setModalState(ManageModalState.withdraw)}
        >
          Withdraw
        </Button>
      </div>
      <div className='flex justify-between md:justify-start gap-4'>
        <DropdownButton
          options={strategyChainOptions}
          onClick={handleChainClick}
          selectedList={filteredChains}
        >
          Strategy [<span className='text-green'>{filteredChains.length}</span>]
        </DropdownButton>
        <DropdownButton
          compact
          options={sorts}
          onClick={handleSortClick}
          selectedList={[currentSort.value]}
        >
          Sort by
        </DropdownButton>
        {/* table view is not very good yet :p */}
        {/* <Button
          className='hidden lg:block'
          color='white'
          onClick={() => setTableView(!tableView)}
        >
          <img
            src={!tableView ? tableViewIcon : cardViewIcon}
            alt='tableViewButtonIcon'
          />
        </Button> */}
      </div>
    </div>
  );
};

export default StrategiesHeader;
