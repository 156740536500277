import Button from 'components/ui/button/Button';
import { Dispatch, SetStateAction } from 'react';
import { ManageModalState } from '../ManageModalContainer';

const ManageModalTabBar = ({
  isInDepositingTab,
  setState,
  isStrategyManage,
}: {
  isInDepositingTab: boolean;
  setState: Dispatch<SetStateAction<ManageModalState>>;
  isStrategyManage: boolean;
}) => {
  return (
    <div className='grid grid-cols-1 sm:grid-cols-2'>
      <Button
        secondary
        color={isInDepositingTab ? 'green' : 'gray'}
        onClick={() => !isInDepositingTab && setState(ManageModalState.deposit)}
      >
        {isStrategyManage ? '.Enter.Strategy' : '.Deposit'}
      </Button>
      <Button
        secondary
        color={!isInDepositingTab ? 'green' : 'gray'}
        onClick={() => isInDepositingTab && setState(ManageModalState.withdraw)}
      >
        {isStrategyManage ? '.Exit.Strategy' : '.Withdraw'}
      </Button>
    </div>
  );
};

export default ManageModalTabBar;
