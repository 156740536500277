import { IStrategy } from '@0xnodes/shared/types';
import React, { useState } from 'react';

export interface IStrategyContext {
  selectedStrategy: IStrategy | undefined;
  setSelectedStrategy(strategy: IStrategy): void;
}

export const StrategyContext = React.createContext<IStrategyContext>({
  selectedStrategy: undefined,
  setSelectedStrategy: (strategy: IStrategy) => {},
});

function StrategyProvider({ children }: { children: React.ReactNode }) {
  const [selectedStrategy, setSelectedStrategy] = useState<
    IStrategy | undefined
  >();

  const value: IStrategyContext = {
    selectedStrategy,
    setSelectedStrategy,
  };

  return (
    <StrategyContext.Provider value={value}>
      {children}
    </StrategyContext.Provider>
  );
}

export default StrategyProvider;
