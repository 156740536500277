import Disclaimer from 'components/ui/disclaimer/Disclaimer';
import { Routes, Route } from 'react-router-dom';
import StrategiesPage from './StrategiesPage/StrategiesPage';
import KernelPage from './KernelPage/KernelPage';

// Since routes are regular React components, they
// may be rendered anywhere in the app, including in
// child elements.
//
// This helps when it's time to code-split your app
// into multiple bundles because code-splitting a
// React Router app is the same as code-splitting
// any other React app.

const PageLayout = ({ children }: { children?: JSX.Element | string }) => (
  <div className=''>{children}</div>
);

const disclaimerNote = (
  <div className='text-gray-light'>
    The council wishes to inform you that
    <br />
    <span className='text-green'> system.11 is in beta </span>
    and is not guaranteed to be free from bugs or exploits. Use at your own
    risk.
  </div>
);

const disclaimerReadMore = (
  <div className='text-white'>
    SYSTEM.11 IS IN BETA. YOUR USE OF THE SYSTEM.11 PROTOCOL INVOLVES VARIOUS
    RISKS, INCLUDING, BUT NOT LIMITED TO, LOSSES WHILE DIGITAL ASSETS ARE BEING
    SUPPLIED TO, OR USED WITHIN, THE SYSTEM.11 PROTOCOL AND LOSSES DUE TO THE
    FLUCTUATION OF PRICES OF TOKENS. BEFORE USING THE SYSTEM.11 PROTOCOL, YOU
    SHOULD REVIEW THE RELEVANT DOCUMENTATION TO MAKE SURE YOU UNDERSTAND HOW THE
    SYSTEM.11 PROTOCOL WORKS. ADDITIONALLY, JUST AS YOU CAN ACCESS EMAIL
    PROTOCOLS SUCH AS SMTP THROUGH MULTIPLE EMAIL CLIENTS, YOU CAN ACCESS THE
    SYSTEM.11 PROTOCOL THROUGH DOZENS OF WEB OR MOBILE INTERFACES. YOU ARE
    RESPONSIBLE FOR DOING YOUR OWN DILIGENCE ON THOSE INTERFACES TO UNDERSTAND
    THE FEES AND RISKS THEY PRESENT. SYSTEM.11 PROTOCOL IS PROVIDED "AS IS", AT
    YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY KIND. NO DEVELOPER OR ENTITY
    INVOLVED IN CREATING THE SYSTEM.11 PROTOCOL WILL BE LIABLE FOR ANY CLAIMS OR
    DAMAGES WHATSOEVER ASSOCIATED WITH YOUR USE, INABILITY TO USE, OR YOUR
    INTERACTION WITH OTHER USERS OF, THE SYSTEM.11 PROTOCOL, INCLUDING ANY
    DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL
    DAMAGES, OR LOSS OF PROFITS, CRYPTOCURRENCIES, TOKENS, OR ANYTHING ELSE OF
    VALUE.
  </div>
);

const Systems = () => <div>Systems</div>;

function ApplicationRoutes() {
  return (
    <div className='grid grid-cols-1 gap-12'>
      <Disclaimer
        className={'max-w-2/3 min-w-76 '}
        readMoreContent={disclaimerReadMore}
      >
        {disclaimerNote}
      </Disclaimer>
      <PageLayout>
        <Routes>
          <Route path=''>
            <Route index element={<StrategiesPage />} />
            <Route path='kernel' element={<KernelPage />} />
            <Route path='systems' element={<Systems />} />
          </Route>
        </Routes>
      </PageLayout>
    </div>
  );
}

export default ApplicationRoutes;
