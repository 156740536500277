import { MouseEventHandler, ReactNode } from 'react';

const GREEN = 'green';
const WHITE = 'white';
const GRAY = 'gray';
const BLACK = 'black';
export interface ButtonProps {
  onClick: MouseEventHandler;
  children: ReactNode;
  secondary?: boolean;
  color?: 'green' | 'white' | 'gray' | 'black';
  disabled?: boolean;
  active?: boolean;
  className?: string;
  style?: object;
}

function Button({
  onClick,
  disabled,
  active,
  children,
  secondary,
  className,
  color,
  style,
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`
      h-12 px-8 py-3 rounded
      ${
        secondary
          ? color === GREEN
            ? `bg-transparent hover:bg-green-darkest border ${
                !disabled
                  ? `border-green hover:bg-green-darker hover:border-green`
                  : `border-green-dark`
              }`
            : color === BLACK
            ? `bg-transparent text-black hover:bg-green-darkest border ${
                !disabled
                  ? `border-green hover:bg-green-darker hover:border-green`
                  : `border-green-dark`
              }`
            : color === GRAY
            ? `bg-transparent hover:bg-gray-darker border border-gray-lighter`
            : `bg-orange-darkest border ${
                !disabled
                  ? `border-orange hover:bg-orange-darker hover:border-orange`
                  : `border-orange-darker`
              }`
          : // : color === WHITE
            // ? `bg-gray-dark border border-gray-lighter ${
            //     !disabled ? `hover:border-gray-light` : ``
            //   }`
            `${
              !active ? 'bg-gray-darker' : 'bg-gray-dark'
            } border border-gray-darker ${
              !disabled ? `hover:bg-gray-dark hover:border-gray-dark` : ``
            }`
      }
      ${
        color === GREEN
          ? `${!disabled ? `text-green` : `text-green-darker`}`
          : color === GRAY
          ? `${!disabled ? `text-gray-lighter` : `text-gray`}`
          : color === WHITE
          ? `${!disabled ? `text-white` : `text-gray`}`
          : color === BLACK
          ? `${!disabled ? `text-black hover:text-gray` : `text-gray`}`
          : `${!disabled ? `text-orange` : `text-orange-dark`}`
      }
      ${disabled ? 'cursor-default' : ''}
      ${className ? className : ''}
    `}
      style={style}
    >
      {children}
    </button>
  );
}

export default Button;
