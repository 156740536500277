const BiosIcon = (p: { fill?: string }) => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...p}
    >
      <g clipPath='url(#clip0_158_9292)'>
        <path
          d='M13.174 15.1486L4.5791 22.6881V24.6077L13.221 32.1473H17.1192V30.7365L9.39319 23.7983V23.4514L17.1192 16.675V15.1486H13.174Z'
          fill={p.fill ? p.fill : '#00FF8B'}
        />
        <path
          d='M30.9043 15.1486V16.675L38.6068 23.4514V23.7983L30.9043 30.7365V32.1473H34.8025L43.4444 24.6077V22.6881L34.826 15.1486H30.9043Z'
          fill={p.fill ? p.fill : '#00FF8B'}
        />
        <path
          d='M25.3621 15.1486L18.7163 32.1473H22.7789L29.3308 15.1486H25.3621Z'
          fill={p.fill ? p.fill : '#00FF8B'}
        />
        <path
          d='M24 47.2727C10.7554 47.2727 0 36.6803 0 23.6364C0 10.5924 10.7554 0 24 0C37.2446 0 48 10.5924 48 23.6364C48 36.6803 37.2446 47.2727 24 47.2727ZM24 1.06387C11.3425 1.06387 1.08023 11.1706 1.08023 23.6364C1.08023 36.1021 11.3425 46.2089 24 46.2089C36.6575 46.2089 46.9198 36.1021 46.9198 23.6364C46.8963 11.1706 36.6575 1.087 24 1.06387Z'
          fill={p.fill ? p.fill : '#00FF8B'}
        />
      </g>
      <defs>
        <clipPath id='clip0_158_9292'>
          <rect width='48' height='47.2727' fill={'white'} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BiosIcon;
