const BinaryTextBig = ({ className }: { className?: string }) => {
  return (
    <div
      className={`flex justify-center whitespace-nowrap ${
        className ? className : ''
      }`}
    >
      <div className={`text-4xl text-gray-opaque`}>
        <div>
          01000001 01110011 01110011 01101001 01101101 01101001 01101100
          01100001 01110100 01101001 01101111 01101110 00100000 01101001
          01110011 00100000 01101110 01101111 01110100 00100000
        </div>
        <div>
          01101111 01110000 01110100 01101001 01101111 01101110 01100001
          01101100 01010100 01101000 01100101 00100000 01100011 01101111
          01110101 01101110 01100011 01101001 01101100 00100000
        </div>
        <div>
          01101000 01100001 01110011 00100000 01110011 01110000 01101111
          01101011 <span className='text-green-darkest'>01100101 </span>
          01101110 00001101 00001010 01001010 01000001 01011010 01011010
          <span className='text-green-darkest'> 01000101 </span>01010010
          01000011 01001001
        </div>
        <div>
          01010011 <span className='text-green-darkest'>01000101</span> 00100000
          01010011 01010100 01000001 01010010 01010100 01010011 00100000
          01000001 01010100 00100000 01000101 01001100 01000101 01010110
          01000101 01001110 00001101
        </div>
        <div>
          00001010 00001101 00001010 01000001 01110011 01110011 01101001
          01101101 01101001 01101100 01100001 01110100 01101001 01101111
          01101110 00100000 01101001 01110011 00100000 01101110
        </div>
        <div>
          01101111 01110100 00100000 01101111 01110000 01110100 01101001
          01101111 01101111 01110000 01110100 01101001 01101111 01101110
          <span className='text-green-darkest'> 01100001 </span>01101100
          01010100 01101000 01100101 00100000
        </div>
        <div>
          01101000 01100001 01110011 00100000 01110011 01110000 01101111
          01101011 01100101 01101110 00001101 00001010 01001010 01000001
          01011010 01011010 01000101 01010010 01000011 01001001
        </div>
        <div>
          01010011 01000101 00100000 01010011{' '}
          <span className='text-green-darkest'> 01010100 </span> 01000001
          01010010 01010100 01010011 00100000 01000001 01010100 00100000
          01000101 01001100 01000101 01010110 01000101 01001110 00001101
        </div>
        <div>
          00001010 00001101 00001010 01000001 01110011{' '}
          <span className='text-green-darkest'>01110011</span> 01101001 01101101
          01101001 01101100 01100001 01110100 01101001 01101111 01101110
          <span className='text-green-darkest'> 00100000 </span> 01101001
          01110011 00100000 01101110
        </div>
        <div>
          01101111 01110100 00100000 01101111 01110000 01110100 01101001
          01101111
        </div>
      </div>
    </div>
  );
};

export default BinaryTextBig;
