/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  TokenConfigs,
  TokenConfigsInterface,
} from "../../../contracts/libraries/TokenConfigs";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: true,
        internalType: "enum TokenConfigs.TokenSettingName",
        name: "settingName",
        type: "uint8",
      },
      {
        indexed: true,
        internalType: "bool",
        name: "newValue",
        type: "bool",
      },
    ],
    name: "TokenSettingToggled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: true,
        internalType: "enum TokenConfigs.TokenSettingName",
        name: "settingName",
        type: "uint8",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "newValue",
        type: "uint256",
      },
    ],
    name: "TokenSettingUpdated",
    type: "event",
  },
];

const _bytecode =
  "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea2646970667358221220072a4a71838521de8f8727cd6b1c11fc5c3fd6fc370c27dc568cdc5503d8228a64736f6c63430008040033";

type TokenConfigsConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: TokenConfigsConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class TokenConfigs__factory extends ContractFactory {
  constructor(...args: TokenConfigsConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<TokenConfigs> {
    return super.deploy(overrides || {}) as Promise<TokenConfigs>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): TokenConfigs {
    return super.attach(address) as TokenConfigs;
  }
  override connect(signer: Signer): TokenConfigs__factory {
    return super.connect(signer) as TokenConfigs__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): TokenConfigsInterface {
    return new utils.Interface(_abi) as TokenConfigsInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): TokenConfigs {
    return new Contract(address, _abi, signerOrProvider) as TokenConfigs;
  }
}
