import SkeletonContainer from './SkeletonContainer';
import SkeletonContainerMessage from './SkeletonContainerMessage';

export interface SkeletonsProps {
  name: string;
  gridClassNames: string;
  quantity: number;
  error: boolean;
  height: number;
}

const Skeletons = ({
  name,
  gridClassNames,
  quantity,
  error,
  height = 200,
}: SkeletonsProps) => {
  return (
    <div className={`relative grid ${gridClassNames}`}>
      {error && <SkeletonContainerMessage name={name} error={error} />}
      {[...Array(quantity)].map((_, i) => (
        <SkeletonContainer
          key={`skeleton-${name}-${i}`}
          error={error}
          height={height}
        />
      ))}
    </div>
  );
};

export default Skeletons;
