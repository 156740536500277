/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  ISyntheticMap,
  ISyntheticMapInterface,
} from "../../../contracts/interfaces/ISyntheticMap";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_underlying",
        type: "address",
      },
    ],
    name: "deploySyntheticTokenIfNotExist",
    outputs: [
      {
        internalType: "address",
        name: "deployedSynthetic_",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_synghetic",
        type: "address",
      },
    ],
    name: "syntheticToUnderlying",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_underlying",
        type: "address",
      },
    ],
    name: "underlyingToSynthetic",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class ISyntheticMap__factory {
  static readonly abi = _abi;
  static createInterface(): ISyntheticMapInterface {
    return new utils.Interface(_abi) as ISyntheticMapInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ISyntheticMap {
    return new Contract(address, _abi, signerOrProvider) as ISyntheticMap;
  }
}
