import { useState, useEffect } from 'react';
import { constants } from 'ethers';
import { useWeb3 } from '../web3';
import { SymbolAddressMap, mainnet } from '../assets/tokenAddresses';
import { IERC20MetadataUpgradeable } from '../assets/typechain';
import sushiData from '@sushiswap/sushi-data';
import { useDebouncedCallback } from 'use-debounce';
import { useQuery as useReactQuery } from 'react-query';

interface CoinGeckoResponse {
  [address: string]: {
    usd: number;
  };
}

interface AddressSymbolMap {
  [address: string]: string | undefined;
}

const makeCoinGecko = (id: string) =>
  `https://api.coingecko.com/api/v3/simple/price?ids=${id}&vs_currencies=usd`;

const biosPriceUrl = makeCoinGecko('bios');

const priceUrl: Record<number, string> = {
  // mainnet
  1: makeCoinGecko('ethereum'),
  42: makeCoinGecko('ethereum'),
  31337: makeCoinGecko('ethereum'),

  // polygon
  137: makeCoinGecko('matic-network'),
  31338: makeCoinGecko('matic-network'),

  // avalanche
  43114: makeCoinGecko('avalanche-2'),
  31339: makeCoinGecko('avalanche-2'),

  // fantom
  250: makeCoinGecko('fantom'),
  31340: makeCoinGecko('fantom'),

  // bsc
  56: makeCoinGecko('binancecoin'),
  31341: makeCoinGecko('binancecoin'),

  // metis
  1088: makeCoinGecko('metis-token'),
  31343: makeCoinGecko('metis-token'),
};

// NEW
const useBiosPriceUSD = () => {
  return useReactQuery(
    ['bios', 'price', 'usd'],
    async () => {
      const res = await fetch(biosPriceUrl);
      if (!res.ok) {
        throw new Error('Error fetching BIOS price from CoinGecko!');
      }
      const data: CoinGeckoResponse = await res.json();

      return Object.values(data)[0].usd;
    },
    {
      cacheTime: 300000, // 5 min
    }
  );
};

const useEthPrice = () => {
  const { chainId } = useWeb3();
  const [etherPrice, setEtherPrice] = useState<number>(0);

  const getEtherPrice = useDebouncedCallback(
    (
      url = 'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
    ) => {
      fetch(url)
        .then((etherResponse) => {
          if (!etherResponse.ok) {
            throw new Error(
              'Getting ether price data network response was not ok'
            );
          }
          return etherResponse.json();
        })
        .then((etherData: CoinGeckoResponse) =>
          setEtherPrice(Object.values(etherData)[0].usd)
        )
        .catch(console.error);
    },
    1000,
    { leading: true }
  );

  useEffect(() => {
    if (chainId) {
      getEtherPrice(priceUrl[chainId]);
      const intervalId = setInterval(
        () => getEtherPrice(priceUrl[chainId]),
        60000
      );
      return () => clearInterval(intervalId);
    }
  }, [getEtherPrice, chainId]);

  return etherPrice;
};

export const mainnetBiosAddress = '0xaaca86b876ca011844b5798eca7a67591a9743c8';
export const mainnetSlpAddress = '0xe9a889e6963f122a98f8083d951c71329c726c0a';

// TODO: TEMP HACK, dont use this when coingecko price feed for bios is back to normal
export const getBiosTokenPriceInUsd = async (): Promise<{
  [address: string]: number;
}> => {
  const v = await sushiData.exchange.token({
    token_address: mainnetBiosAddress,
  });
  const eth = await sushiData.exchange.ethPrice();
  const slpPrice = await getLpTokenPrice(mainnetSlpAddress, v.derivedETH, eth);
  return {
    [mainnetBiosAddress]: v.derivedETH * eth,
    [mainnetSlpAddress]: slpPrice,
  };
};

export const getLpTokenPrice = async (
  address: string,
  biosPrice: number,
  ethPrice: number
): Promise<number> => {
  // slp bios eth token address
  const value = await sushiData.exchange.pair({ pair_address: address });

  const price =
    Number(value?.reserve0 || '0') * Number(biosPrice) +
    (Number(value?.reserve1 || '0') * Number(ethPrice || '0')) /
      Number(value?.totalSupply || '1');
  return Number(price);
};

// const getTokenPrices = async (
//   mainnetAddressesSymbols: AddressSymbolMap | undefined,
//   altnetSymbolsAddresses: SymbolAddressMap | undefined
// ) => {
//   if (!mainnetAddressesSymbols) {
//     return;
//   }

//   const mainnetAddresses = Object.keys(mainnetAddressesSymbols);
//   if (mainnetAddresses.length === 0) {
//     return;
//   }

//   fetch(
//     `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${mainnetAddresses.join(
//       ','
//     )}&vs_currencies=usd`
//   )
//     .then((tokensResponse) => {
//       if (!tokensResponse.ok) {
//         throw new Error('Getting token price data network response was not ok');
//       }
//       return tokensResponse.json();
//     })
//     .then(async (tokensData: CoinGeckoResponse) => {
//       if (!altnetSymbolsAddresses) {
//         const symbolPriceMap = mainnetAddresses.reduce((pMap, a) => {
//           if (tokensData[a.toLowerCase()]) {
//             pMap[a.toLowerCase()] = tokensData[a.toLowerCase()].usd;
//           }
//           return pMap;
//         }, {} as { [address: string]: number });
//         const price = await getBiosTokenPriceInUsd();
//         const updatedSymbolPriceMap = { ...symbolPriceMap, ...price };
//         return updatedSymbolPriceMap;
//       } else {
//         const mainnetSymbolsAddresses = Object.fromEntries(
//           Object.entries(mainnetAddressesSymbols).map((a) => a.reverse())
//         );
//         const symbolPriceMap = Object.keys(altnetSymbolsAddresses).reduce(
//           (pMap, s) => {
//             const altnetAddress = altnetSymbolsAddresses[s];
//             const mainnetAddress = mainnetSymbolsAddresses[s];
//             if (!mainnetAddress || !altnetAddress) {
//               return pMap;
//             }
//             if (tokensData[mainnetAddress.toLowerCase()]) {
//               pMap[altnetAddress.toLowerCase()] =
//                 tokensData[mainnetAddress.toLowerCase()].usd;
//             }
//             return pMap;
//           },
//           {} as { [address: string]: number }
//         );
//         const price = await getBiosTokenPriceInUsd();
//         const updatedSymbolPriceMap = { ...symbolPriceMap, ...price };
//         return updatedSymbolPriceMap;
//       }
//     })
//     .catch(console.error);
// };

const useTokenPrices = (
  tokens: string[] | undefined,
  symbols: { [address: string]: string } | undefined
) => {
  const { networkName } = useWeb3();
  const [mainnetAddressesSymbols, setMainnetAddressesSymbols] =
    useState<AddressSymbolMap>();
  const [altnetSymbolsAddresses, setAltnetSymbolsAddresses] =
    useState<SymbolAddressMap>();

  const [tokensPriceData, setTokensPriceData] = useState<{
    [address: string]: number;
  }>({});

  const getTokenPrices = (
    mainnetAddressesSymbols: AddressSymbolMap | undefined,
    altnetSymbolsAddresses: SymbolAddressMap | undefined
  ) => {
    if (!mainnetAddressesSymbols) {
      setTokensPriceData({});
      return;
    }

    const mainnetAddresses = Object.keys(mainnetAddressesSymbols);
    if (mainnetAddresses.length === 0) {
      return;
    }

    fetch(
      `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${mainnetAddresses.join(
        ','
      )}&vs_currencies=usd`
    )
      .then((tokensResponse) => {
        if (!tokensResponse.ok) {
          setTokensPriceData({});
          throw new Error(
            'Getting token price data network response was not ok'
          );
        }
        return tokensResponse.json();
      })
      .then(async (tokensData: CoinGeckoResponse) => {
        if (!altnetSymbolsAddresses) {
          const symbolPriceMap = mainnetAddresses.reduce((pMap, a) => {
            if (tokensData[a.toLowerCase()]) {
              pMap[a.toLowerCase()] = tokensData[a.toLowerCase()].usd;
            }
            return pMap;
          }, {} as { [address: string]: number });
          const price = await getBiosTokenPriceInUsd();
          const updatedSymbolPriceMap = { ...symbolPriceMap, ...price };
          setTokensPriceData(updatedSymbolPriceMap);
        } else {
          const mainnetSymbolsAddresses = Object.fromEntries(
            Object.entries(mainnetAddressesSymbols).map((a) => a.reverse())
          );
          const symbolPriceMap = Object.keys(altnetSymbolsAddresses).reduce(
            (pMap, s) => {
              const altnetAddress = altnetSymbolsAddresses[s];
              const mainnetAddress = mainnetSymbolsAddresses[s];
              if (!mainnetAddress || !altnetAddress) {
                return pMap;
              }
              if (tokensData[mainnetAddress.toLowerCase()]) {
                pMap[altnetAddress.toLowerCase()] =
                  tokensData[mainnetAddress.toLowerCase()].usd;
              }
              return pMap;
            },
            {} as { [address: string]: number }
          );
          const price = await getBiosTokenPriceInUsd();
          const updatedSymbolPriceMap = { ...symbolPriceMap, ...price };
          setTokensPriceData(updatedSymbolPriceMap);
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (!networkName || !tokens) {
      setMainnetAddressesSymbols(undefined);
      setAltnetSymbolsAddresses(undefined);
      return;
    }

    if (!['localhost', 'homestead'].includes(networkName)) {
      setMainnetAddressesSymbols(
        Object.fromEntries(Object.entries(mainnet).map((a) => a.reverse()))
      );
      setAltnetSymbolsAddresses(
        tokens.reduce((p, c) => {
          if (!symbols) return { ...p };
          return { ...p, [symbols[c]]: c };
        }, {} as AddressSymbolMap)
      );
    } else {
      setMainnetAddressesSymbols(
        tokens.reduce((p, c) => {
          if (!symbols) return { ...p };
          return { ...p, [c]: symbols[c] };
        }, {} as AddressSymbolMap)
      );
      setAltnetSymbolsAddresses(undefined);
    }
  }, [networkName, symbols, tokens]);

  useEffect(() => {
    getTokenPrices(mainnetAddressesSymbols, altnetSymbolsAddresses);
    const intervalId = setInterval(
      () => getTokenPrices(mainnetAddressesSymbols, altnetSymbolsAddresses),
      60000
    );
    return () => clearInterval(intervalId);
  }, [mainnetAddressesSymbols, altnetSymbolsAddresses]);

  return tokensPriceData;
};

const useAssetPrices = (
  tokens: string[] | undefined,
  symbols: { [address: string]: string } | undefined
) => {
  const [assetPrices, setAssetPrices] = useState<
    { [address: string]: number } | undefined
  >();

  const tokenPrices = useTokenPrices(tokens, symbols);
  const etherPrice = useEthPrice();

  useEffect(() => {
    setAssetPrices(
      Object.assign({}, tokenPrices, { [constants.AddressZero]: etherPrice })
    );
  }, [etherPrice, tokenPrices]);

  return assetPrices;
};

const useGetEthPrice = (prices: { [address: string]: number } | undefined) => {
  const [ethPrice, setEthPrice] = useState(0);

  useEffect(() => {
    if (!prices) {
      setEthPrice(0);
      return;
    }

    setEthPrice(prices[constants.AddressZero]);
  }, [prices]);

  return ethPrice;
};

const useGetBiosPrice = (
  prices: { [address: string]: number } | undefined,
  biosTokenContract: IERC20MetadataUpgradeable | undefined
) => {
  const [biosPrice, setBiosPrice] = useState(0);

  useEffect(() => {
    if (!biosTokenContract || !prices) {
      setBiosPrice(0);
      return;
    }

    setBiosPrice(prices[biosTokenContract.address.toLowerCase()]);
  }, [prices, biosTokenContract]);

  return biosPrice;
};

const useAssetValue = (balance: number, unitPrice: number | undefined) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (!unitPrice) {
      setValue(0);
      return;
    }

    setValue(balance * unitPrice);
  }, [balance, unitPrice]);

  return value;
};

export {
  useAssetPrices,
  useGetEthPrice,
  useGetBiosPrice,
  useAssetValue,
  useBiosPriceUSD,
};
