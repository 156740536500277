export const Prefix = ({
  prefix,
  children,
  className,
  prefixColor,
  textColor,
  noWrap,
  centerPrefix,
  prefixClassName,
  contentClassName,
  reverse,
}: PrefixProps) => {
  return (
    <span
      className={`flex  ${reverse ? `flex-row md:flex-row-reverse` : ''} ${
        className && className
      }`}
    >
      <span
        className={`mx-2 flex flex-col ${
          centerPrefix ? 'justify-center' : ''
        } text-${prefixColor ? prefixColor : 'green'}
        ${prefixClassName ? prefixClassName : ''}`}
      >
        {prefix}
      </span>
      <span
        className={`w-full flex justify-items-start ${
          noWrap ? 'flex-nowrap' : 'flex-wrap'
        } text-${textColor ? textColor : 'white'}
        ${contentClassName ? contentClassName : ''}
        `}
      >
        {children}
      </span>
    </span>
  );
};

export interface ArrowPrefixProps {
  children: React.ReactNode;
  arrowLeft?: boolean;
  prefixColor?: string;
  textColor?: string;
  noWrap?: boolean;
  className?: string;
  contentClassName?: string;
  prefixClassName?: string;
  centerPrefix?: boolean;
  reverse?: boolean;
}

export const ArrowPrefix = (args: ArrowPrefixProps) => {
  return (
    <Prefix prefix={args.arrowLeft ? '←' : '→'} {...args}>
      {args.children}
    </Prefix>
  );
};

export interface PrefixProps {
  children: React.ReactNode;
  prefix?: string | React.ReactNode | JSX.Element;
  prefixColor?: string;
  prefixClassName?: string;
  textColor?: string;
  noWrap?: boolean;
  className?: string;
  contentClassName?: string;
  centerPrefix?: boolean;
  reverse?: boolean;
}

export const SlashPrefix = (args: PrefixProps) => {
  return (
    <Prefix prefix={'//'} {...args}>
      {args.children}
    </Prefix>
  );
};

export const CrocPrefix = (args: PrefixProps) => {
  return (
    <Prefix prefix={'>'} {...args}>
      {args.children}
    </Prefix>
  );
};

export const DotPrefix = (args: PrefixProps) => {
  const dot = (
    <div
      className={`rounded-full w-2 h-2 bg-${
        args.prefixColor ? args.prefixColor : 'green'
      }`}
      style={{ marginTop: args.centerPrefix ? '' : '.5em' }}
    ></div>
  );
  return (
    <Prefix prefix={dot} {...args}>
      {args.children}
    </Prefix>
  );
};
