import * as React from 'react';
import { useWeb3 } from '../../web3';

export interface NetworkSpecificProps {
  children: JSX.Element;
  ethereum?: boolean;
  polygon?: boolean;
  avalanche?: boolean;
  fantom?: boolean;
  bsc?: boolean;
  arbitrum?: boolean;
  metis?: boolean;
  hide?: boolean;
}

function NetworkSpecific({
  children,
  ethereum = false,
  polygon = false,
  avalanche = false,
  fantom = false,
  bsc = false,
  arbitrum = false,
  metis = false,
  hide = false,
}: NetworkSpecificProps) {
  const { chainId } = useWeb3();

  const match =
    !!chainId &&
    ((ethereum && [1, 31337].includes(chainId)) ||
      (polygon && [137, 31338].includes(chainId)) ||
      (avalanche && [43114, 31339].includes(chainId)) ||
      (fantom && [250, 31340].includes(chainId)) ||
      (bsc && [56, 31341].includes(chainId)) ||
      (arbitrum && [42161, 31342].includes(chainId)) ||
      (metis && [1088, 31343].includes(chainId)));

  const show = !!chainId && ((!hide && match) || (hide && !match));

  return show ? children : <></>;
}

export default NetworkSpecific;
