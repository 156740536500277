import React, { useState } from 'react';
// @ts-ignore
import Banner from 'react-js-banner';
import { tokenAddresses, tokenUnits } from '@0xnodes/shared/addresses';
import { networkMetadata } from '@0xnodes/shared/networks';
import { useMatch, Path, useResolvedPath, Link } from 'react-router-dom';
import Logo from 'assets/icons/Logo';
import AddTokenButton from 'components/ui/header/AddTokenButton';
import ChainButton from 'components/ui/header/ChainButton';
import WalletConnectButton from 'components/ui/header/WalletConenctButton';
import BiosIcon from 'assets/icons/assets/BiosIcon';
import { useWeb3 } from '../../web3';
import { useBiosPriceUSD } from 'data/prices';
import X from 'assets/icons/X';
import Hamburger from 'assets/icons/HamburgerMenuIcon';

// Since routes are regular React components, they
// may be rendered anywhere in the app, including in
// child elements.
//
// This helps when it's time to code-split your app
// into multiple bundles because code-splitting a
// React Router app is the same as code-splitting
// any other React app.

function NavLink({
  children,
  to,
  external = false,
}: {
  children?: JSX.Element | string;
  to: string | Partial<Path>;
  external?: boolean;
}) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li
      className={`my-2 md:my-0 mx-2 ${match ? 'text-green' : 'text-grey-flat'}`}
    >
      {external && typeof to === 'string' ? (
        <a href={to} target='_blank' rel='noreferrer'>
          {children}
        </a>
      ) : (
        <Link to={to}>{children}</Link>
      )}
    </li>
  );
}

const AddBiosToMetamask = () => {
  const { chainId } = useWeb3();
  const { data: biosPrice } = useBiosPriceUSD();
  const address = chainId ? tokenAddresses[chainId]?.BIOS || '' : '';
  const ready = !!(chainId && address && tokenUnits.BIOS);
  return (
    <AddTokenButton
      symbol={'BIOS'}
      address={address}
      decimals={tokenUnits.BIOS}
      price={biosPrice}
      icon={BiosIcon}
      disabled={!ready}
      imgLink={`${window.location.origin}/bios_icon_black.png`}
      tokenChainId={chainId || 0}
      className='justify-end'
    />
  );
};

const Navbar = () => {
  const { chainId } = useWeb3();
  const paused = chainId && networkMetadata[chainId].paused;
  const pages = [
    {
      label: 'Strategies',
      location: '/',
    },
    {
      label: '(_KERNEL[REWARDS])',
      location: '/kernel',
    },
    // {
    //   label: 'Systems',
    //   location: '/systems',
    // },
    {
      label: 'Docs',
      location: 'https://docs.0xnodes.io',
      external: true,
    },
  ];

  const [mobileNavbarOpen, setMobileNavbarOpen] = useState(false);

  return (
    <>
      <div className='w-full fixed md:relative p-6 px-8 bg-black '>
        <div
          className='flex justify-between flex-col md:flex-row gap-8 md:pb-auto z-30 md:z-auto mx-auto'
          style={{ maxWidth: 1440 }}
        >
          <div className='flex flex-col md:flex-row gap-8'>
            <div className='mr-4 flex justify-between md:justify-center flex-row md:flex-col'>
              <Logo />

              <div
                onClick={() => setMobileNavbarOpen(!mobileNavbarOpen)}
                className={`md:hidden cursor-pointer flex flex-col justify-center`}
              >
                {mobileNavbarOpen ? <X /> : <Hamburger />}
              </div>
            </div>
            <ul
              className={`${
                !mobileNavbarOpen ? 'hidden md:flex' : ''
              } flex justify-center items-center flex-col md:flex-row gap-8 z-30 md:z-auto`}
            >
              {pages.map((page) => (
                <NavLink
                  key={page.location}
                  to={page.location}
                  external={page.external}
                >
                  {page.label}
                </NavLink>
              ))}
            </ul>
          </div>
          <div
            className={`${
              !mobileNavbarOpen ? 'hidden md:grid' : ''
            } grid grid-cols-1 md:grid-cols-3 gap-8 z-30 md:z-auto`}
          >
            <AddBiosToMetamask />
            {/* <SlashesStats label='net' value={`.${networkName}`} className='px-3 mr-4'/> */}
            <ChainButton />

            <WalletConnectButton />
          </div>
          {mobileNavbarOpen && (
            <div
              className={'absolute top-0 left-0 w-screen h-screen z-20'}
              onClick={() => setMobileNavbarOpen(false)}
            />
          )}
        </div>
      </div>
      {paused ? (
        <Banner title='METIS network is currently paused for system upgrades.' />
      ) : null}
    </>
  );
};

export default Navbar;
