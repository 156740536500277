import { ReactNode, useState } from 'react';
import Modal from 'components/ui/modal/Modal';

export interface DisclaimerProps {
  children: ReactNode;
  readMoreContent?: ReactNode;
  className?: string;
}

const Disclaimer = ({
  children,
  readMoreContent,
  className,
}: DisclaimerProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <div
      className={`pl-4 border-green border-l-4 ${className ? className : ''}`}
    >
      {children}
      {readMoreContent && (
        <div>
          <div
            onClick={() => setModalOpen(true)}
            className='text-white cursor-pointer w-fit'
          >{`>> Read More`}</div>
          <Modal
            long
            open={modalOpen}
            closeFunction={() => setModalOpen(false)}
          >
            {readMoreContent}
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Disclaimer;
