import { useState, useEffect } from 'react';
import { BigNumber, utils } from 'ethers';
import { useWeb3 } from '../web3';
import {
  IERC20MetadataUpgradeable,
  Kernel,
  StrategyMap,
  UserPositionsV2,
  YieldManager,
} from 'assets/typechain';
import { AssetType } from './tokens';
import { useQuery as useReactQuery } from 'react-query';
import { useUserPositionsContract } from 'hooks/useContract';

// NEW
const useGlobalDepositAmount = (token?: string) => {
  const { chainId } = useWeb3();
  const userPositions = useUserPositionsContract();
  const ready = !!(chainId && token && userPositions);
  return useReactQuery(
    [chainId, 'global', 'tokenDeposits', token],
    () => {
      if (!ready) throw new Error('useGlobalDepositAmount is not ready!');

      return userPositions.totalTokenBalance(token);
    },
    {
      enabled: ready,
    }
  );
};

const getTotalValue = (
  amounts: { [address: string]: BigNumber },
  decimals: { [address: string]: number },
  prices: { [address: string]: number }
) => {
  return Object.keys(amounts).reduce(
    (p, c) =>
      p +
      parseFloat(utils.formatUnits(amounts[c], decimals[c])) * (prices[c] || 0),
    0
  );
};

const getTotalAmount = (
  amounts: { [address: string]: BigNumber },
  decimals: { [address: string]: number }
) => {
  return Object.keys(amounts).reduce(
    (p, c) => p + Number(utils.formatUnits(amounts[c], decimals[c])),
    0
  );
};

const useTokenBalancesSet = (
  allBalances: { [address: string]: BigNumber } | undefined,
  assetType: AssetType,
  assetTypes: { [address: string]: AssetType | undefined } | undefined
) => {
  const [tokenBalancesSet, setTokenBalancesSet] = useState<
    { [address: string]: BigNumber } | undefined
  >();

  useEffect(() => {
    if (!allBalances || !assetTypes) {
      setTokenBalancesSet(undefined);
      return;
    }

    const theSet = Object.keys(allBalances).reduce((p, c) => {
      const tokenAssetType = assetTypes[c];
      if (tokenAssetType === assetType) {
        p[c] = allBalances[c];
      }
      return p;
    }, {} as { [address: string]: BigNumber });

    setTokenBalancesSet(theSet);
  }, [allBalances, assetType, assetTypes]);

  return tokenBalancesSet;
};

const useKernelEthAmount = (kernel: Kernel | undefined) => {
  const { provider } = useWeb3();
  const [kernelEthAmount, setKernelEthAmount] = useState(0);

  useEffect(() => {
    if (!kernel || !provider) {
      setKernelEthAmount(0);
      return;
    }

    provider
      .getBalance(kernel.address)
      .then((balance) =>
        setKernelEthAmount(parseFloat(utils.formatEther(balance)))
      )
      .catch(console.error);
  }, [kernel, provider]);

  return kernelEthAmount;
};

const useKernelBiosAmount = (
  kernel: Kernel | undefined,
  bios: IERC20MetadataUpgradeable | undefined
) => {
  const [kernelBiosAmount, setKernelBiosAmount] = useState(0);

  useEffect(() => {
    if (!kernel || !bios) {
      setKernelBiosAmount(0);
      return;
    }

    Promise.all([bios.balanceOf(kernel.address), bios.decimals()])
      .then(([balance, decimals]) =>
        setKernelBiosAmount(parseFloat(utils.formatUnits(balance, decimals)))
      )
      .catch(console.error);
  }, [bios, kernel]);

  return kernelBiosAmount;
};

const useKernelReservesValue = (
  tokens: string[] | undefined,
  yieldManager: YieldManager | undefined,
  prices: { [address: string]: number } | undefined,
  decimals: { [address: string]: number } | undefined
) => {
  const [kernelReservesValue, setKernelReservesValue] = useState(0);

  useEffect(() => {
    if (!yieldManager || !tokens || !decimals || !prices) {
      setKernelReservesValue(0);
      return;
    }

    Promise.all(
      tokens.map((token) =>
        Promise.all([token, yieldManager.getReserveTokenBalance(token)])
      )
    )
      .then((data) => {
        const balances = data.reduce((p, [address, balance]) => {
          p[address] = balance;
          return p;
        }, {} as { [address: string]: BigNumber });
        setKernelReservesValue(getTotalValue(balances, decimals, prices));
      })
      .catch(console.error);
  }, [tokens, yieldManager, prices, decimals]);

  return kernelReservesValue;
};

const useGlobalTotalDepositedAmounts = (
  addresses: string[] | undefined,
  userPositions: UserPositionsV2 | undefined,
  kernel: Kernel | undefined
) => {
  const [amounts, setAmounts] = useState<
    { [address: string]: BigNumber } | undefined
  >();
  const { account } = useWeb3();

  useEffect(() => {
    if (!userPositions || !addresses || !kernel) {
      setAmounts(undefined);
      return;
    }

    const getData = () => {
      Promise.all(
        addresses.map((address) =>
          Promise.all([address, userPositions.totalTokenBalance(address)])
        )
      )
        .then((data) => {
          const balances = data.reduce((p, [address, balance]) => {
            p[address] = balance;
            return p;
          }, {} as { [address: string]: BigNumber });
          setAmounts(balances);
        })
        .catch(console.error);
    };

    getData();

    // Listeners if user connected
    if (account) {
      const depositFilter = userPositions.filters.Deposit(
        null,
        null,
        null,
        null
      );
      const withdrawFilter = kernel.filters.Withdraw(null, null, null, null);
      userPositions.on(depositFilter, getData);
      kernel.on(withdrawFilter, getData);

      return () => {
        userPositions.removeListener(depositFilter, getData);
        kernel.removeListener(withdrawFilter, getData);
      };
    }
  }, [addresses, userPositions, kernel, account]);

  return amounts;
};

const useGlobalTotalValueLockedValue = (
  kernelEthValue: number,
  kernelBiosValue: number,
  globalTotalBiosDepositedValue: number,
  globalTotalDepositedValue: number
) => {
  const [totalValueLocked, setTotalValueLocked] = useState(0);

  useEffect(() => {
    setTotalValueLocked(
      kernelEthValue +
        kernelBiosValue -
        globalTotalBiosDepositedValue +
        globalTotalDepositedValue
    );
  }, [
    globalTotalBiosDepositedValue,
    globalTotalDepositedValue,
    kernelBiosValue,
    kernelEthValue,
  ]);

  return totalValueLocked;
};

const useUserTotalDepositedAmounts = (
  addresses: string[] | undefined,
  userPositions: UserPositionsV2 | undefined,
  kernel: Kernel | undefined,
  strategyMapContract: StrategyMap | undefined,
  currentBlockNumber: number
) => {
  const { account } = useWeb3();
  const [amounts, setAmounts] = useState<
    { [address: string]: BigNumber } | undefined
  >();

  useEffect(() => {
    if (
      !account ||
      !userPositions ||
      !addresses ||
      !kernel ||
      !strategyMapContract
    ) {
      setAmounts(undefined);
      return;
    }

    const getData = () => {
      Promise.all(
        addresses.map((token) =>
          Promise.all([token, userPositions.userTokenBalance(token, account)])
        )
      )
        .then((data) => {
          const balances = data.reduce((p, [address, balance]) => {
            p[address] = balance;
            return p;
          }, {} as { [address: string]: BigNumber });
          setAmounts(balances);
        })
        .catch(console.error);
    };

    getData();

    // Listeners if user connected
    if (account) {
      const depositFilter = userPositions.filters.Deposit(
        account,
        null,
        null,
        null
      );
      const withdrawFilter = kernel.filters.Withdraw(account, null, null, null);
      userPositions.on(depositFilter, getData);
      kernel.on(withdrawFilter, getData);

      const enterFilter = userPositions.filters.EnterStrategy(null, null, null);
      const exitFilter = userPositions.filters.ExitStrategy(null, null, null);
      userPositions.on(enterFilter, getData);
      userPositions.on(exitFilter, getData);

      return () => {
        userPositions.removeListener(depositFilter, getData);
        kernel.removeListener(withdrawFilter, getData);
        userPositions.removeListener(enterFilter, getData);
        userPositions.removeListener(exitFilter, getData);
      };
    }
  }, [
    account,
    addresses,
    userPositions,
    kernel,
    strategyMapContract,
    currentBlockNumber,
  ]);

  return amounts;
};

const useUserTotalValueLockedValue = (
  totalEthDepositedValue: number,
  totalBiosDepositedValue: number,
  totalStablesDepositedValue: number,
  totalAltsDepositedValue: number
) => {
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    setAmount(
      totalEthDepositedValue +
        totalBiosDepositedValue +
        totalStablesDepositedValue +
        totalAltsDepositedValue
    );
  }, [
    totalAltsDepositedValue,
    totalBiosDepositedValue,
    totalEthDepositedValue,
    totalStablesDepositedValue,
  ]);

  return amount;
};

const useTotalDepositedAmount = (
  amounts: { [address: string]: BigNumber } | undefined,
  decimals: { [address: string]: number } | undefined
) => {
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (!amounts || !decimals) {
      setAmount(0);
      return;
    }

    setAmount(getTotalAmount(amounts, decimals));
  }, [amounts, decimals]);

  return amount;
};

const useTotalDepositedValue = (
  amounts: { [address: string]: BigNumber } | undefined,
  decimals: { [address: string]: number } | undefined,
  prices: { [address: string]: number } | undefined
) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (!amounts || !decimals || !prices) {
      setValue(0);
      return;
    }

    setValue(getTotalValue(amounts, decimals, prices));
  }, [amounts, decimals, prices]);

  return value;
};

const useAmountValues = (
  amounts: { [address: string]: BigNumber } | undefined,
  decimals: { [address: string]: number } | undefined,
  prices: { [address: string]: number } | undefined
) => {
  const [values, setValues] = useState<
    { [address: string]: number } | undefined
  >();

  useEffect(() => {
    if (!amounts || !decimals || !prices) {
      setValues(undefined);
      return;
    }

    setValues(
      Object.keys(amounts).reduce((p, c) => {
        p[c] = Number(utils.formatUnits(amounts[c], decimals[c])) * prices[c];
        return p;
      }, {} as { [address: string]: number })
    );
  }, [amounts, decimals, prices]);

  return values;
};

export {
  useTokenBalancesSet,
  useKernelEthAmount,
  useKernelBiosAmount,
  useKernelReservesValue,
  useGlobalDepositAmount,
  useGlobalTotalDepositedAmounts,
  useGlobalTotalValueLockedValue,
  useUserTotalDepositedAmounts,
  useUserTotalValueLockedValue,
  useTotalDepositedAmount,
  useTotalDepositedValue,
  useAmountValues,
};
