import Button from 'components/ui/button/Button';
import { useNativeSymbol } from 'hooks/tokens/useNativeSymbol';

import { IStrategy, IStrategyAsset } from '@0xnodes/shared/types';

import Card from 'components/ui/card/Card';
import { ArrowPrefix, CrocPrefix } from 'components/ui/prefixes/Prefix';
import { SlashPrefix } from 'components/ui/prefixes/Prefix';
import { TTokenSymbol } from '@0xnodes/shared/types';
import { BigNumber } from 'ethers';
import { assetFormat } from 'components/ui/numberFormats';
import { formatUnits } from 'ethers/lib/utils';
import { networkStyling } from 'data/chainData';
import { switchChainHelper } from 'components/utils/metamaskHelper';
import { useNetworkName } from 'hooks/network/useNetworkName';
import { connect } from 'web3/providers';
import { useAssetDataByChain } from 'hooks/tokens/useAssetData';
import { useWrappedNativeSymbol } from 'hooks/tokens/useWrappedNativeSymbol';
import useAssetIcon from 'hooks/tokens/useAssetIcon';

export interface StrategyCardProps {
  strategy: IStrategy;
  userPosition?: BigNumber;
  availableYield?: BigNumber;
  userConnected?: boolean;
  networkConnected?: boolean;
  onClickHandler: Function;
  index: number;
  tableView?: boolean;
  noBgImg?: boolean;
}

export const StrategyCard = ({
  strategy,
  userPosition,
  userConnected = false,
  networkConnected = false,
  availableYield,
  onClickHandler,
  index,
  tableView,
  noBgImg,
}: StrategyCardProps) => {
  const { chainId, underlying } = strategy;
  const asset = useAssetDataByChain(chainId, underlying);
  const nativeSymbol = useNativeSymbol(chainId);
  const wrappedNativeSymbol = useWrappedNativeSymbol(chainId);
  const icon = useAssetIcon(asset?.symbol);
  const nativeIcon =
    chainId && networkStyling[chainId]?.nativeAssetIcon
      ? networkStyling[chainId].nativeAssetIcon
      : undefined;

  if (!(chainId && asset && nativeSymbol && icon)) {
    return <></>;
  }

  const symbol =
    asset.symbol === wrappedNativeSymbol ? nativeSymbol : asset.symbol;

  return (
    <Card
      footerOnSide={tableView}
      footer={
        <StrategyCardActions
          userPosition={userPosition}
          onClickHandler={onClickHandler}
          tableView={tableView}
          symbol={symbol}
          icon={icon}
          userConnected={userConnected}
          networkConnected={networkConnected}
          chainId={chainId}
          decimals={asset.decimals}
        />
      }
    >
      <StrategyCardBody
        noBgImg={noBgImg}
        tableView={tableView}
        chainId={strategy.chainId}
      >
        <StrategyCardHeader
          index={index}
          title={strategy.name}
          description={strategy.description}
        />
        <StrategyAPY apy={strategy.apy} label='APR' />
        <StrategyTokenContent
          tokens={strategy.farms?.map((t) => t.symbol)}
          accepts={[symbol]}
          returns={[nativeSymbol]}
        />
        <div className='flex flex-col justify-center'>
          <StrategyCardStat
            label='Strategy.TVL'
            value={
              strategy.globalDeposits
                ? BigNumber.from(strategy.globalDeposits)
                : undefined
            }
            symbol={symbol}
            icon={icon}
            decimals={asset.decimals}
          />
          <StrategyCardStat
            label='Available yield'
            value={
              userConnected && networkConnected ? availableYield : undefined
            }
            symbol={nativeSymbol}
            icon={nativeIcon}
          />
        </div>
      </StrategyCardBody>
    </Card>
  );
};

const StrategyCardHeader = ({
  title,
  description,
  index,
}: {
  title: string;
  description: string;
  index: number;
}) => {
  return (
    <div className='p-0 flex flex-col justify-center'>
      <h2 className='md:text-5xl xl:text-5xl text-lg mb-2 text-white'>
        <SlashPrefix>{title}</SlashPrefix>
      </h2>
      <h6 className='text-grey-flat'>
        <CrocPrefix>{description}</CrocPrefix>
      </h6>
    </div>
  );
};
StrategyCard.Header = StrategyCardHeader;

const StrategyToken = ({ token }: { token: TTokenSymbol }) => (
  <span className='mx-1'>${token}</span>
);

const StrategyAPY = ({
  apy,
  label: inputLabel,
}: {
  apy: string | number | undefined;
  label?: string;
}) => {
  const label = inputLabel ?? 'APY';

  return (
    <div className='flex flex-col justify-center md:text-left'>
      <SlashPrefix>{label}</SlashPrefix>
      <h4 className={`text-5xl text-${apy ? 'green' : 'grey-flat'}`}>
        {apy && parseInt(apy.toString()) < 5 ? (
          <>
            <span>{'PROCESSING'}</span>
          </>
        ) : (
          <>
            <span>{apy ? assetFormat(apy, 2) : '---'}</span>
            <span className='text-green mx-1'>{apy ? '%' : ''}</span>
          </>
        )}
      </h4>
    </div>
  );
};
StrategyCard.APY = StrategyAPY;

const StrategyCardBody = ({
  children,
  chainId,
  tableView,
  noBgImg,
}: {
  children: React.ReactElement | React.ReactElement[];
  chainId?: number;
  tableView?: boolean;
  noBgImg?: boolean;
}) => {
  // TODO: img source is sloppy. look for cleaner implementation
  const bgImg =
    chainId && networkStyling[chainId]?.bgImage ? (
      <img src={networkStyling[chainId].bgImage} alt='bg' />
    ) : (
      <img src={networkStyling[1].bgImage} alt='bg' />
    );
  return (
    <div
      className={`relative w-full align-stretch grid gap-8 ${
        tableView ? 'lg:flex justify-between' : ' grid-cols-1'
      } `}
    >
      {!noBgImg && bgImg && (
        <div
          className={'h-40 t-auto sm:h-52 md:h-80'}
          style={{
            position: 'absolute',
            right: tableView ? 0 : -45,
            top: tableView ? 10 : 100,
            zIndex: 0,
            maxWidth: 300,
          }}
        >
          {bgImg}
        </div>
      )}
      {children}
    </div>
  );
};
StrategyCard.StrategyCardBody = StrategyCardBody;

const StrategyTokenContent = ({
  tokens,
  accepts,
  returns,
  children,
}: {
  tokens: TTokenSymbol[];
  accepts: JSX.Element[] | TTokenSymbol[] | IStrategyAsset[] | string[];
  returns: JSX.Element[] | TTokenSymbol[] | IStrategyAsset[] | string[];
  children?: React.ReactNode;
}) => {
  return (
    <div className='flex flex-col justify-center'>
      <SlashPrefix>
        <div className='text-gray-light mr-2'>Farms</div>
        {tokens?.map((toke, i) => (
          <StrategyToken key={i} token={toke} />
        ))}
      </SlashPrefix>
      <ArrowPrefix>
        <div className='text-gray-light mr-2'>Accepts</div>
        {accepts?.map((t, i) => (
          <div key={i} className='mr-1'>
            {t}
          </div>
        ))}
      </ArrowPrefix>
      <ArrowPrefix>
        <div className='text-gray-light mr-2'>Returns</div>
        {returns?.map((t, i) => (
          <div key={i} className='mr-1'>
            {t}
          </div>
        ))}
      </ArrowPrefix>
      {children}
    </div>
  );
};
StrategyCard.TokenContent = StrategyTokenContent;

const StrategyTvlContent = ({
  tvl,
  pendingYield,
  availableYield,
  symbol,
  icon,
  children,
  decimals,
}: {
  tvl?: BigNumber;
  pendingYield: BigNumber;
  availableYield?: BigNumber;
  symbol: TTokenSymbol;
  icon: string;
  children?: React.ReactNode;
  decimals?: number;
}) => {
  return (
    <div className='flex flex-col justify-center'>
      <StrategyCardStat
        label='Strategy.TVL'
        value={tvl}
        symbol={symbol}
        icon={icon}
        decimals={decimals}
      />
      <StrategyCardStat
        label='Available yield'
        value={availableYield}
        symbol={symbol}
        icon={icon}
        decimals={decimals}
      />
      {children}
    </div>
  );
};
StrategyCard.TvlContent = StrategyTvlContent;

export const StrategyCardStat = ({
  label,
  value,
  symbol,
  icon,
  decimals = 18,
}: {
  label: string;
  value?: BigNumber;
  symbol?: string;
  icon: any | undefined;
  decimals?: number;
}) => {
  return (
    <div className='my-2 mr-4'>
      <div className='text-white'>{label}</div>
      <div>
        <span className='text-green'>
          <img
            // src={
            //   typeof ethsvg === 'object'
            //     ? (ethsvg as { src?: string }).src
            //     : ethsvg
            // }
            src={icon}
            alt={`${symbol}_icon`}
            style={{ margin: '0px 8px', height: 20, display: 'inline-block' }}
          />
          {value ? assetFormat(formatUnits(value, decimals)) : '---'}
        </span>
        <span className='mx-1 text-grey text-sm'>{symbol}</span>
      </div>
    </div>
  );
};
StrategyCard.Stat = StrategyCardStat;

export const StrategyCardActions = ({
  userPosition,
  userConnected = false,
  networkConnected = false,
  chainId, // chainId of the strat
  onClickHandler,
  tableView,
  symbol,
  icon,
  decimals,
}: {
  userPosition?: BigNumber;
  userConnected?: boolean;
  networkConnected?: boolean;
  chainId: number;
  onClickHandler: Function;
  tableView?: boolean;
  symbol: TTokenSymbol;
  icon: string;
  decimals: number;
}) => {
  const networkName = useNetworkName(chainId);
  const showConnectWallet = !userConnected;
  const showSwitchNetwork = !showConnectWallet && !networkConnected;
  const showManage = !showConnectWallet && !showSwitchNetwork;
  return (
    <div
      className={`flex-wrap flex h-full justify-between ${
        tableView && 'lg:flex-col lg:justify-center'
      }`}
    >
      {showManage ? (
        <>
          <StrategyCardStat
            label='Your Position'
            value={userPosition}
            symbol={symbol}
            icon={icon}
            decimals={decimals}
          />
          <Button className='my-2' onClick={() => onClickHandler()}>
            manage
          </Button>
        </>
      ) : null}
      {showSwitchNetwork ? (
        <>
          <Button
            className='my-2 mx-auto'
            onClick={() => switchChainHelper(chainId)}
          >
            Switch to {networkName} network
          </Button>
        </>
      ) : null}
      {showConnectWallet ? (
        <Button
          className='my-2 mx-auto'
          onClick={() => !userConnected && connect()}
        >
          connect your wallet
        </Button>
      ) : null}
    </div>
  );
};
StrategyCard.Actions = StrategyCardActions;

export default StrategyCard;
