/**
 * Token contract addresses on Mainnet (used for retrieving pricing data)
 */

export interface SymbolAddressMap {
  [symbol: string]: string | undefined;
}

const mainnet: SymbolAddressMap = {
  WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  BIOS: '0xaaca86b876ca011844b5798eca7a67591a9743c8',

  BUSD: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  DAI: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  USDC: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  FRAX: '0x853d955acef822db058eb8505911ed77f175b99e',

  XIOT: '0x31024a4c3e9aeeb256b825790f5cb7ac645e7cd5',
  bXIOT: '0x5c4ac68aAc56eBe098D621Cd8CE9F43270Aaa355',
  dXIOT: '0x8b6DD24Bcb2D0aeA92c3AbD4eB11103A5DB6D714',
  RI: '0x37E808F084101F75783612407e7C3f5F92d8ee3F',
  SLP: '0xE9a889E6963f122a98f8083d951c71329c726c0A',

  TOKE: '0x2e9d63788249371f1DFC918a52f8d799F4a38C94',
  OHM: '0x383518188c0c6d7730d91b2c03a03c837814a899',
  SUSHI: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
};

export { mainnet };
