import { nativeCurrencies } from '@0xnodes/shared/addresses';
import { isAddressZero, isSameAddress } from '@0xnodes/shared/utils';
import { networkStyling } from 'data/chainData';
import assetIcons from 'data/tokenIcons';
import { useWeb3 } from '../../web3';

export const useAssetIcon = (token: string | undefined) => {
  const { chainId } = useWeb3();
  const nativeCurrency = chainId ? nativeCurrencies[chainId] : undefined;
  if (!token || !chainId) return undefined;
  const icon =
    nativeCurrency &&
    chainId &&
    (isAddressZero(token) ||
      isSameAddress(token, nativeCurrency.wrappedAddress))
      ? networkStyling[chainId]?.nativeAssetIcon
      : assetIcons[token] || assetIcons.ETH;
  return icon;
};

export default useAssetIcon;
