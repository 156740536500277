import ShortAddress from 'components/utils/ShortAddress';
import { useRef } from 'react';
import { connect } from '../../../web3/providers';
import { useWeb3 } from '../../../web3';
import Button from '../button/Button';

const WalletConnectButton = ({ long }: { long?: boolean }) => {
  const { account } = useWeb3();
  const buttonText = useRef<string>('.connect');

  if (long) {
    buttonText.current = '.connect_wallet';
  }

  return (
    <Button
      onClick={() => {
        !account && connect();
      }}
      className={''}
    >
      {!account ? buttonText.current : <ShortAddress address={account} />}
    </Button>
  );
};

export default WalletConnectButton;
