import { useState, useEffect } from 'react';
import { BigNumber, constants, ethers } from 'ethers';
import { useWeb3 } from '../web3';
import {
  Kernel,
  IntegrationMap,
  IERC20MetadataUpgradeable__factory,
  IERC20MetadataUpgradeable,
  IWeth9,
  UserPositionsV2,
} from '../assets/typechain';
import { biosRewardWeightSum } from './weights';
import { TypedEvent, TypedEventFilter } from '../assets/typechain/common';
import { useNativeSymbol } from 'hooks/tokens/useNativeSymbol';
import { useQuery as useReactQuery } from 'react-query';

export enum AssetType {
  Unknown,
  ETH,
  BIOS,
  Stable,
  Alt,
}

const nativeEth = {
  name: 'Ether',
  symbol: 'ETH',
};
const nativeMatic = {
  name: 'Matic',
  symbol: 'MATIC',
};
const nativeAvax = {
  name: 'Avax',
  symbol: 'AVAX',
};
const nativeFtm = {
  name: 'Ftm',
  symbol: 'FTM',
};
const nativeBsc = {
  name: 'Bnb',
  symbol: 'BNB',
};
const nativeMetis = {
  name: 'Metis',
  symbol: 'METIS',
};

const nativeAsset: Record<string, { name: string; symbol: string }> = {
  '1': nativeEth,
  '42': nativeEth,
  '31337': nativeEth,
  '137': nativeMatic,
  '31338': nativeMatic,
  '43114': nativeAvax,
  '31339': nativeAvax,
  '250': nativeFtm,
  '31340': nativeFtm,
  '56': nativeBsc,
  '31341': nativeBsc,
  '1088': nativeMetis,
  '31343': nativeMetis,
};

const useSupportedAssetSymbols = (
  contracts: { [address: string]: IERC20MetadataUpgradeable } | undefined
) => {
  const [symbols, setSymbols] = useState<
    { [address: string]: string } | undefined
  >();
  const nativeSymbol = useNativeSymbol() || '';

  useEffect(() => {
    if (!contracts) {
      setSymbols(undefined);
      return;
    }

    const addresses = Object.keys(contracts);

    Promise.all(
      addresses.map((address) =>
        Promise.all([address, contracts[address].symbol()])
      )
    )
      .then((data) => {
        const newSymbols = data.reduce((p, [address, symbol]) => {
          p[address] = symbol;
          return p;
        }, {} as { [address: string]: string });
        const newPlusEth = Object.assign(newSymbols, {
          [constants.AddressZero]: nativeSymbol,
        });
        setSymbols(newPlusEth);
      })
      .catch(console.error);
  }, [contracts, nativeSymbol]);

  return symbols;
};

const useSupportedAssetNames = (
  contracts: { [address: string]: IERC20MetadataUpgradeable } | undefined
) => {
  const [names, setNames] = useState<
    { [address: string]: string } | undefined
  >();
  const { chainId } = useWeb3();
  const nativeName = chainId ? nativeAsset?.[`${chainId}`]?.name : '';

  useEffect(() => {
    if (!contracts) {
      setNames(undefined);
      return;
    }

    const addresses = Object.keys(contracts);

    Promise.all(
      addresses.map((address) =>
        Promise.all([address, contracts[address].name()])
      )
    )
      .then((data) => {
        const newNames = data.reduce((p, [address, name]) => {
          p[address] = name;
          return p;
        }, {} as { [address: string]: string });
        const newPlusEth = Object.assign({}, newNames, {
          [constants.AddressZero]: nativeName,
        });
        setNames(newPlusEth);
      })
      .catch(console.error);
  }, [contracts, nativeName]);

  return names;
};

const useSupportedAssetDecimals = (
  contracts: { [address: string]: IERC20MetadataUpgradeable } | undefined
) => {
  const [decimals, setDecimals] = useState<
    { [address: string]: number } | undefined
  >();

  useEffect(() => {
    if (!contracts) {
      setDecimals(undefined);
      return;
    }

    const addresses = Object.keys(contracts);

    Promise.all(
      addresses.map((address) =>
        Promise.all([address, contracts[address].decimals()])
      )
    )
      .then((data) => {
        const newDecimals = data.reduce((p, [address, decimal]) => {
          p[address] = decimal;
          return p;
        }, {} as { [address: string]: number });
        const newPlusEth = Object.assign({}, newDecimals, {
          [constants.AddressZero]: 18,
        });
        setDecimals(newPlusEth);
      })
      .catch(console.error);
  }, [contracts]);

  return decimals;
};

const useSupportedAssetTypes = (
  addresses: string[] | undefined,
  supportedTokenBiosRewardWeights: { [address: string]: BigNumber } | undefined,
  wethContract: IWeth9 | undefined,
  biosContract: IERC20MetadataUpgradeable | undefined
) => {
  const [types, setTypes] = useState<
    { [address: string]: AssetType } | undefined
  >();

  useEffect(() => {
    if (
      !addresses ||
      !wethContract ||
      !biosContract ||
      !supportedTokenBiosRewardWeights
    ) {
      setTypes(undefined);
      return;
    }

    Promise.all(
      addresses.map((address) => ({
        address,
        type: getAssetType(
          address,
          supportedTokenBiosRewardWeights[address],
          wethContract,
          biosContract
        ),
      }))
    )
      .then((data) => {
        const newTypes = data.reduce((p, { address, type }) => {
          p[address] = type;
          return p;
        }, {} as { [address: string]: AssetType });
        const newPlusEth = Object.assign({}, newTypes, {
          [constants.AddressZero]: AssetType.ETH,
        });
        setTypes(newPlusEth);
      })
      .catch(console.error);
  }, [addresses, biosContract, supportedTokenBiosRewardWeights, wethContract]);

  return types;
};

const useSupportedTokenIntegrationWeightSums = (
  addresses: string[] | undefined,
  integrationMap: IntegrationMap | undefined
) => {
  const [integrationWeightSums, setIntegrationWeightSums] = useState<
    { [address: string]: BigNumber } | undefined
  >();

  useEffect(() => {
    if (!addresses || !integrationMap) {
      setIntegrationWeightSums(undefined);
      return;
    }

    // console.error(
    //   'no action taken',
    //   'integrationMap.getTokenIntegrationWeightSum does not exist'
    // );
    /*
    Promise.all(addresses.map(address => Promise.all([address, integrationMap.getTokenIntegrationWeightSum(address)])))
      .then(data => {
        const newSums = data.reduce((p, [address, sum]) => {
          p[address] = sum;
          return p;
        }, {} as { [address: string]: BigNumber });
        setIntegrationWeightSums(newSums);
      })
      .catch(console.error);
      */
  }, [addresses, integrationMap]);

  return integrationWeightSums;
};

const useSupportedTokenContracts = (addresses: string[] | undefined) => {
  const [contracts, setContracts] = useState<
    { [address: string]: IERC20MetadataUpgradeable } | undefined
  >();
  const { signerOrProvider } = useWeb3();

  useEffect(() => {
    if (!addresses || !signerOrProvider) {
      setContracts(undefined);
      return;
    }

    setContracts(
      addresses.reduce((p, c) => {
        p[c] = IERC20MetadataUpgradeable__factory.connect(c, signerOrProvider);
        return p;
      }, {} as { [address: string]: IERC20MetadataUpgradeable })
    );
  }, [addresses, signerOrProvider]);

  return contracts;
};

const useTotalBiosRewardWeight = (
  supportedTokens: string[] | undefined,
  biosRewardRates: { [address: string]: BigNumber | undefined } | undefined
) => {
  const [sum, setSum] = useState(BigNumber.from(0));

  useEffect(() => {
    if (!supportedTokens || !biosRewardRates) {
      setSum(BigNumber.from(0));
      return;
    }

    const sum = biosRewardWeightSum(biosRewardRates);
    setSum(sum);
  }, [supportedTokens, biosRewardRates]);

  return sum;
};

const useReserveRatioDenominator = (
  integrationMap: IntegrationMap | undefined
) => {
  const [reserveRatioDenominator, setReserveRatioDenominator] = useState(0);

  useEffect(() => {
    if (!integrationMap) {
      setReserveRatioDenominator(0);
      return;
    }

    integrationMap
      .getReserveRatioDenominator()
      .then(setReserveRatioDenominator)
      .catch(console.error);
  }, [integrationMap]);

  return reserveRatioDenominator;
};

const useSupportedTokensLength = (
  integrationMap: IntegrationMap | undefined,
  kernel: Kernel | undefined
) => {
  const { account } = useWeb3();
  const [tokensLength, setTokensLength] = useState(0);

  useEffect(() => {
    if (!integrationMap || !kernel) {
      setTokensLength(0);
      return;
    }

    integrationMap
      .getTokenAddressesLength()
      .then((length) => setTokensLength(length.toNumber()))
      .catch(console.error);

    // Listeners if user connected
    if (account) {
      const addNewToken = () => {
        setTokensLength((length) => length + 1);
      };

      const newTokenFilter = kernel.filters.TokenAdded(
        null,
        null,
        null,
        null,
        null
      );
      kernel.on(newTokenFilter, addNewToken);

      return () => {
        kernel.removeListener(newTokenFilter, addNewToken);
      };
    }
  }, [integrationMap, kernel, account]);

  return tokensLength;
};

const useSupportedTokensAddresses = (
  integrationMap: IntegrationMap | undefined,
  supportedTokensLength: number
) => {
  const [tokensAddresses, setTokensAddresses] = useState<string[]>();

  useEffect(() => {
    if (!integrationMap || supportedTokensLength === 0) {
      setTokensAddresses(undefined);
      return;
    }

    Promise.all(
      [...new Array(supportedTokensLength).keys()].map((index) =>
        integrationMap.getTokenAddress(index)
      )
    )
      .then((tokenAddresses) =>
        setTokensAddresses(
          tokenAddresses.map((address) => address.toLowerCase())
        )
      )
      .catch(console.error);
  }, [integrationMap, supportedTokensLength]);

  return tokensAddresses;
};

const useAcceptingDeposits = (
  tokens: string[] | undefined,
  integrationMap: IntegrationMap | undefined,
  wethContract: IWeth9 | undefined
) => {
  const { account } = useWeb3();
  const [acceptingDeposits, setAcceptingDeposits] = useState<
    { [address: string]: boolean } | undefined
  >();

  useEffect(() => {
    if (!integrationMap || !tokens || !wethContract) {
      setAcceptingDeposits(undefined);
      return;
    }

    Promise.all(
      tokens.map((token) =>
        Promise.all([token, integrationMap.getTokenAcceptingDeposits(token)])
      )
    )
      .then((data) => {
        const newAcceptingDeposits = data.reduce(
          (p, [address, acceptingDeposits]) => {
            p[address] = acceptingDeposits;
            if (address === wethContract.address.toLowerCase()) {
              p[constants.AddressZero] = acceptingDeposits;
            }
            return p;
          },
          {} as { [address: string]: boolean }
        );
        setAcceptingDeposits(newAcceptingDeposits);
      })
      .catch(console.error);
  }, [integrationMap, tokens, wethContract, account]);

  return acceptingDeposits;
};

const useAcceptingWithdrawals = (
  tokens: string[] | undefined,
  integrationMap: IntegrationMap | undefined,
  wethContract: IWeth9 | undefined
) => {
  const { account } = useWeb3();
  const [acceptingWithdrawals, setAcceptingWithdrawals] = useState<
    { [address: string]: boolean } | undefined
  >();

  useEffect(() => {
    if (!integrationMap || !tokens || !wethContract) {
      setAcceptingWithdrawals(undefined);
      return;
    }

    Promise.all(
      tokens.map((token) =>
        Promise.all([token, integrationMap.getTokenAcceptingWithdrawals(token)])
      )
    )
      .then((data) => {
        const newAcceptingWithdrawals = data.reduce(
          (p, [address, acceptingWithdrawals]) => {
            p[address] = acceptingWithdrawals;
            if (address === wethContract.address.toLowerCase()) {
              p[constants.AddressZero] = acceptingWithdrawals;
            }
            return p;
          },
          {} as { [address: string]: boolean }
        );
        setAcceptingWithdrawals(newAcceptingWithdrawals);
      })
      .catch(console.error);
  }, [integrationMap, tokens, wethContract, account]);

  return acceptingWithdrawals;
};

const useBiosRewardWeights = (
  tokens: string[] | undefined,
  integrationMap: IntegrationMap | undefined
) => {
  const { account } = useWeb3();
  const [biosRewardWeights, setBiosRewardWeights] = useState<
    { [address: string]: BigNumber } | undefined
  >();

  useEffect(() => {
    if (!integrationMap || !tokens) {
      setBiosRewardWeights(undefined);
      return;
    }

    Promise.all(
      tokens.map((token) =>
        Promise.all([token, integrationMap.getTokenBiosRewardWeight(token)])
      )
    )
      .then((data) => {
        const newBiosRewardWeights = data.reduce(
          (p, [address, biosRewardWeight]) => {
            p[address] = biosRewardWeight;
            return p;
          },
          {} as { [address: string]: BigNumber }
        );
        setBiosRewardWeights(newBiosRewardWeights);
      })
      .catch(console.error);
  }, [integrationMap, tokens, account]);

  return biosRewardWeights;
};

const useReserveRatioNumerators = (
  tokens: string[] | undefined,
  integrationMap: IntegrationMap | undefined
) => {
  const { account } = useWeb3();
  const [reserveRatioNumerators, setReserveRatioNumerators] = useState<
    { [address: string]: BigNumber } | undefined
  >();

  useEffect(() => {
    if (!tokens || !integrationMap) {
      setReserveRatioNumerators(undefined);
      return;
    }

    Promise.all(
      tokens.map((token) =>
        Promise.all([
          token,
          integrationMap.getTokenReserveRatioNumerator(token),
        ])
      )
    )
      .then((data) => {
        const newReserveRatioNumerators = data.reduce(
          (p, [address, reserveRatioNumerator]) => {
            p[address] = reserveRatioNumerator;
            return p;
          },
          {} as { [address: string]: BigNumber }
        );
        setReserveRatioNumerators(newReserveRatioNumerators);
      })
      .catch(console.error);
  }, [integrationMap, tokens, account]);

  return reserveRatioNumerators;
};

const useUserAllowances = (
  userPositions: UserPositionsV2 | undefined,
  tokenContracts: { [address: string]: IERC20MetadataUpgradeable } | undefined,
  wethContract: IWeth9 | undefined
) => {
  const { account } = useWeb3();
  const [allowances, setAllowances] = useState<
    { [address: string]: BigNumber } | undefined
  >();

  useEffect(() => {
    if (!userPositions || !account || !wethContract || !tokenContracts) {
      setAllowances(undefined);
      return;
    }

    const updateData = (address: string, allowance: BigNumber) => {
      setAllowances((allowances) => {
        const allAllowances = Object.assign({}, allowances);
        allAllowances[address.toLowerCase()] = allowance;
        return allAllowances;
      });
    };

    const addresses = Object.keys(tokenContracts);

    Promise.all(
      addresses.map((address) =>
        Promise.all([
          address,
          tokenContracts[address].allowance(account, userPositions.address),
        ])
      )
    )
      .then((data) => {
        const newAllowances = data.reduce((p, [address, allowance]) => {
          p[address] = allowance;
          return p;
        }, {} as { [address: string]: BigNumber });
        const newPlusEth = Object.assign({}, newAllowances, {
          [constants.AddressZero]: constants.MaxUint256,
        });
        setAllowances(newPlusEth);
      })
      .catch(console.error);

    // account was already checked
    interface FilterListener {
      contract: IERC20MetadataUpgradeable;
      filter: TypedEventFilter<
        TypedEvent<
          [string, string, BigNumber],
          { owner: string; spender: string; value: BigNumber }
        >
      >;
      callback: (_: string, __: string, value: BigNumber, ___: any) => void;
    }
    const filterListeners: FilterListener[] = [];

    const setAllowanceFilterCallback =
      (address: string) =>
      (_: string, __: string, value: BigNumber, ___: any) =>
        updateData(address, value);

    addresses.forEach((address) => {
      const contract = tokenContracts[address];
      if (!contract) {
        return;
      }

      const kernelAllowanceFilter = contract.filters.Approval(
        account,
        userPositions.address,
        null
      );
      const kernelAllowanceListener = setAllowanceFilterCallback(address);
      contract.on(kernelAllowanceFilter, kernelAllowanceListener);
      filterListeners.push({
        contract: contract,
        filter: kernelAllowanceFilter,
        callback: kernelAllowanceListener,
      });
    });

    return () => {
      filterListeners.forEach((listener) => {
        listener.contract.removeListener(listener.filter, listener.callback);
      });
    };
  }, [account, userPositions, tokenContracts, wethContract]);

  return allowances;
};

const getAssetType = (
  address: string,
  supportedTokenBiosRewardWeights: BigNumber | undefined,
  wethContract: IWeth9 | undefined,
  biosContract: IERC20MetadataUpgradeable | undefined
) => {
  if (!wethContract || !biosContract || !supportedTokenBiosRewardWeights) {
    return AssetType.Unknown;
  }

  if (address.toLowerCase() === wethContract.address.toLowerCase()) {
    return AssetType.ETH;
  }

  if (address.toLowerCase() === biosContract.address.toLowerCase()) {
    return AssetType.BIOS;
  }

  if (supportedTokenBiosRewardWeights.gt(0)) {
    return AssetType.Alt;
  }

  return AssetType.Stable;
};

// NEW
const useUserNativeBalance = () => {
  const { account, chainId, provider } = useWeb3();
  const ready = !!(account && chainId && provider);

  return useReactQuery(
    [chainId, account, 'walletBalance', ethers.constants.AddressZero],
    () => {
      if (!ready) throw new Error('useUserNativeBalance is not ready!');
      return provider.getBalance(account);
    },
    {
      enabled: ready,
    }
  );
};

const useUserEtherBalance = (currentBlockNumber: number) => {
  const { account, provider } = useWeb3();
  const [etherBalance, setEtherBalance] = useState(BigNumber.from(0));

  useEffect(() => {
    if (!account || !provider) {
      setEtherBalance(BigNumber.from(0));
      return;
    }

    provider.getBalance(account).then(setEtherBalance).catch(console.error);
  }, [account, provider, currentBlockNumber]);

  return etherBalance;
};

const useSupportedAssets = (tokens: string[] | undefined) => {
  const [allAssets, setAllAssets] = useState<string[] | undefined>();
  const { chainId } = useWeb3();

  useEffect(() => {
    if (!tokens || !chainId) {
      setAllAssets(undefined);
      return;
    }

    // metis we don't want to use native, only wrapped
    setAllAssets(
      [1088, 31342].includes(chainId)
        ? tokens
        : [constants.AddressZero, ...tokens]
    );
  }, [tokens, chainId]);

  return allAssets;
};

export {
  useSupportedTokenContracts,
  useTotalBiosRewardWeight,
  useReserveRatioDenominator,
  useSupportedTokensLength,
  useSupportedTokensAddresses,
  useUserEtherBalance,
  useUserNativeBalance,
  useSupportedAssets,
  useAcceptingDeposits,
  useAcceptingWithdrawals,
  useBiosRewardWeights,
  useReserveRatioNumerators,
  useUserAllowances,
  useSupportedAssetSymbols,
  useSupportedAssetNames,
  useSupportedAssetDecimals,
  useSupportedTokenIntegrationWeightSums,
  useSupportedAssetTypes,
};
