/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  Unwrapper,
  UnwrapperInterface,
} from "../../../contracts/core/Unwrapper";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "wrappedTokenAddress",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "token",
    outputs: [
      {
        internalType: "contract IWeth9",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "unwrap",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b506040516102bc3803806102bc83398101604081905261002f9161009c565b6001600160a01b0381166100775760405162461bcd60e51b815260206004820152600b60248201526a626164206164647265737360a81b604482015260640160405180910390fd5b600080546001600160a01b0319166001600160a01b03929092169190911790556100ca565b6000602082840312156100ad578081fd5b81516001600160a01b03811681146100c3578182fd5b9392505050565b6101e3806100d96000396000f3fe60806040526004361061002d5760003560e01c8063de0e9a3e14610039578063fc0c546a1461005b57600080fd5b3661003457005b600080fd5b34801561004557600080fd5b50610059610054366004610195565b610097565b005b34801561006757600080fd5b5060005461007b906001600160a01b031681565b6040516001600160a01b03909116815260200160405180910390f35b600054604051632e1a7d4d60e01b8152600481018390526001600160a01b0390911690632e1a7d4d90602401600060405180830381600087803b1580156100dd57600080fd5b505af11580156100f1573d6000803e3d6000fd5b50506040516000925033915083908381818185875af1925050503d8060008114610137576040519150601f19603f3d011682016040523d82523d6000602084013e61013c565b606091505b50509050806101915760405162461bcd60e51b815260206004820152601e60248201527f4661696c656420746f2073656e6420756e7772617070656420746f6b656e0000604482015260640160405180910390fd5b5050565b6000602082840312156101a6578081fd5b503591905056fea2646970667358221220c65418a3c8c54a19bc09d2b232e3ebeb22b460341e6220801ddff8672366953164736f6c63430008040033";

type UnwrapperConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: UnwrapperConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class Unwrapper__factory extends ContractFactory {
  constructor(...args: UnwrapperConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    wrappedTokenAddress: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<Unwrapper> {
    return super.deploy(
      wrappedTokenAddress,
      overrides || {}
    ) as Promise<Unwrapper>;
  }
  override getDeployTransaction(
    wrappedTokenAddress: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(wrappedTokenAddress, overrides || {});
  }
  override attach(address: string): Unwrapper {
    return super.attach(address) as Unwrapper;
  }
  override connect(signer: Signer): Unwrapper__factory {
    return super.connect(signer) as Unwrapper__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): UnwrapperInterface {
    return new utils.Interface(_abi) as UnwrapperInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): Unwrapper {
    return new Contract(address, _abi, signerOrProvider) as Unwrapper;
  }
}
