import { useState, useEffect } from 'react';
import { useWeb3 } from '../web3';
import { useModuleMapAddress } from '../web3/chains';
import {
  ModuleMap,
  ModuleMap__factory,
  Kernel,
  Kernel__factory,
  BiosRewards,
  BiosRewards__factory,
  EtherRewards,
  EtherRewards__factory,
  IntegrationMap,
  IntegrationMap__factory,
  YieldManager,
  YieldManager__factory,
  SushiSwapTrader,
  SushiSwapTrader__factory,
  UniswapTrader,
  UniswapTrader__factory,
  IERC20MetadataUpgradeable,
  IERC20MetadataUpgradeable__factory,
  IWeth9,
  IWeth9__factory,
  StrategyMap__factory,
  StrategyMap,
  SushiSwapIntegration,
  SushiSwapIntegration__factory,
  SushiSwapIntegrationV2,
  SushiSwapIntegrationV2__factory,
  ISushiSwapMasterChefV2,
  ISushiSwapMasterChefV2__factory,
  UserPositionsV2__factory,
  UserPositionsV2,
} from '../assets/typechain';

const useModuleMapContract = () => {
  const { chainId, signerOrProvider } = useWeb3();
  const moduleMap = useModuleMapAddress(chainId);
  const [moduleMapContract, setModuleMapContract] = useState<ModuleMap>();

  useEffect(() => {
    if (!chainId || !moduleMap || !signerOrProvider) {
      setModuleMapContract(undefined);
      return;
    }

    setModuleMapContract(
      ModuleMap__factory.connect(moduleMap, signerOrProvider)
    );
  }, [chainId, moduleMap, signerOrProvider]);

  return moduleMapContract;
};

const useKernelContract = (moduleMap: ModuleMap | undefined) => {
  const { signerOrProvider, chainId } = useWeb3();
  const [kernelContract, setKernelContract] = useState<Kernel>();

  useEffect(() => {
    if (!moduleMap || !signerOrProvider || !chainId) {
      setKernelContract(undefined);
      return;
    }

    moduleMap
      .getModuleAddress(0) // 0 is kernel
      .then((kernel) =>
        setKernelContract(Kernel__factory.connect(kernel, signerOrProvider))
      )
      .catch(console.error);
  }, [moduleMap, signerOrProvider, chainId]);

  return kernelContract;
};

const useUserPositionsContract = (moduleMap: ModuleMap | undefined) => {
  const { signerOrProvider, chainId } = useWeb3();
  const [userPositionsContract, setUserPositionsContract] =
    useState<UserPositionsV2>();

  useEffect(() => {
    if (!moduleMap || !signerOrProvider || !chainId) {
      setUserPositionsContract(undefined);
      return;
    }

    moduleMap
      .getModuleAddress(1) // 1 is user positions
      .then((userPositions) =>
        setUserPositionsContract(
          UserPositionsV2__factory.connect(userPositions, signerOrProvider)
        )
      )
      .catch(console.error);
  }, [moduleMap, signerOrProvider, chainId]);

  return userPositionsContract;
};

const useYieldManagerContract = (moduleMap: ModuleMap | undefined) => {
  const { signerOrProvider, chainId } = useWeb3();
  const [yieldManagerContract, setYieldManagerContract] =
    useState<YieldManager>();

  useEffect(() => {
    if (!moduleMap || !signerOrProvider || !chainId) {
      setYieldManagerContract(undefined);
      return;
    }

    moduleMap
      .getModuleAddress(2) // 2 is yield manager
      .then((userPositions) =>
        setYieldManagerContract(
          YieldManager__factory.connect(userPositions, signerOrProvider)
        )
      )
      .catch(console.error);
  }, [moduleMap, signerOrProvider, chainId]);

  return yieldManagerContract;
};

const useIntegrationMapContract = (moduleMap: ModuleMap | undefined) => {
  const { signerOrProvider, chainId } = useWeb3();
  const [integrationMapContract, setIntegrationMapContract] =
    useState<IntegrationMap>();

  useEffect(() => {
    if (!moduleMap || !signerOrProvider || !chainId) {
      setIntegrationMapContract(undefined);
      return;
    }

    moduleMap
      .getModuleAddress(3) // 3 is integration map
      .then((integrationMap) =>
        setIntegrationMapContract(
          IntegrationMap__factory.connect(integrationMap, signerOrProvider)
        )
      )
      .catch(console.error);
  }, [moduleMap, signerOrProvider, chainId]);

  return integrationMapContract;
};

const useBiosRewardsContract = (moduleMap: ModuleMap | undefined) => {
  const { signerOrProvider, chainId } = useWeb3();
  const [biosRewardsContract, setBiosRewardsContract] = useState<BiosRewards>();

  useEffect(() => {
    // for now lets only use this on mainnet...
    if (
      !moduleMap ||
      !signerOrProvider ||
      !(chainId === 1 || chainId === 31337)
    ) {
      setBiosRewardsContract(undefined);
      return;
    }

    moduleMap
      .getModuleAddress(4) // 4 is bios rewards
      .then((rewards) =>
        setBiosRewardsContract(
          BiosRewards__factory.connect(rewards, signerOrProvider)
        )
      )
      .catch(console.error);
  }, [moduleMap, signerOrProvider, chainId]);

  return biosRewardsContract;
};

const useEtherRewardsContract = (moduleMap: ModuleMap | undefined) => {
  const { signerOrProvider, chainId } = useWeb3();
  const [etherRewardsContract, setEtherRewardsContract] =
    useState<EtherRewards>();

  useEffect(() => {
    if (!moduleMap || !signerOrProvider || !chainId) {
      setEtherRewardsContract(undefined);
      return;
    }

    moduleMap
      .getModuleAddress(5) // 5 is ether rewards
      .then((rewards) =>
        setEtherRewardsContract(
          EtherRewards__factory.connect(rewards, signerOrProvider)
        )
      )
      .catch(console.error);
  }, [moduleMap, signerOrProvider, chainId]);

  return etherRewardsContract;
};

const useSushiSwapTraderContract = (moduleMap: ModuleMap | undefined) => {
  const { signerOrProvider, chainId } = useWeb3();
  const [sushiSwapTraderContract, setSushiSwapTraderContract] =
    useState<SushiSwapTrader>();

  useEffect(() => {
    if (!moduleMap || !signerOrProvider || !chainId) {
      setSushiSwapTraderContract(undefined);
      return;
    }

    moduleMap
      .getModuleAddress(6) // 6 is SushiSwap Trader
      .then((sushi) =>
        setSushiSwapTraderContract(
          SushiSwapTrader__factory.connect(sushi, signerOrProvider)
        )
      )
      .catch(console.error);
  }, [moduleMap, signerOrProvider, chainId]);

  return sushiSwapTraderContract;
};

const useUniswapTraderContract = (moduleMap: ModuleMap | undefined) => {
  const { signerOrProvider, chainId } = useWeb3();
  const [uniswapTraderContract, setUniswapTraderContract] =
    useState<UniswapTrader>();

  useEffect(() => {
    if (!moduleMap || !signerOrProvider || !chainId) {
      setUniswapTraderContract(undefined);
      return;
    }

    moduleMap
      .getModuleAddress(7) // 7 is Uniswap Trader
      .then((uni) =>
        setUniswapTraderContract(
          UniswapTrader__factory.connect(uni, signerOrProvider)
        )
      )
      .catch(console.error);
  }, [moduleMap, signerOrProvider, chainId]);

  return uniswapTraderContract;
};

const useStrategyMapContract = (moduleMap: ModuleMap | undefined) => {
  const { signerOrProvider, chainId } = useWeb3();
  const [strategyMapContract, setStrategyMapContract] = useState<StrategyMap>();

  useEffect(() => {
    if (!moduleMap || !signerOrProvider || !chainId) {
      setStrategyMapContract(undefined);
      return;
    }

    moduleMap
      .getModuleAddress(8) // 8 is strategy map
      .then((strategy) =>
        setStrategyMapContract(
          StrategyMap__factory.connect(strategy, signerOrProvider)
        )
      )
      .catch(console.error);
  }, [moduleMap, signerOrProvider, chainId]);

  return strategyMapContract;
};

const useBiosTokenContract = (integrationMap: IntegrationMap | undefined) => {
  const { signerOrProvider, chainId } = useWeb3();
  const [biosTokenContract, setBiosTokenContract] =
    useState<IERC20MetadataUpgradeable>();

  useEffect(() => {
    if (!integrationMap || !signerOrProvider || !chainId) {
      setBiosTokenContract(undefined);
      return;
    }

    integrationMap
      .getBiosTokenAddress()
      .then((address) =>
        setBiosTokenContract(
          address
            ? IERC20MetadataUpgradeable__factory.connect(
                address,
                signerOrProvider
              )
            : undefined
        )
      )
      .catch(console.error);
  }, [integrationMap, signerOrProvider, chainId]);

  return biosTokenContract;
};

const useWethTokenContract = (integrationMap: IntegrationMap | undefined) => {
  const { signerOrProvider, chainId } = useWeb3();
  const [wethTokenContract, setWethTokenContract] = useState<IWeth9>();

  useEffect(() => {
    if (!integrationMap || !signerOrProvider || !chainId) {
      setWethTokenContract(undefined);
      return;
    }

    integrationMap
      .getWethTokenAddress()
      .then((address) =>
        setWethTokenContract(IWeth9__factory.connect(address, signerOrProvider))
      )
      .catch(console.error);
  }, [integrationMap, signerOrProvider, chainId]);

  return wethTokenContract;
};

const useSushiSwapIntegrationContract = (
  integrationMap: IntegrationMap | undefined
) => {
  const { signerOrProvider, chainId } = useWeb3();
  const [sushiSwapIntegrationContract, setSushiSwapIntegrationContract] =
    useState<SushiSwapIntegration>();

  useEffect(() => {
    if (
      !integrationMap ||
      !signerOrProvider ||
      !(chainId === 1 || chainId === 31337)
    ) {
      setSushiSwapIntegrationContract(undefined);
      return;
    }

    integrationMap
      .getIntegrationAddress(2)
      .then((address) =>
        setSushiSwapIntegrationContract(
          SushiSwapIntegration__factory.connect(address, signerOrProvider)
        )
      )
      .catch(console.error);
  }, [integrationMap, signerOrProvider, chainId]);

  return sushiSwapIntegrationContract;
};

const useSushiSwapIntegrationV2Contract = (
  integrationMap: IntegrationMap | undefined
) => {
  const { signerOrProvider, chainId } = useWeb3();
  const [sushiSwapIntegrationV2Contract, setSushiSwapIntegrationV2Contract] =
    useState<SushiSwapIntegrationV2>();

  useEffect(() => {
    if (
      !integrationMap ||
      !signerOrProvider ||
      !(chainId === 1 || chainId === 31337)
    ) {
      setSushiSwapIntegrationV2Contract(undefined);
      return;
    }

    integrationMap
      .getIntegrationAddress(3)
      .then((address) =>
        setSushiSwapIntegrationV2Contract(
          SushiSwapIntegrationV2__factory.connect(address, signerOrProvider)
        )
      )
      .catch(console.error);
  }, [integrationMap, signerOrProvider, chainId]);

  return sushiSwapIntegrationV2Contract;
};

const useSushiSwapIntegrationV2AContract = (
  integrationMap: IntegrationMap | undefined
) => {
  const { signerOrProvider, chainId } = useWeb3();
  const [contract, setContract] = useState<SushiSwapIntegrationV2>();

  useEffect(() => {
    if (
      !integrationMap ||
      !signerOrProvider ||
      !(chainId === 1 || chainId === 31337)
    ) {
      setContract(undefined);
      return;
    }

    integrationMap
      .getIntegrationAddress(4)
      .then((address) =>
        setContract(
          SushiSwapIntegrationV2__factory.connect(address, signerOrProvider)
        )
      )
      .catch(console.error);
  }, [integrationMap, signerOrProvider, chainId]);

  return contract;
};

const useSushiSwapMasterChefV2Contract = () => {
  const { signerOrProvider, chainId } = useWeb3();
  const [sushiSwapMasterChefV2Contract, setSushiSwapMasterChefV2Contract] =
    useState<ISushiSwapMasterChefV2>();

  useEffect(() => {
    if (!signerOrProvider) {
      setSushiSwapMasterChefV2Contract(undefined);
      return;
    }

    setSushiSwapMasterChefV2Contract(
      ISushiSwapMasterChefV2__factory.connect(
        '0xef0881ec094552b2e128cf945ef17a6752b4ec5d',
        signerOrProvider
      )
    );
  }, [signerOrProvider, chainId]);

  return sushiSwapMasterChefV2Contract;
};

export {
  useModuleMapContract,
  useKernelContract,
  useUserPositionsContract,
  useYieldManagerContract,
  useIntegrationMapContract,
  useBiosRewardsContract,
  useEtherRewardsContract,
  useSushiSwapIntegrationContract,
  useSushiSwapIntegrationV2Contract,
  useSushiSwapIntegrationV2AContract,
  useSushiSwapMasterChefV2Contract,
  useSushiSwapTraderContract,
  useUniswapTraderContract,
  useStrategyMapContract,
  useBiosTokenContract,
  useWethTokenContract,
};
