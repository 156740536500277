import { createContext, useContext } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Data, useSystemData } from './data';

const createDataRoot = (context: React.Context<Data>) => {
  const DataRoot = ({ children }: { children: React.ReactNode }) => {
    const data = useSystemData();

    return <context.Provider value={data}>{children}</context.Provider>;
  };

  return DataRoot;
};

const dataContext = createContext({} as Data);

const DataProvider = createDataRoot(dataContext);

const useData = () => {
  const context = useContext(dataContext);
  if (context === undefined) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: 10000 }, // 10s
  },
});

// :( https://stackoverflow.com/questions/71788254/react-18-typescript-children-fc/71809927
const QueryClientProviderOverride: any = QueryClientProvider;

const CacheProvider = ({ children }: { children: React.ReactNode }) => (
  <QueryClientProviderOverride client={queryClient}>
    {children}
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProviderOverride>
);

export { DataProvider, CacheProvider, useData };
