import Modal from 'components/ui/modal/Modal';

const RewardsModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: Function;
}) => {
  return (
    <Modal open={open} closeFunction={handleClose}>
      claim rewards
    </Modal>
  );
};
export default RewardsModal;
