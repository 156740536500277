import { useState } from 'react';
import Button from '../button/Button';
import CheckIcon from 'assets/icons/check';
import { ArrowPrefix } from '../prefixes/Prefix';
import upIcon from 'assets/icons/up.png';

export interface DropdownOptionProps {
  label: string | React.ReactNode;
  value: string | number;
  bgColor?: string;
  // TODO: add type
  Icon?: any;
}

export interface DropdownButtonProps {
  children: React.ReactNode;
  options: DropdownOptionProps[];
  onClick: Function;
  allowDropdown?: boolean;
  selectedList?: (number | string)[];
  unselectedDottedBorder?: boolean;
  compact?: boolean;
  className?: string;
  menuClassName?: string;
}

const DropdownButton = ({
  children,
  allowDropdown = true,
  options,
  onClick,
  selectedList,
  unselectedDottedBorder,
  compact,
  className,
  menuClassName,
}: DropdownButtonProps) => {
  const [showMenu, setShowMenu] = useState(false);
  if (!allowDropdown && showMenu) {
    setShowMenu(false);
  }

  return (
    <div className={`flex`}>
      <Button
        active={showMenu}
        color='white'
        className={`flex ${className ? className : ''}`}
        onClick={() => {
          if (allowDropdown) {
            setShowMenu(!showMenu);
          }
        }}
      >
        <div>{children}</div>

        {allowDropdown && options.length > 0 && (
          <div
            style={{ marginTop: '0.35em' }}
            className='ml-4 flex flex-col justify-center'
          >
            <img
              src={
                typeof upIcon === 'object'
                  ? (upIcon as { src: string }).src
                  : upIcon
              }
              alt='upIcon'
              className={`w-auto h-3 transform origin-center ${
                showMenu ? '' : 'rotate-180'
              }`}
            />
          </div>
        )}
      </Button>
      <br />
      {showMenu && (
        <DropdownMenu
          onClick={onClick}
          setShowMenu={setShowMenu}
          options={options}
          selectedList={selectedList}
          unselectedDottedBorder={unselectedDottedBorder}
          compact={compact}
          className={menuClassName}
        />
      )}
    </div>
  );
};

export interface DropdownMenuProps {
  onClick: Function;
  setShowMenu: Function;
  options: DropdownOptionProps[];
  selectedList?: (number | string)[];
  unselectedDottedBorder?: boolean;
  compact?: boolean;
  className?: string;
}

export const DropdownMenu = ({
  onClick,
  setShowMenu,
  options,
  selectedList,
  unselectedDottedBorder,
  compact,
  className,
}: DropdownMenuProps) => {
  return (
    <div className='z-30 relative'>
      <div
        onClick={() => setShowMenu(false)}
        className='w-screen h-screen fixed left-0 top-0'
      ></div>
      <div
        className={`flex flex-col absolute mt-8 top-6 right-0 p-6 bg-gray-darker w-72 rounded border-2 border-gray-dark grid grid-cols-1 gap-${
          compact ? '2' : '4'
        }
        ${className ? className : ''}`}
      >
        {options.map((option) => (
          <DropdownMenuOption
            data={option}
            selected={selectedList && selectedList.includes(option.value)}
            onClick={onClick}
            unselectedDottedBorder={unselectedDottedBorder}
            compact={compact}
          />
        ))}
      </div>
    </div>
  );
};

const DropdownMenuOption = ({
  data,
  selected,
  onClick,
  unselectedDottedBorder,
  compact,
  className,
}: {
  data: DropdownOptionProps;
  selected?: boolean;
  onClick: Function;
  unselectedDottedBorder?: boolean;
  compact?: boolean;
  className?: string;
}) => {
  const [isHover, setIsHover] = useState(false);

  const bgColor = data.bgColor ? data.bgColor : '#888888';

  const iconDiv = data.Icon ? (
    <div className='h-7 w-7 mr-2'>
      <data.Icon />
    </div>
  ) : (
    <></>
  );

  return (
    <button
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => onClick && onClick(data.value)}
      className={`p-${
        compact ? '2' : '4'
      } rounded text-left w-full text-white flex justify-between
      hover:bg-gray
      ${className ? className : ''}`}
      style={{
        backgroundColor: !compact
          ? bgColor + (isHover || selected ? '32' : '0D')
          : '',
        border: !compact
          ? `2px ${!selected && unselectedDottedBorder ? 'dashed' : 'solid'} ${
              bgColor + (selected ? `` : '44')
            }`
          : '',
      }}
    >
      <div className='flex'>
        <div className='flex flex-col justify-center'>
          {compact && selected ? (
            <ArrowPrefix textColor='green'>
              {iconDiv}
              {data.label}
            </ArrowPrefix>
          ) : (
            <div className='flex'>
              {iconDiv}
              {data.label}
            </div>
          )}
        </div>
      </div>
      {selected && !compact && (
        <div className='flex flex-col justify-center h-full'>
          <CheckIcon color={bgColor}></CheckIcon>
        </div>
      )}
    </button>
  );
};
DropdownButton.MenuOption = DropdownMenuOption;

export default DropdownButton;
