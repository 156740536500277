import { SlashPrefix } from '../prefixes/Prefix';
import linePattern from 'assets/images/linePattern.png';
import { CSSProperties } from 'react';

const CardHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      className={`flex w-full justify-between p-8 text-3xl border-b border-gray-light`}
    >
      <SlashPrefix className={`w-full`}>{children}</SlashPrefix>
    </div>
  );
};

const CardBody = ({
  children,
  scroll,
  lineBackground,
  hasFooter,
}: {
  children: React.ReactNode;
  scroll?: boolean;
  lineBackground?: boolean;
  hasFooter?: boolean;
}) => {
  return (
    <div
      className={`w-full p-4 md:p-6 text-white ${
        !scroll ? 'overflow-hidden' : 'overflow-y-scroll'
      } ${lineBackground && hasFooter ? 'border-b border-gray-light' : ''}`}
      style={!scroll ? undefined : { maxHeight: 'calc(100vh - 26rem)' }}
    >
      {children}
    </div>
  );
};

const CardFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{ background: `url(${linePattern}), #111111` }}
      className={`text-white p-4 md:p-6`}
    >
      {children}
    </div>
  );
};

export interface CardProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  footerOnSide?: boolean;
  scroll?: boolean;
  lineBackground?: boolean;
}

const Card = ({
  children,
  title,
  footer,
  footerOnSide,
  scroll,
  lineBackground,
}: CardProps) => {
  let style: CSSProperties = {
    boxShadow: '0px 0px 1px 1px #666',
  };

  if (lineBackground) {
    style.background = `url(${linePattern}), #111111`;
  }

  return (
    <div
      style={style}
      className={`bg-black flex flex-col ${
        footerOnSide ? 'lg:flex-row lg:justify-between' : ''
      } align-stretch rounded-md shadow-xl z-10 overflow-hidden justify-between`}
    >
      {title && <CardHeader>{title}</CardHeader>}
      <CardBody
        hasFooter={footer !== undefined}
        lineBackground={lineBackground}
        scroll={scroll}
      >
        {children}
      </CardBody>
      {footer && <CardFooter>{footer}</CardFooter>}
    </div>
  );
};

export default Card;
