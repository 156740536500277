import avaxIcon from 'assets/icons/assets/avax_icon.svg';
import biosIcon from 'assets/icons/assets/bios_icon.svg';
import bnbIcon from 'assets/icons/assets/bnb_icon.svg';
import ethIcon from 'assets/icons/assets/eth_icon.svg';
import ftmIcon from 'assets/icons/assets/ftm_icon.svg';
import maticIcon from 'assets/icons/assets/matic_icon.svg';
import metisIcon from 'assets/icons/assets/metis_icon.svg';
import usdtIcon from 'assets/icons/assets/usdt_icon.svg';

// TODO: reference icons by symbol not address
// TODO: add icons for all assets
const assetIcons: Record<string, string> = {
  ETH: ethIcon,
  BIOS: biosIcon,
  WETH: ethIcon,
  '0x0000000000000000000000000000000000000000': ethIcon,
  '0xAACa86B876ca011844b5798ECA7a67591A9743C8': ethIcon,
  '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': ethIcon,
  USDT: usdtIcon,

  // avalanche
  AVAX: avaxIcon,
  WAVAX: avaxIcon,

  // bsc
  BNB: bnbIcon,
  WBNB: bnbIcon,

  // fantom
  FTM: ftmIcon,
  WFTM: ftmIcon,

  // metis
  METIS: metisIcon,
  Metis: metisIcon, // technically this is their official symbol :) from ERC20.symbol()

  // polygon
  MATIC: maticIcon,
  WMATIC: maticIcon,
};

export default assetIcons;
