import { useQuery as useReactQuery } from 'react-query';
import { useEtherRewardsContract } from 'hooks/useContract';
import { useWeb3 } from '../../web3';

export const useUserClaimableRewardsByStrategy = (strategyId?: number) => {
  const { chainId, account } = useWeb3();
  const etherRewards = useEtherRewardsContract();

  const ready = !!(
    chainId &&
    account &&
    etherRewards &&
    typeof strategyId === 'number'
  );

  return useReactQuery(
    [chainId, account, 'rewards', 'claimable', 'byStrategy', strategyId],
    () => {
      if (!ready)
        throw new Error('useUserClaimableRewardsByStrategy is not ready!');

      return etherRewards.getUserStrategyRewards(strategyId, account);
    },
    { enabled: ready }
  );
};

export default useUserClaimableRewardsByStrategy;
