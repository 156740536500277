import KernelRewards from './KernelRewards/KernelRewards';
import StrategyKernel from './StrategyKernel/StrategyKernel';
// import UserKernel from './UserKernel/UserKernel';

const KernelPage = () => {
  return (
    <div className='grid grid-cols-1 gap-24'>
      <KernelRewards />
      {/* <UserKernel /> */}
      <StrategyKernel />
    </div>
  );
};

export default KernelPage;
