/**
 * Apollo GraphQL Queries
 */
import { gql } from '@apollo/client';

export const HELLO_WORLD = gql`
  query HelloWorldQuery {
    hello
  }
`;
