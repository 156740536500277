/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IProxyCloneFactory,
  IProxyCloneFactoryInterface,
} from "../../../contracts/interfaces/IProxyCloneFactory";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "implemetationHash",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "deployedClone",
        type: "address",
      },
    ],
    name: "CloneDeployed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "implemetationHash",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "proxyAdmin",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "proxy",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "implementation",
        type: "address",
      },
    ],
    name: "SetImplementation",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_implementationHash",
        type: "bytes32",
      },
      {
        internalType: "bytes",
        name: "_initData",
        type: "bytes",
      },
    ],
    name: "deployClone",
    outputs: [
      {
        internalType: "address",
        name: "deployedAddress_",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_implementationHash",
        type: "bytes32",
      },
    ],
    name: "getNextCloneAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_implementationHash",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "_proxyAdminAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_proxyAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_implementationAddress",
        type: "address",
      },
    ],
    name: "setImplementation",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IProxyCloneFactory__factory {
  static readonly abi = _abi;
  static createInterface(): IProxyCloneFactoryInterface {
    return new utils.Interface(_abi) as IProxyCloneFactoryInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IProxyCloneFactory {
    return new Contract(address, _abi, signerOrProvider) as IProxyCloneFactory;
  }
}
