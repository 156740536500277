import { useQueries } from 'react-query';
import {
  useEtherRewardsContract,
  useModuleAddressByChain,
} from 'hooks/useContract';
import { useWeb3 } from '../../web3';
import { useQuery } from '@apollo/client';
import {
  GET_STRATEGIES_SECTION_DATA,
  IStrategiesSectionData,
} from 'pages/StrategiesPage/StrategiesSection/StrategiesSection';
import { networkStyling } from 'data/chainData';
import { ethers } from 'ethers';
import { EtherRewards__factory } from 'assets/typechain';

export const useUserClaimableEthRewardsAmounts = () => {
  const { account, chainId } = useWeb3();
  const { data: strategiesData } = useQuery<IStrategiesSectionData>(
    GET_STRATEGIES_SECTION_DATA,
    {
      variables: { chainId },
      skip: typeof chainId === 'undefined',
    }
  );
  const strategies =
    strategiesData?.strategies?.filter((e) => e.chainId === chainId) || [];
  const etherRewards = useEtherRewardsContract();

  // add strategyId 0 to check PFA
  const strategyIdList = [...Array(strategies.length + 1).keys()];

  // strategyIdList defaults to empty array in which case no queries are generated
  const ready = !!(chainId && account && etherRewards);

  return useQueries(
    strategyIdList.map((strategyId) => ({
      queryKey: [
        chainId,
        account,
        'rewards',
        'claimable',
        'byStrategy',
        strategyId,
      ],
      queryFn: async () => {
        if (!ready) {
          throw new Error('Query is not ready!');
        }
        try {
          return await etherRewards.getUserStrategyRewards(strategyId, account);
        } catch (e) {
          return ethers.BigNumber.from(0);
        }
      },
      enabled: ready,
    }))
  );
};

export const useUserClaimableEthRewardsAmountsByChain = (
  chainId: number | undefined
) => {
  const { account } = useWeb3();
  const { data: etherRewardsAddress } = useModuleAddressByChain(5, chainId);
  const { data: strategiesData } = useQuery<IStrategiesSectionData>(
    GET_STRATEGIES_SECTION_DATA,
    {
      variables: { chainId },
      skip: typeof chainId === 'undefined',
    }
  );
  const strategies =
    strategiesData?.strategies?.filter((e) => e.chainId === chainId) || [];

  // add strategyId 0 to check PFA
  const strategyIdList = [...Array(strategies.length + 1).keys()];

  const rpcUrl = chainId && networkStyling[chainId]?.rpcUrl;

  // strategyIdList defaults to empty array in which case no queries are generated
  const ready = !!(chainId && account && etherRewardsAddress && rpcUrl);

  return useQueries(
    strategyIdList.map((strategyId) => ({
      queryKey: [
        chainId,
        account,
        'rewards',
        'claimable',
        'byStrategy',
        strategyId,
      ],

      queryFn: async () => {
        if (!ready) {
          throw new Error('Query is not ready!');
        }
        const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
        const etherRewards = EtherRewards__factory.connect(
          etherRewardsAddress,
          provider
        );
        try {
          return await etherRewards.getUserStrategyRewards(strategyId, account);
        } catch (e) {
          return ethers.BigNumber.from(0);
        }
      },

      enabled: ready,
    }))
  );
};

export default useUserClaimableEthRewardsAmounts;
