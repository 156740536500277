/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  TokenSettings,
  TokenSettingsInterface,
} from "../../../contracts/libraries/TokenSettings";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: true,
        internalType: "enum TokenSettings.TokenSettingName",
        name: "settingName",
        type: "uint8",
      },
      {
        indexed: true,
        internalType: "bool",
        name: "newValue",
        type: "bool",
      },
    ],
    name: "TokenSettingToggled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: true,
        internalType: "enum TokenSettings.TokenSettingName",
        name: "settingName",
        type: "uint8",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "newValue",
        type: "uint256",
      },
    ],
    name: "TokenSettingUpdated",
    type: "event",
  },
];

const _bytecode =
  "0x60566037600b82828239805160001a607314602a57634e487b7160e01b600052600060045260246000fd5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600080fdfea2646970667358221220b89d8592dbb804f29c72dcf501bff3b34861ec08e1a6c379377df0d7813e2c7564736f6c63430008040033";

type TokenSettingsConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: TokenSettingsConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class TokenSettings__factory extends ContractFactory {
  constructor(...args: TokenSettingsConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<TokenSettings> {
    return super.deploy(overrides || {}) as Promise<TokenSettings>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): TokenSettings {
    return super.attach(address) as TokenSettings;
  }
  override connect(signer: Signer): TokenSettings__factory {
    return super.connect(signer) as TokenSettings__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): TokenSettingsInterface {
    return new utils.Interface(_abi) as TokenSettingsInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): TokenSettings {
    return new Contract(address, _abi, signerOrProvider) as TokenSettings;
  }
}
