import linePattern from 'assets/images/linePattern.png';
import Card from 'components/ui/card/Card';
import imgSrcHelper from 'components/utils/imgSrcHelpepr';
const closeIcon = imgSrcHelper(require('assets/icons/x.png'));

export interface ModalProps {
  open: boolean;
  children: React.ReactNode;
  closeFunction: Function;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  long?: boolean;
  notFixed?: boolean;
}

const Modal = ({
  open,
  children,
  closeFunction,
  title,
  footer,
  long,
  notFixed,
}: ModalProps) => {
  const normalWidth = '520px';
  const longWidth = '66%';

  const handleClose = () => {
    closeFunction ? closeFunction() : console.log('no modal close function');
  };

  if (!open) return <></>;

  return (
    <div className='top-0 left-0 w-full h-full absolute flex justify-center'>
      <div
        className={`${notFixed ? 'relative' : 'fixed'} z-30 mt-32`}
        style={{ minWidth: '520px', width: long ? longWidth : normalWidth }}
      >
        <img
          onClick={() => handleClose()}
          className='absolute top-3 right-3 text-white cursor-pointer'
          src={closeIcon}
          alt='close-icon'
        />
        <Card title={title} footer={footer} scroll={true}>
          {children}
        </Card>
      </div>
      <div
        className={'absolute top-0 left-0 w-full h-full z-20'}
        onClick={() => handleClose()}
        style={{ background: `url(${linePattern}), #111111`, opacity: 0.8 }}
      ></div>
    </div>
  );
};

export default Modal;
