import { isAddressZero } from '@0xnodes/shared/utils';
import { TTokenAddress, TTokenSymbol } from '@0xnodes/shared/types';
import {
  IAssetWithAmount,
  ITotalObj,
} from 'components/ManageModal/ManageModalContainer';
import { useUserNativeBalance } from 'data/tokens';
import { BigNumber } from 'ethers';
import { useUserTokenBalance } from 'hooks/tokens/useUserTokenBalance';
import ManageModalInputLine from './ManageModalInputLine';
import biosIcon from 'assets/icons/assets/bios_icon.svg';
import { useUserKernelBalance } from 'hooks/tokens/useUserKernelBalance';
import useAssetIcon from 'hooks/tokens/useAssetIcon';
import { useUserSystemAllowance } from 'hooks/tokens/useUserSystemAllowance';

const KernelManageModalInputLineContainer = ({
  symbol,
  address,
  decimals,
  isInDepositingTab,
  setAssetsWithAmounts,
  setTotals,
  userDepositedValue,
  tokenPrice,
}: {
  symbol: TTokenSymbol;
  address: TTokenAddress;
  decimals: number;
  isInDepositingTab: boolean;
  setAssetsWithAmounts: React.Dispatch<
    React.SetStateAction<IAssetWithAmount[]>
  >;
  setTotals: React.Dispatch<React.SetStateAction<ITotalObj[]>>;
  userDepositedValue: BigNumber;
  tokenPrice: number;
}) => {
  const isZero = isAddressZero(address);
  const { data } = useUserTokenBalance(isZero ? '' : address);
  const { data: nativeBalance } = useUserNativeBalance();
  const { data: userDeposited } = useUserKernelBalance(isZero ? '' : address);
  const { data: systemAllowance } = useUserSystemAllowance(
    isZero ? '' : address
  );
  const icon = useAssetIcon(address);

  return (
    <ManageModalInputLine
      address={address}
      icon={icon || biosIcon}
      symbol={symbol}
      decimals={decimals}
      tokenPrice={tokenPrice}
      insideBalance={userDeposited}
      outsideBalance={isZero ? nativeBalance : data}
      allowance={systemAllowance}
      isInDepositingTab={isInDepositingTab}
      setAssetsWithAmounts={setAssetsWithAmounts}
      setTotals={setTotals}
      depositedColumn={true}
    />
  );
};

export default KernelManageModalInputLineContainer;
