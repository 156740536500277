import { BigNumber } from '@ethersproject/bignumber';
import { assetFormat, dollar } from 'components/ui/numberFormats';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useEffect, useState } from 'react';
import { TTokenAddress } from '@0xnodes/shared/types';
import { isAddressZero, isSameAddress } from '@0xnodes/shared/utils';
import {
  IAssetWithAmount,
  ITotalObj,
} from 'components/ManageModal/ManageModalContainer';
import getBlockExplorerLink from 'components/utils/blockExplorerLink';
import { useWeb3 } from '../../../../web3';
import { constants } from 'ethers';

const ManageModalInputLine = ({
  address,
  icon,
  symbol,
  decimals,
  tokenPrice,
  insideBalance,
  outsideBalance,
  allowance,
  isInDepositingTab,
  showDollars = false,
  setAssetsWithAmounts,
  setTotals,
  depositedColumn,
}: {
  address: TTokenAddress;
  icon?: string;
  symbol: string;
  decimals: number;
  tokenPrice: number;
  insideBalance?: BigNumber;
  outsideBalance?: BigNumber;
  allowance?: BigNumber;
  isInDepositingTab: boolean;
  showDollars?: boolean;
  setAssetsWithAmounts: React.Dispatch<
    React.SetStateAction<IAssetWithAmount[]>
  >;
  setTotals: React.Dispatch<React.SetStateAction<ITotalObj[]>>;
  depositedColumn?: boolean;
}) => {
  // NOTE: only relevant for kernel funds
  // TODO: implement
  // const isWithdrawable = true;
  const { chainId } = useWeb3();

  useEffect(() => {
    setInputValue('');
    setAssetsWithAmounts([]);
  }, [isInDepositingTab, setAssetsWithAmounts]);

  const availableBalance = isInDepositingTab ? outsideBalance : insideBalance;

  const [inputValue, setInputValue] = useState('');
  const [inputBN, setInputBN] = useState<BigNumber>();
  useEffect(() => {
    if (!inputValue || !decimals) {
      setInputBN(undefined);
      return;
    }

    setInputBN(parseUnits(inputValue, decimals));
  }, [inputValue, decimals]);

  const canDeposit = !!(
    !inputBN ||
    inputBN.eq(0) ||
    (inputBN?.gt(0) && outsideBalance?.gte(inputBN))
  );
  const canWithdraw = !!(
    !inputBN ||
    inputBN.eq(0) ||
    (inputBN?.gt(0) && insideBalance?.gte(inputBN))
  );

  useEffect(() => {
    setAssetsWithAmounts((asset) => {
      const allAssets = [...asset];
      const assetIndex = allAssets.findIndex(
        (d) => d.address.toLowerCase() === address.toLowerCase()
      );
      if (assetIndex === -1) {
        allAssets.push({
          address,
          canDeposit,
          canWithdraw,
          approved:
            !inputBN || isAddressZero(address) || !!allowance?.gte(inputBN),
          allowance,
          inputAmount: inputBN || BigNumber.from(0),
          decimals,
          symbol,
          icon,
          tokenPrice,
        });
      } else {
        allAssets[assetIndex].canDeposit = canDeposit;
        allAssets[assetIndex].canWithdraw = canWithdraw;
        allAssets[assetIndex].inputAmount = inputBN
          ? inputBN
          : BigNumber.from(0);
        allAssets[assetIndex].allowance = allowance;
        allAssets[assetIndex].approved =
          !inputBN || isAddressZero(address) || !!allowance?.gte(inputBN);
      }
      return allAssets;
    });
  }, [
    canDeposit,
    canWithdraw,
    inputBN,
    allowance,
    setAssetsWithAmounts,
    address,
    decimals,
    symbol,
    icon,
    tokenPrice,
  ]);

  useEffect(() => {
    setTotals((totals) => {
      const allTotals = [...totals];

      const totalIndex = allTotals.findIndex(
        (t) => t.address.toLowerCase() === address.toLowerCase()
      );
      const value = inputValue === '' ? 0 : parseInt(inputValue) * tokenPrice;

      if (totalIndex === -1) {
        allTotals.push({ address: address, value });
      } else {
        allTotals[totalIndex].value = value;
      }
      return allTotals;
    });
  }, [inputValue, setTotals, tokenPrice, address]);

  const inputDisabled = !availableBalance || availableBalance.eq(0);
  const inputError = isInDepositingTab ? !canDeposit : !canWithdraw;

  const tokenText = symbol ? (
    <div className='ml-2'>{symbol}</div>
  ) : (
    <div className='text-red ml-2'>{address}</div>
  );

  if (!symbol) {
    console.log('MISSING SYMBOL FOR ', address);
  }
  return (
    <div className='my-4'>
      <div className='grid grid-cols-12'>
        <div className='col-span-3 text-white text-xl flex flex-col justify-center -mt-6'>
          <div className='flex'>
            {icon && <img width={20} src={icon} alt={`${symbol}-icon`} />}
            {isSameAddress(constants.AddressZero, address) ? (
              tokenText
            ) : (
              <a
                target='_blank'
                rel='noreferrer'
                href={getBlockExplorerLink(chainId, address)}
              >
                {tokenText}
              </a>
            )}
          </div>
          {symbol === 'UST' ? (
            <div className='text-base font-bold'>wormhole</div>
          ) : null}
        </div>
        <div
          // className={`col-span-${depositedColumn ? '6' : '9'}`}
          // temp override to fix missing tailwind style
          style={{
            gridColumn: depositedColumn ? 'span 6 / span 6' : 'span 9 / span 9',
          }}
        >
          <div
            className={`p-2 flex justify-between rounded border ${
              inputDisabled
                ? 'border-gray-dark'
                : inputError
                ? 'border-red'
                : 'border-gray'
            }`}
          >
            <input
              value={inputValue}
              disabled={inputDisabled}
              placeholder={
                availableBalance
                  ? formatUnits(availableBalance, decimals)
                  : '---'
              }
              onChange={(e) => setInputValue(e.target.value)}
              onWheel={(e) => (e.target as HTMLInputElement).blur()}
              type='number'
              className={`bg-black outline-none text-xl w-full mr-2 ${
                inputDisabled
                  ? 'text-gray-light placeholder-gray-dark'
                  : inputError
                  ? 'text-red'
                  : inputValue === ''
                  ? 'placeholder-gray-light'
                  : 'text-green'
              }`}
            />
            <div className='flex justify-center'>
              <button
                disabled={inputDisabled}
                className={`${
                  inputDisabled
                    ? 'text-gray-dark bg-gray-darkest cursor-default'
                    : 'text-gray-light bg-gray-dark'
                } rounded w-min py-1 px-2`}
                onClick={() =>
                  availableBalance &&
                  setInputValue(formatUnits(availableBalance, decimals))
                }
              >
                Max
              </button>
            </div>
          </div>
          <div className='text-right text-sm text-gray mt-1'>
            {outsideBalance && availableBalance
              ? assetFormat(
                  parseInt(
                    formatUnits(
                      depositedColumn ? outsideBalance : availableBalance,
                      decimals
                    ),
                    10
                  )
                )
              : '---'}
          </div>
        </div>
        {depositedColumn && (
          <div className='col-span-3 flex flex-col justify-center mb-6 text-right text-gray-light'>
            <div className='text-sm'>
              {insideBalance
                ? assetFormat(
                    parseInt(formatUnits(insideBalance, decimals), 10)
                  )
                : '---'}
            </div>

            <div className='text-xs'>
              {showDollars && insideBalance
                ? dollar(
                    tokenPrice *
                      parseInt(formatUnits(insideBalance, decimals), 10)
                  )
                : '---'}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageModalInputLine;
