/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IStrategyMapV1,
  IStrategyMapV1Interface,
} from "../../../contracts/interfaces/IStrategyMapV1";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "strategyId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address",
            name: "integration",
            type: "address",
          },
          {
            internalType: "uint32",
            name: "ammPoolID",
            type: "uint32",
          },
        ],
        indexed: false,
        internalType: "struct IStrategyMapV1.Integration",
        name: "integration",
        type: "tuple",
      },
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "AddIntegration",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "DeleteStrategy",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "strategyId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "sourceIndex",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "destinationIndex",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "amount",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "rebalance",
        type: "bool",
      },
    ],
    name: "IntegrationWeightAdjustment",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address",
            name: "integration",
            type: "address",
          },
          {
            internalType: "uint32",
            name: "ammPoolID",
            type: "uint32",
          },
        ],
        indexed: false,
        internalType: "struct IStrategyMapV1.Integration[]",
        name: "integrations",
        type: "tuple[]",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "integrationPairIdx",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint32",
            name: "weight",
            type: "uint32",
          },
        ],
        indexed: false,
        internalType: "struct IStrategyMapV1.Token[]",
        name: "tokens",
        type: "tuple[]",
      },
      {
        indexed: false,
        internalType: "string",
        name: "name",
        type: "string",
      },
    ],
    name: "NewStrategy",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "strategyId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "integrationIndex",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "RemoveIntegration",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "name",
        type: "string",
      },
    ],
    name: "UpdateName",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "strategyId",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address",
            name: "integration",
            type: "address",
          },
          {
            internalType: "uint32",
            name: "ammPoolID",
            type: "uint32",
          },
        ],
        internalType: "struct IStrategyMapV1.Integration",
        name: "integration",
        type: "tuple",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "addPairToStrategy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        components: [
          {
            internalType: "address",
            name: "integration",
            type: "address",
          },
          {
            internalType: "uint32",
            name: "ammPoolID",
            type: "uint32",
          },
        ],
        internalType: "struct IStrategyMapV1.Integration[]",
        name: "integrations",
        type: "tuple[]",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "integrationPairIdx",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint32",
            name: "weight",
            type: "uint32",
          },
        ],
        internalType: "struct IStrategyMapV1.Token[]",
        name: "tokens",
        type: "tuple[]",
      },
      {
        internalType: "uint256[]",
        name: "maxCap",
        type: "uint256[]",
      },
    ],
    name: "addStrategy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "tokens",
        type: "address[]",
      },
    ],
    name: "clearClosablePositions",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "closePositionsForWithdrawal",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "integration",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "poolID",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "decreaseDeployAmountChange",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        internalType: "struct IUserPositionsV2.TokenMovement[]",
        name: "tokens",
        type: "tuple[]",
      },
    ],
    name: "decreaseStrategy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "deleteStrategy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "integration",
        type: "address",
      },
      {
        internalType: "uint32",
        name: "poolID",
        type: "uint32",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "getDeployAmount",
    outputs: [
      {
        internalType: "int256",
        name: "",
        type: "int256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "getMaximumCap",
    outputs: [
      {
        internalType: "uint256",
        name: "cap",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "ids",
        type: "uint256[]",
      },
    ],
    name: "getMultipleStrategies",
    outputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            components: [
              {
                internalType: "address",
                name: "integration",
                type: "address",
              },
              {
                internalType: "uint32",
                name: "ammPoolID",
                type: "uint32",
              },
            ],
            internalType: "struct IStrategyMapV1.Integration[]",
            name: "integrations",
            type: "tuple[]",
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "integrationPairIdx",
                type: "uint256",
              },
              {
                internalType: "address",
                name: "token",
                type: "address",
              },
              {
                internalType: "uint32",
                name: "weight",
                type: "uint32",
              },
            ],
            internalType: "struct IStrategyMapV1.Token[]",
            name: "tokens",
            type: "tuple[]",
          },
        ],
        internalType: "struct IStrategyMapV1.StrategySummary[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
    ],
    name: "getStrategy",
    outputs: [
      {
        components: [
          {
            internalType: "string",
            name: "name",
            type: "string",
          },
          {
            components: [
              {
                internalType: "address",
                name: "integration",
                type: "address",
              },
              {
                internalType: "uint32",
                name: "ammPoolID",
                type: "uint32",
              },
            ],
            internalType: "struct IStrategyMapV1.Integration[]",
            name: "integrations",
            type: "tuple[]",
          },
          {
            components: [
              {
                internalType: "uint256",
                name: "integrationPairIdx",
                type: "uint256",
              },
              {
                internalType: "address",
                name: "token",
                type: "address",
              },
              {
                internalType: "uint32",
                name: "weight",
                type: "uint32",
              },
            ],
            internalType: "struct IStrategyMapV1.Token[]",
            name: "tokens",
            type: "tuple[]",
          },
        ],
        internalType: "struct IStrategyMapV1.StrategySummary",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "_strategies",
        type: "uint256[]",
      },
      {
        internalType: "address[]",
        name: "_tokens",
        type: "address[]",
      },
    ],
    name: "getStrategyBalances",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "strategyID",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "address",
                name: "token",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "balance",
                type: "uint256",
              },
            ],
            internalType: "struct IStrategyMapV1.GeneralBalance[]",
            name: "tokens",
            type: "tuple[]",
          },
        ],
        internalType: "struct IStrategyMapV1.StrategyBalance[]",
        name: "strategyBalances",
        type: "tuple[]",
      },
      {
        components: [
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "balance",
            type: "uint256",
          },
        ],
        internalType: "struct IStrategyMapV1.GeneralBalance[]",
        name: "generalBalances",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "getStrategyTokenBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "strategy",
        type: "uint256",
      },
    ],
    name: "getStrategyTokenLength",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "getTokenTotalBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "idCounter",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        internalType: "struct IUserPositionsV2.TokenMovement[]",
        name: "tokens",
        type: "tuple[]",
      },
    ],
    name: "increaseStrategy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "strategyId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "sourceIndex",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "destinationIndex",
        type: "uint256",
      },
      {
        internalType: "int256",
        name: "vectorAmount",
        type: "int256",
      },
      {
        internalType: "uint32",
        name: "amount",
        type: "uint32",
      },
      {
        internalType: "bool",
        name: "rebalance",
        type: "bool",
      },
    ],
    name: "movePairWeight",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "strategyId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "integrationIndex",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "removePairFromStrategy",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "maxCap",
        type: "uint256",
      },
    ],
    name: "setMaximumCap",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
    ],
    name: "updateName",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IStrategyMapV1__factory {
  static readonly abi = _abi;
  static createInterface(): IStrategyMapV1Interface {
    return new utils.Interface(_abi) as IStrategyMapV1Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IStrategyMapV1 {
    return new Contract(address, _abi, signerOrProvider) as IStrategyMapV1;
  }
}
