import BinaryTextBig from './Stuff/BinaryTextBig';
import BinaryTextSmall from './Stuff/BinaryTextSmall';
import NoSenseText from './Stuff/NoSenseText';

const BackgroundStuff = () => {
  return (
    <div className='cursor-default select-none mt-60'>
      <div
        className='absolute'
        style={{
          top: '950px',
          width: '100vw',
        }}
      >
        <BinaryTextBig />
      </div>
      <div className='absolute bottom-0 flex flex-col gap-4 m-4 pt-16'>
        <NoSenseText className={``} />
        <BinaryTextSmall className={``} />
      </div>
    </div>
  );
};

export default BackgroundStuff;
