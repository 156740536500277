import { TChainId } from '@0xnodes/shared/types';
import SmallContainer from 'components/ui/smallContainer/SmallContainer';
import { useYieldDistributionSettings } from 'hooks/strategies/useYieldDistributionSettings';

export interface YieldDistributionContainerProps {
  chainId?: TChainId;
}

const LABELS: Record<string, string> = {
  treasury: 'Treasury',
  protocol: 'PFA',
  strategy: 'Strategies',
};

const YieldDistributionContainer = ({
  chainId,
}: YieldDistributionContainerProps) => {
  const { data: yieldDistribution } = useYieldDistributionSettings();
  const total = yieldDistribution
    ? Object.values(yieldDistribution).reduce((acc, each) => acc + each)
    : 100;
  const settings = yieldDistribution
    ? Object.entries(yieldDistribution).reduce(
        (acc, [key, value]) =>
          LABELS[key]
            ? [
                ...acc,
                {
                  label: LABELS[key],
                  value: ((value * 100) / total).toFixed(1),
                },
              ]
            : acc,
        [] as { label: string; value: string }[]
      )
    : [];
  return (
    <SmallContainer title='Yield Distribution'>
      {chainId && yieldDistribution ? (
        <div className='flex-col justify-between'>
          {settings.map(({ label, value }) => (
            <div className='flex' key={label}>
              <div className='mr-2 text-green'>{label}</div>
              <div className='text-white'>{value}%</div>
            </div>
          ))}
        </div>
      ) : (
        'Loading...'
      )}
    </SmallContainer>
  );
};

export default YieldDistributionContainer;
