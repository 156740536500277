import getBlockExplorerLink from 'components/utils/blockExplorerLink';
import { constants } from 'ethers';
import { useWeb3 } from '../../web3';

function EtherscanLink({
  children,
  address,
  className,
}: {
  children: React.ReactNode;
  address: string;
  className?: string;
}) {
  const classes = `text-green hover:text-pink hover:underline cursor-pointer ${
    className && className
  }`;

  const { chainId } = useWeb3();
  const blockExplorerLink = getBlockExplorerLink(chainId, address);

  if (!blockExplorerLink || address === constants.AddressZero) {
    return <div className={classes}>{children}</div>;
  }

  return (
    <a
      className={classes}
      href={blockExplorerLink}
      target='_blank'
      rel='noreferrer'
    >
      {children}
    </a>
  );
}

export default EtherscanLink;
