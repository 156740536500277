import { useState, useEffect } from 'react';
import { utils } from 'ethers';
import {
  IIntegration,
  IIntegration__factory,
  IntegrationMap,
  Kernel,
} from '../assets/typechain';
import { useWeb3 } from '../web3';

const useSupportedIntegrationsLength = (
  integrationMap: IntegrationMap | undefined
) => {
  const [length, setLength] = useState(0);

  useEffect(() => {
    if (!integrationMap) {
      setLength(0);
      return;
    }

    integrationMap
      .getIntegrationAddressesLength()
      .then((length) => setLength(length.toNumber()))
      .catch(console.error);
  }, [integrationMap]);

  return length;
};

const useSupportedIntegrationsAddresses = (
  integrationMap: IntegrationMap | undefined,
  supportedIntegrationsLength: number
) => {
  const [integrationsAddresses, setIntegrationsAddresses] =
    useState<string[]>();

  useEffect(() => {
    if (!integrationMap || supportedIntegrationsLength === 0) {
      setIntegrationsAddresses(undefined);
      return;
    }

    Promise.all(
      [...new Array(supportedIntegrationsLength).keys()].map((index) =>
        integrationMap.getIntegrationAddress(index)
      )
    )
      .then((addresses) =>
        setIntegrationsAddresses(
          addresses.map((address) => address.toLowerCase())
        )
      )
      .catch(console.error);
  }, [integrationMap, supportedIntegrationsLength]);

  return integrationsAddresses;
};

const useSupportedIntegrationsContracts = (
  supportedIntegrationsAddresses: string[] | undefined
) => {
  const [contracts, setContracts] = useState<
    { [address: string]: IIntegration } | undefined
  >();
  const { provider } = useWeb3();

  useEffect(() => {
    if (!supportedIntegrationsAddresses || !provider) {
      setContracts(undefined);
      return;
    }

    setContracts(
      supportedIntegrationsAddresses.reduce((p, c) => {
        p[c] = IIntegration__factory.connect(c, provider);
        return p;
      }, {} as { [address: string]: IIntegration })
    );
  }, [provider, supportedIntegrationsAddresses]);

  return contracts;
};

const useSupportedIntegrationsNames = (
  integrationMap: IntegrationMap | undefined,
  supportedIntegrationsContracts:
    | { [address: string]: IIntegration }
    | undefined
) => {
  const [names, setNames] = useState<
    { [address: string]: string } | undefined
  >();

  useEffect(() => {
    if (!integrationMap || !supportedIntegrationsContracts) {
      setNames(undefined);
      return;
    }

    Promise.all(
      Object.keys(supportedIntegrationsContracts).map((address) =>
        Promise.all([address, integrationMap.getIntegrationName(address)])
      )
    )
      .then((data) => {
        setNames(
          data.reduce((p, [address, name]) => {
            p[address] = name;
            return p;
          }, {} as { [address: string]: string })
        );
      })
      .catch(console.error);
  }, [integrationMap, supportedIntegrationsContracts]);

  return names;
};

const useStrategyRatios = (
  supportedTokens: string[] | undefined,
  supportedIntegrations: string[] | undefined,
  integrationContracts: { [address: string]: IIntegration } | undefined,
  prices: { [address: string]: number | undefined } | undefined,
  decimals: { [address: string]: number | undefined } | undefined,
  kernel: Kernel | undefined
) => {
  const { account } = useWeb3();
  const [ratios, setRatios] = useState<{
    [integrationAddress: string]: number;
  }>({});

  useEffect(() => {
    if (
      !supportedTokens ||
      !supportedIntegrations ||
      !integrationContracts ||
      !decimals ||
      !prices ||
      !kernel
    ) {
      setRatios({});
      return;
    }

    // make sure integrationContracts and supportedIntegrations match
    for (let i = 0; i < supportedIntegrations.length; i++) {
      if (!integrationContracts[supportedIntegrations[i]]) {
        setRatios({});
        return;
      }
    }

    const getData = () => {
      Promise.all(
        (supportedIntegrations || []).map((integration) => {
          return Promise.all([
            integration,
            Promise.all(
              supportedTokens.map((token) => {
                return Promise.all([
                  decimals[token],
                  prices[token],
                  integrationContracts[integration].getBalance(token),
                ]);
              })
            ),
          ]);
        })
      )
        .then((data) => {
          const integrationTotals = data.map(([address, assetBalances]) => {
            const balance = assetBalances.reduce(
              (p, [decimals, price, balance]) => {
                return (
                  p +
                  Number(utils.formatUnits(balance, decimals)) * (price || 0)
                );
              },
              0
            );
            return { address, balance };
          });
          const balanceTotal = integrationTotals.reduce((p, integration) => {
            return p + integration.balance;
          }, 0);
          const ratios = integrationTotals.reduce((p, integration) => {
            p[integration.address] = integration.balance / balanceTotal;
            return p;
          }, {} as { [integrationAddress: string]: number });
          setRatios(ratios);
        })
        .catch(console.error);
    };

    getData();

    // Listeners if user connected
    if (account) {
      const filter = kernel.filters.Deploy();
      kernel.on(filter, getData);

      return () => {
        kernel.removeListener(filter, getData);
      };
    }
  }, [
    supportedTokens,
    supportedIntegrations,
    prices,
    decimals,
    kernel,
    integrationContracts,
    account,
  ]);

  return ratios;
};

export {
  useSupportedIntegrationsLength,
  useSupportedIntegrationsAddresses,
  useSupportedIntegrationsContracts,
  useSupportedIntegrationsNames,
  useStrategyRatios,
};
