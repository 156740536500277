import { getChainNames } from 'web3/chains';
import { useWeb3 } from '../../web3';

const networkNames: Record<number, string> = {
  ...getChainNames(),
  31337: 'local-ethereum',
  31338: 'local-polygon',
  31339: 'local-avalanche',
  31340: 'local-fantom',
  31341: 'local-bsc',
  31343: 'local-andromeda',
};

export const useNetworkName = (chainId?: number) => {
  const { chainId: currentChain } = useWeb3();
  const chain = chainId || currentChain;
  return (chain && networkNames[chain]) || undefined;
};
