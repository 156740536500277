import { useState, useEffect } from 'react';
import { BigNumber, constants } from 'ethers';
import { useWeb3 } from '../web3';
import { Kernel, IWeth9, UserPositionsV2 } from '../assets/typechain';

const useUserDeposits = (
  kernel: Kernel | undefined,
  userPositions: UserPositionsV2 | undefined,
  tokens: string[] | undefined,
  wethContract: IWeth9 | undefined
) => {
  const { account } = useWeb3();
  const [deposits, setDeposits] = useState<
    { [address: string]: BigNumber } | undefined
  >();

  useEffect(() => {
    if (!kernel || !account || !tokens || !wethContract || !userPositions) {
      setDeposits(undefined);
      return;
    }

    const getData = () => {
      Promise.all(
        tokens.map((token) =>
          Promise.all([token, userPositions.userTokenBalance(token, account)])
        )
      )
        .then((data) => {
          const d = data.reduce((p, [address, depositAmount]) => {
            p[address] = depositAmount;
            return p;
          }, {} as { [address: string]: BigNumber });

          const wethDeposit = d[wethContract.address.toLowerCase()];

          setDeposits(
            Object.assign({}, d, { [constants.AddressZero]: wethDeposit })
          );
        })
        .catch(console.error);
    };

    getData();

    // Listeners if user connected
    if (account) {
      const depositFilter = userPositions.filters.Deposit(
        account,
        null,
        null,
        null
      );
      const withdrawFilter = kernel.filters.Withdraw(account, null, null, null);
      userPositions.on(depositFilter, getData);
      kernel.on(withdrawFilter, getData);

      const enterFilter = userPositions.filters.EnterStrategy(null, null, null);
      const exitFilter = userPositions.filters.ExitStrategy(null, null, null);
      userPositions.on(enterFilter, getData);
      userPositions.on(exitFilter, getData);

      return () => {
        userPositions.removeListener(enterFilter, getData);
        userPositions.removeListener(exitFilter, getData);
        kernel.removeListener(depositFilter, getData);
        kernel.removeListener(withdrawFilter, getData);
      };
    }
  }, [kernel, account, tokens, wethContract, userPositions]);

  return deposits;
};

export { useUserDeposits };
