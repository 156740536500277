/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  TestAMMLibrary,
  TestAMMLibraryInterface,
} from "../../../../contracts/mock/AMMLibrary.sol/TestAMMLibrary";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "availableLiquidity",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "reserve0",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "reserve1",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "totalSupply",
        type: "uint256",
      },
      {
        components: [
          {
            internalType: "address",
            name: "tokenA",
            type: "address",
          },
          {
            internalType: "address",
            name: "tokenB",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "positionID",
            type: "uint256",
          },
        ],
        internalType: "struct IAMMIntegration.Pool",
        name: "pool",
        type: "tuple",
      },
    ],
    name: "getLiquidityToWithdraw",
    outputs: [
      {
        internalType: "uint256",
        name: "liquidity",
        type: "uint256",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b506102a8806100206000396000f3fe608060405234801561001057600080fd5b506004361061002b5760003560e01c8063a7cfd77314610030575b600080fd5b61004361003e366004610161565b610055565b60405190815260200160405180910390f35b600061006688888888888888610072565b98975050505050505050565b60008082600001516001600160a01b0316896001600160a01b031614156100c55783156100bb57836100ac87670de0b6b3a7640000610247565b6100b69190610227565b6100be565b60005b90506100f3565b83156100ed57836100de86670de0b6b3a7640000610247565b6100e89190610227565b6100f0565b60005b90505b8061010257600091505061013a565b8061010e60028a610227565b61012090670de0b6b3a7640000610247565b61012a9190610227565b915086821115610138578691505b505b979650505050505050565b80356001600160a01b038116811461015c57600080fd5b919050565b600080600080600080600087890361012081121561017d578384fd5b61018689610145565b975060208901359650604089013595506060808a0135955060808a0135945060a08a0135935060bf19820112156101bb578182fd5b50604051606081016001600160401b03811182821017156101ea57634e487b7160e01b83526041600452602483fd5b6040526101f960c08a01610145565b815261020760e08a01610145565b602082015261010089013560408201528091505092959891949750929550565b60008261024257634e487b7160e01b81526012600452602481fd5b500490565b600081600019048311821515161561026d57634e487b7160e01b81526011600452602481fd5b50029056fea2646970667358221220683630068a5529521d31fc5a10298945db134e7c082d9a1d9bb7dbecccd8fccc64736f6c63430008040033";

type TestAMMLibraryConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: TestAMMLibraryConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class TestAMMLibrary__factory extends ContractFactory {
  constructor(...args: TestAMMLibraryConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<TestAMMLibrary> {
    return super.deploy(overrides || {}) as Promise<TestAMMLibrary>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): TestAMMLibrary {
    return super.attach(address) as TestAMMLibrary;
  }
  override connect(signer: Signer): TestAMMLibrary__factory {
    return super.connect(signer) as TestAMMLibrary__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): TestAMMLibraryInterface {
    return new utils.Interface(_abi) as TestAMMLibraryInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): TestAMMLibrary {
    return new Contract(address, _abi, signerOrProvider) as TestAMMLibrary;
  }
}
