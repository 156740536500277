import { isAddressZero } from '@0xnodes/shared/utils';
import { useUserPositionsContract } from 'hooks/useContract';
import { useQuery as useReactQuery } from 'react-query';
import { useWeb3 } from '../../web3';

export const useUserKernelBalance = (token: string) => {
  const { account, chainId } = useWeb3();
  const userPositions = useUserPositionsContract();
  const ready = !!(
    account &&
    chainId &&
    userPositions &&
    token &&
    !isAddressZero(token)
  );

  return useReactQuery(
    [chainId, account, 'kernelBalance', token],
    () => {
      if (!ready) throw new Error('useUserKernelBalance is not ready!');
      return userPositions.userTokenBalance(token, account);
    },
    { enabled: ready }
  );
};
