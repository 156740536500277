/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  ProxyClone,
  ProxyCloneInterface,
} from "../../../contracts/clones/ProxyClone";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_proxyAdmin",
        type: "address",
      },
      {
        internalType: "address",
        name: "_proxy",
        type: "address",
      },
      {
        internalType: "address",
        name: "_implementation",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    stateMutability: "nonpayable",
    type: "fallback",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b5060405161019638038061019683398101604081905261002f916100b9565b7fb53127684a568b3173ae13b9f8a6016e243e63b6e8ee1178d6a717850b5d6103929092557f12fe31f5c6f7b7eb37c3a9b1bcb2048b5844d6998ddcca840cc9fa541abf2a59557f360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc556100fb565b80516001600160a01b03811681146100b457600080fd5b919050565b6000806000606084860312156100cd578283fd5b6100d68461009d565b92506100e46020850161009d565b91506100f26040850161009d565b90509250925092565b608d806101096000396000f3fe6080604052348015600f57600080fd5b507f12fe31f5c6f7b7eb37c3a9b1bcb2048b5844d6998ddcca840cc9fa541abf2a59543660008037600080366000845af490503d6000803e8080156052573d6000f35b3d6000fdfea26469706673582212203fee3b8d6a463b183e13684206ead80b5be2cb4b62b3921beaf3d055caa6137964736f6c63430008040033";

type ProxyCloneConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: ProxyCloneConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class ProxyClone__factory extends ContractFactory {
  constructor(...args: ProxyCloneConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    _proxyAdmin: string,
    _proxy: string,
    _implementation: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<ProxyClone> {
    return super.deploy(
      _proxyAdmin,
      _proxy,
      _implementation,
      overrides || {}
    ) as Promise<ProxyClone>;
  }
  override getDeployTransaction(
    _proxyAdmin: string,
    _proxy: string,
    _implementation: string,
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(
      _proxyAdmin,
      _proxy,
      _implementation,
      overrides || {}
    );
  }
  override attach(address: string): ProxyClone {
    return super.attach(address) as ProxyClone;
  }
  override connect(signer: Signer): ProxyClone__factory {
    return super.connect(signer) as ProxyClone__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): ProxyCloneInterface {
    return new utils.Interface(_abi) as ProxyCloneInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ProxyClone {
    return new Contract(address, _abi, signerOrProvider) as ProxyClone;
  }
}
