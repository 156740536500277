import { useWeb3 } from '../../../web3';
import IconWithText from '../iconWithText/IconWithText';
import { dollar } from '../numberFormats';
import { infoToast } from 'components/utils/toastHelper';

const AddTokenButton = ({
  symbol,
  decimals,
  address,
  price,
  icon,
  imgLink,
  tokenChainId,
  disabled = false,
  className,
}: {
  symbol: string;
  decimals: number;
  address: string;
  price?: number;
  icon: React.ReactNode;
  imgLink: string;
  tokenChainId: number;
  disabled?: boolean;
  className?: string;
}) => {
  const { account, chainId } = useWeb3();

  const addToken = async () => {
    if (account) {
      if (tokenChainId === chainId) {
        try {
          await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address: address,
                symbol: symbol,
                decimals: decimals,
                image: imgLink,
              },
            },
          });
          // infoToast(`${symbol} added to wallet`, {
          //   toastId: 'biosAddedToWallet',
          // });
        } catch (error: any) {
          console.log('error adding token');
          console.log(error);
        }
      } else {
        infoToast('You are on the wrong network to add this token', {
          toastId: 'wrongNetworkToAddToken',
        });
      }
    } else {
      infoToast('You must connect your wallet to add tokens', {
        toastId: 'connectWalletToAddToken',
      });
    }
  };

  const onClick = async () => {
    if (account) {
      try {
        await addToken();
      } catch (error: any) {
        console.log('error adding BIOS');
        console.log(error);
      }
    } else {
      infoToast('You must connect your wallet to add BIOS', {
        toastId: 'connectWalletToSwitch',
      });
    }
  };

  // TODO: add tooltip with button description
  return (
    <IconWithText
      text={symbol}
      subText={
        price ? <span className='text-green'>{dollar(price)}</span> : '---'
      }
      icon={icon}
      className={className}
      onClick={disabled ? undefined : onClick}
    />
  );
};

export default AddTokenButton;
