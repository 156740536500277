import { useMemo } from 'react';

export interface SkeletonContainerProps {
  height: number;
  error: boolean;
}

const SkeletonContainer = ({ height, error }: SkeletonContainerProps) => {
  const style = 'rounded h-2 bg-green-darker mt-6 mx-6';

  // NOTE: if `h-2` or `m-6` is changed in `style` const this needs to be recalculated
  const lineHeight = 32;
  const numberOfRows = Math.floor(height / lineHeight) - 1;

  const randomWidth = () => {
    return 1 + Math.ceil(8 * Math.random());
  };

  const children = useMemo(
    () =>
      [...Array(numberOfRows)].map((e, i) =>
        i === 0 ? (
          <div className={style} key={i}></div>
        ) : (
          <div className={`${style} w-${randomWidth()}/12`} key={i}></div>
        )
      ),
    [numberOfRows]
  );

  return (
    <div className='bg-gray-opaque rounded-md'>
      <div
        className={` overflow-hidden ${
          !error ? 'animate-pulse' : 'opacity-60'
        } `}
        style={{ height: height }}
      >
        {children}
      </div>
    </div>
  );
};

export default SkeletonContainer;
