import { nativeCurrencies, tokenAddresses } from '@0xnodes/shared/addresses';
import { isAddressZero } from '@0xnodes/shared/utils';
import {
  IDepositableAsset,
  IDepositableAssetGroups,
  IQueryResponse,
  IToken,
  TChainId,
} from '@0xnodes/shared/types';
import { gql, useQuery } from '@apollo/client';
import {
  failedKernelDepositToast,
  failedKernelWithdrawToast,
  successfulKernelDepositToast,
  successfulKernelWithdrawToast,
} from 'components/utils/toastHelper';
import { useUserNativeBalance } from 'data/tokens';
import { useTransaction } from 'data/transactions';
import { BigNumber, constants, ethers } from 'ethers';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useWeb3 } from '../../web3';
import ManageModalContainer, {
  IAssetWithAmount,
  ManageModalState,
} from './ManageModalContainer';
import { useKernelContract } from 'hooks/useContract';

export const GET_KERNEL_MANAGE_MODAL_DATA = gql`
  query GetAssetsData($chainId: Int!) {
    assets(chainId: $chainId) {
      tokens {
        name
        address
        symbol
        decimals
        depositsEnabled
        withdrawalsEnabled
        LPEnabled
        bridgingEnabled
        biosRewardWeight
      }
    }
  }
`;

export interface IKernelManageModalData extends IQueryResponse {
  assetGroups?: IDepositableAssetGroups;
}

export const useKernelManageModalData = (
  chainId?: TChainId
): IKernelManageModalData => {
  const { loading, error, data } = useQuery<{ assets: { tokens: IToken[] } }>(
    GET_KERNEL_MANAGE_MODAL_DATA,
    {
      variables: { chainId },
      skip: typeof chainId === 'undefined',
    }
  );
  const { data: nativeBalance } = useUserNativeBalance();
  const [assetGroups, setAssetGroups] = useState<IDepositableAssetGroups>();

  useEffect(() => {
    if (!data || !chainId) return;
    const {
      assets: { tokens },
    } = data;
    const tokenBalances: Record<string, BigNumber> = {};

    const formatAsset = ({
      address,
      symbol,
      decimals,
      depositsEnabled,
      withdrawalsEnabled,
    }: IToken): IDepositableAsset => ({
      address,
      symbol,
      decimals: decimals as unknown as number,
      assetPrice: BigNumber.from('123'),
      userDeposited: BigNumber.from('9999999999999999999'),
      userBalance:
        (isAddressZero(address) ? nativeBalance : tokenBalances[address]) ||
        BigNumber.from(0),
      depositsEnabled,
      withdrawalsEnabled,
    });

    const groups = tokens.reduce(
      (acc, each) => {
        const address = each.address.toLowerCase();
        if (
          [
            ethers.constants.AddressZero,
            nativeCurrencies[chainId].wrappedAddress.toLowerCase(),
            '0xdac17f958d2ee523a2206206994597c13d831ec7', // mainnet USDT
            '0xa693b19d2931d498c5b318df961919bb4aee87a5', // mainnet UST WormHole
          ].includes(address)
        ) {
          return {
            ...acc,
            strategyAssets: [...acc.strategyAssets, formatAsset(each)],
          };
        } else if (tokenAddresses[chainId].BIOS?.toLowerCase() === address) {
          return {
            ...acc,
            pfaAssets: [...acc.pfaAssets, formatAsset(each)],
          };
        } else if (each.biosRewardWeight > 0) {
          return {
            ...acc,
            emitterAssets: [...acc.emitterAssets, formatAsset(each)],
          };
        } else {
          return acc;
        }
      },
      {
        strategyAssets: [] as IDepositableAsset[],
        pfaAssets: [] as IDepositableAsset[],
        emitterAssets: [] as IDepositableAsset[],
      }
    );

    // sort native before wrapped
    groups.strategyAssets.sort((a, b) =>
      BigNumber.from(a.address).gt(BigNumber.from(b.address)) ? 1 : -1
    );

    // sort symbol alphabetically
    groups.emitterAssets.sort((a, b) => a.symbol.localeCompare(b.symbol));

    setAssetGroups(groups);
  }, [chainId, data, nativeBalance, setAssetGroups]);

  return { loading, error, assetGroups };
};

export const KernelManageModal = ({
  state,
  setState,
}: {
  state: ManageModalState;
  setState: Dispatch<SetStateAction<ManageModalState>>;
}) => {
  const { chainId } = useWeb3();
  const { assetGroups } = useKernelManageModalData(chainId);
  const kernel = useKernelContract();

  const { contractCallNew, pending } = useTransaction();

  const kernelDeposit = (assetsWithAmounts: IAssetWithAmount[]) => {
    const depositAssets = assetsWithAmounts.filter((a) => a.inputAmount.gt(0));
    const ether = depositAssets.find(
      (a) => a.address === constants.AddressZero
    );
    const tokens = ether
      ? depositAssets.filter((a) => a.address !== constants.AddressZero)
      : [...depositAssets];
    const tokenAddresses = tokens.map((t) => t.address);
    const tokenAmounts = tokens.map((t) => t.inputAmount);

    if (kernel) {
      contractCallNew(
        () =>
          kernel.deposit(tokenAddresses, tokenAmounts, {
            value: ether ? ether.inputAmount : 0,
          }),
        'Depositing assets',
        () =>
          failedKernelDepositToast(depositAssets, {
            toastId: `failed-deposit-kernel`,
          }),
        () =>
          successfulKernelDepositToast(depositAssets, {
            toastId: `succeeded-deposit-kernel`,
          }),
        undefined,
        () => {
          setState(ManageModalState.closed);
        }
      );
    }
  };

  const kernelWithdraw = (assetsWithAmounts: IAssetWithAmount[]) => {
    if (!chainId) return;
    const withdrawAssets = assetsWithAmounts.filter((a) => a.inputAmount.gt(0));
    const withdrawEther = withdrawAssets.find((a) => isAddressZero(a.address));
    const ether = withdrawEther ? Object.assign({}, withdrawEther) : undefined;
    const tokensWithoutEther = ether
      ? withdrawAssets.filter((a) => a.address !== constants.AddressZero)
      : [...withdrawAssets];

    if (ether)
      ether.address = nativeCurrencies[chainId]?.wrappedAddress.toLowerCase();
    const tokens = ether
      ? [...tokensWithoutEther, ether]
      : [...tokensWithoutEther];
    const tokenAddresses = tokens.map((t) => t.address);
    const tokenAmounts = tokens.map((t) => t.inputAmount);

    if (kernel) {
      contractCallNew(
        () => kernel.withdraw(tokenAddresses, tokenAmounts, !!withdrawEther),
        'Withdrawing assets',
        () =>
          failedKernelWithdrawToast(tokens, {
            toastId: `failed-deposit-kernel`,
          }),
        () =>
          successfulKernelWithdrawToast(tokens, {
            toastId: `succeeded-deposit-kernel`,
          }),
        undefined,
        () => {
          setState(ManageModalState.closed);
        }
      );
    }
  };

  return (
    <ManageModalContainer
      state={state}
      setState={setState}
      pending={pending}
      enterContractCall={kernelDeposit}
      exitContractCall={kernelWithdraw}
      isStrategyManage={false}
      title={'Manage (_Kernel)'}
      assetGroups={assetGroups}
    />
  );
};
