import { toast, ToastOptions } from 'react-toastify';
import SmallContainer from 'components/ui/smallContainer/SmallContainer';
import theme from 'config/theme';
import { BigNumber } from 'ethers';
import { assetFormat } from 'components/ui/numberFormats';
import { formatUnits } from 'ethers/lib/utils';
import { IAssetWithAmount } from 'components/ManageModal/ManageModalContainer';
import { TTokenSymbol } from '@0xnodes/shared/types';
import { nativeCurrencies } from '@0xnodes/shared/addresses';
import { networkStyling } from 'data/chainData';

const toastContainer = (
  content: string | React.ReactNode,
  toastConfig: ToastOptions,
  type: 'confirmation' | 'error' | 'info'
) => {
  const color = type === 'confirmation' || type === 'info' ? 'green' : 'red';
  if (type === 'error')
    toastConfig.progressStyle = { backgroundColor: theme.colors.red.DEFAULT };

  return toast(
    <SmallContainer
      borderColor={color}
      lineBackground
      // containerColor={
      //   success
      //     ? tailwindConfig.theme.default.colors.green.DEFAULT
      //     : tailwindConfig.theme.default.colors.red.DEFAULT
      // }
      title={type}
      titlePrefixColor={color}
    >
      {content}
    </SmallContainer>,
    toastConfig
  );
};

export const infoToast = (
  content: string | React.ReactNode,
  toastConfig: ToastOptions
) => {
  return toastContainer(
    <div className='text-green text-lg'>{content}</div>,
    toastConfig,
    'info'
  );
};

export const errorToast = (
  content: string | React.ReactNode,
  toastConfig: ToastOptions
) => {
  return toastContainer(
    <div className='text-red text-lg'>{content}</div>,
    toastConfig,
    'error'
  );
};

const AssetItem = ({
  icon,
  decimals,
  symbol,
  amount,
}: {
  icon?: any;
  decimals: number;
  symbol: TTokenSymbol;
  amount: BigNumber;
}) => {
  return (
    <div className='flex items-center'>
      <div>
        {icon ? (
          <img width={12} src={icon} alt={`${symbol}-icon`} className='mr-1' />
        ) : null}
      </div>
      <div>{assetFormat(formatUnits(amount, decimals))}</div>
      <div className='ml-2'>{symbol}</div>
    </div>
  );
};

interface ITransactionToastContainerParams {
  success: boolean;
  title: string;
  assets: IAssetData[];
  actionSymbol?: string;
  text?: string;
}

interface IAssetData {
  icon?: any;
  decimals: number;
  symbol: TTokenSymbol;
  inputAmount: BigNumber;
}

const transactionToastContainer = (
  {
    success,
    title,
    assets,
    actionSymbol,
    text,
  }: ITransactionToastContainerParams,
  toastConfig: ToastOptions
) => {
  const color = success ? 'green' : 'red';
  return toastContainer(
    <div>
      <div>
        <div className={`text-${color} text-lg mb-2`}>{title}</div>
        <div>
          {assets.map((asset) => (
            <div className='flex' key={asset.symbol}>
              {actionSymbol ? (
                <div className={`text-${color} mr-2`}>{actionSymbol}</div>
              ) : null}
              <AssetItem
                icon={asset.icon}
                decimals={asset.decimals}
                symbol={asset.symbol}
                amount={asset.inputAmount}
              />
            </div>
          ))}
        </div>
        {text ? (
          <div className={`text-${success ? 'gray-light' : 'red'}`}>{text}</div>
        ) : null}
      </div>
    </div>,
    toastConfig,
    success ? 'confirmation' : 'error'
  );
};

export const successfulClaimRewardsToast = (
  chainId: number,
  amount: BigNumber,
  toastOptions: ToastOptions
) => {
  const { decimals, symbol } = nativeCurrencies[chainId];
  const { nativeAssetIcon } = networkStyling[chainId];
  return transactionToastContainer(
    {
      success: true,
      title: 'Claim.Rewards',
      assets: [
        { decimals, symbol, inputAmount: amount, icon: nativeAssetIcon },
      ],
      actionSymbol: '+',
      text: 'rewards claimed',
    },
    toastOptions
  );
};

export const failedClaimRewardsToast = (
  chainId: number,
  amount: BigNumber,
  toastOptions: ToastOptions
) => {
  const { decimals, symbol } = nativeCurrencies[chainId];
  const { nativeAssetIcon } = networkStyling[chainId];
  return transactionToastContainer(
    {
      success: false,
      title: 'Claim.Rewards',
      assets: [
        { decimals, symbol, inputAmount: amount, icon: nativeAssetIcon },
      ],
      actionSymbol: '+',
      text: 'rewards not claimed',
    },
    toastOptions
  );
};

export const successfulClaimBiosRewardsToast = (
  chainId: number,
  amount: BigNumber,
  toastOptions: ToastOptions
) => {
  const { nativeAssetIcon } = networkStyling[chainId];
  return transactionToastContainer(
    {
      success: true,
      title: 'Claim.BIOS.Rewards',
      assets: [
        {
          decimals: 18,
          symbol: 'BIOS',
          inputAmount: amount,
          icon: nativeAssetIcon,
        },
      ],
      actionSymbol: '+',
      text: 'rewards claimed',
    },
    toastOptions
  );
};

export const failedClaimBiosRewardsToast = (
  chainId: number,
  amount: BigNumber,
  toastOptions: ToastOptions
) => {
  const { nativeAssetIcon } = networkStyling[chainId];
  return transactionToastContainer(
    {
      success: false,
      title: 'Claim.BIOS.Rewards',
      assets: [
        {
          decimals: 18,
          symbol: 'BIOS',
          inputAmount: amount,
          icon: nativeAssetIcon,
        },
      ],
      actionSymbol: '+',
      text: 'rewards not claimed',
    },
    toastOptions
  );
};

export const successfulStrategyDepositToast = (
  assetsWithAmount: IAssetWithAmount[],
  toastConfig: ToastOptions,
  strategyName: string
) => {
  return transactionToastContainer(
    {
      success: true,
      title: '→ Enter.Strategy',
      assets: assetsWithAmount,
      actionSymbol: '+',
      text: `deposited to ${strategyName} strategy`,
    },
    toastConfig
  );
};
export const failedStrategyDepositToast = (
  assetsWithAmount: IAssetWithAmount[],
  toastConfig: ToastOptions,
  strategyName: string
) => {
  return transactionToastContainer(
    {
      success: false,
      title: '→ Enter.Strategy',
      assets: assetsWithAmount,
      actionSymbol: '+',
      text: `deposit to ${strategyName} strategy`,
    },
    toastConfig
  );
};

export const successfulStrategyWithdrawToast = (
  assetsWithAmount: IAssetWithAmount[],
  toastConfig: ToastOptions,
  strategyName: string
) => {
  return transactionToastContainer(
    {
      success: true,
      title: '← Exit.Strategy',
      assets: assetsWithAmount,
      actionSymbol: '-',
      text: `withdrawn from ${strategyName} strategy`,
    },
    toastConfig
  );
};
export const failedStrategyWithdrawToast = (
  assetsWithAmount: IAssetWithAmount[],
  toastConfig: ToastOptions,
  strategyName: string
) => {
  return transactionToastContainer(
    {
      success: false,
      title: '← Exit.Strategy',
      assets: assetsWithAmount,
      actionSymbol: '-',
      text: `withdraw from ${strategyName} strategy`,
    },
    toastConfig
  );
};

export const successfulKernelDepositToast = (
  assetsWithAmount: IAssetWithAmount[],
  toastOptions: ToastOptions
) => {
  return transactionToastContainer(
    {
      success: true,
      title: '+ Deposit',
      assets: assetsWithAmount,
      actionSymbol: '+',
      text: `deposited to user (_Kernel)`,
    },
    toastOptions
  );
};
export const failedKernelDepositToast = (
  assetsWithAmount: IAssetWithAmount[],
  toastOptions: ToastOptions
) => {
  return transactionToastContainer(
    {
      success: false,
      title: '+ Deposit',
      assets: assetsWithAmount,
      actionSymbol: '+',
      text: `deposit to user (_Kernel)`,
    },
    toastOptions
  );
};

export const successfulKernelWithdrawToast = (
  assetsWithAmount: IAssetWithAmount[],
  toastOptions: ToastOptions
) => {
  return transactionToastContainer(
    {
      success: true,
      title: '- Withdrawal',
      assets: assetsWithAmount,
      actionSymbol: '-',
      text: `withdrawn from user (_Kernel)`,
    },
    toastOptions
  );
};

export const failedKernelWithdrawToast = (
  assetsWithAmount: IAssetWithAmount[],
  toastOptions: ToastOptions
) => {
  return transactionToastContainer(
    {
      success: false,
      title: '- Withdrawal',
      assets: assetsWithAmount,
      actionSymbol: '-',
      text: `withdraw from user (_Kernel)`,
    },
    toastOptions
  );
};
