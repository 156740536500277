import { networks } from '@0xnodes/shared/networks';

const BLOCK_EXPLORERS = {
  ETHEREUM: 'etherscan.io',
  MATIC: 'polygonscan.com',
  FANTOM: 'ftmscan.com',
  AVALANCHE: 'snowtrace.io',
  BSC: 'bscscan.com',
  KOVAN: 'kovan.etherscan.io',
  METIS: 'andromeda-explorer.metis.io',
};

const getBlockExplorerUrl = (chainId?: number) => {
  switch (chainId) {
    case networks.ethereum:
    case networks.localhost:
      return BLOCK_EXPLORERS.ETHEREUM;
    case networks.kovan:
      return BLOCK_EXPLORERS.KOVAN;

    case networks.polygon:
    case networks.local_polygon:
      return BLOCK_EXPLORERS.MATIC;

    case networks.fantom:
    case networks.local_fantom:
      return BLOCK_EXPLORERS.FANTOM;

    case networks.avalanche:
    case networks.local_avalanche:
      return BLOCK_EXPLORERS.AVALANCHE;

    case networks.bsc:
    case networks.bsc_local:
      return BLOCK_EXPLORERS.BSC;

    case networks.metis:
    case networks.local_metis:
      return BLOCK_EXPLORERS.METIS;

    default:
      return undefined;
  }
};

const getBlockExplorerLink = (chainId: number | undefined, address: string) => {
  const blockExplorerUrl = getBlockExplorerUrl(chainId);

  if (blockExplorerUrl) {
    return `https://${blockExplorerUrl}/address/${address}`;
  } else {
    return undefined;
  }
};

export default getBlockExplorerLink;
