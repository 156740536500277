/**
 * Apollo GraphQL Provider
 */
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import * as React from 'react';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: process.env.REACT_APP_GRAPHQL_URL || 'http://localhost:4000/graphql',
});

export interface GraphQLProviderProps {
  children: React.ReactNode;
}

// :( https://stackoverflow.com/questions/71788254/react-18-typescript-children-fc/71809927
const ApolloProviderOverride: any = ApolloProvider;

export const GraphQLProvider = ({ children }: GraphQLProviderProps) => {
  return (
    <ApolloProviderOverride client={client}>{children}</ApolloProviderOverride>
  );
};

export default GraphQLProvider;
