import {
  IDepositableAsset,
  IDepositableAssetGroups,
} from '@0xnodes/shared/types';
import Button from 'components/ui/button/Button';
import { SlashPrefix } from 'components/ui/prefixes/Prefix';
import SlashesPrefix from 'components/ui/SlashesPrefix';
import { StrategyContext } from 'contexts/strategyContext_new';
import { BigNumber } from 'ethers';
import { useContext, useState } from 'react';
import { IAssetWithAmount, ITotalObj } from '../ManageModalContainer';
import KernelManageModalInputLineContainer from './InputLine/KernelManageModalInputLineContainer';
import StrategyManageModalInputLineContainer from './InputLine/StrategyManageModalInputLineContainer';
import upIcon from 'assets/icons/up.svg';
import downIcon from 'assets/icons/down.svg';
import useAssetData from 'hooks/tokens/useAssetData';

const ManageModalTokenList = ({
  assetGroups,
  isInDepositingTab,
  isStrategyManage,
  setAssetsWithAmounts,
  setTotals,
}: {
  assetGroups?: IDepositableAssetGroups;
  isInDepositingTab: boolean;
  isStrategyManage: boolean;
  setAssetsWithAmounts: React.Dispatch<
    React.SetStateAction<IAssetWithAmount[]>
  >;
  setTotals: React.Dispatch<React.SetStateAction<ITotalObj[]>>;
}) => {
  const [showEmitterAssets, setShowEmitterAssets] = useState(false);
  const { selectedStrategy } = useContext(StrategyContext);
  const underlying = selectedStrategy?.underlying;

  const assetData = useAssetData(underlying);

  const makeKernelInputLine = (asset: IDepositableAsset) => (
    <KernelManageModalInputLineContainer
      key={asset.address}
      address={asset.address}
      symbol={asset.symbol}
      decimals={asset.decimals}
      tokenPrice={asset.assetPrice.toNumber()}
      userDepositedValue={BigNumber.from(0)} // mock
      isInDepositingTab={isInDepositingTab}
      setAssetsWithAmounts={setAssetsWithAmounts}
      setTotals={setTotals}
    />
  );

  const filter = ({ depositsEnabled, withdrawalsEnabled }: IDepositableAsset) =>
    isInDepositingTab ? depositsEnabled : withdrawalsEnabled;
  const filteredGroups = assetGroups
    ? {
        strategyAssets: assetGroups.strategyAssets.filter(filter),
        pfaAssets: assetGroups.pfaAssets.filter(filter),
        emitterAssets: assetGroups.emitterAssets.filter(filter),
      }
    : {};

  // FOR KERNEL MGMT
  if (!isStrategyManage && assetGroups) {
    return (
      <>
        {filteredGroups.strategyAssets?.length ? (
          <div>
            <SectionHeader>Strategy Assets</SectionHeader>
            {filteredGroups.strategyAssets.map(makeKernelInputLine)}
          </div>
        ) : null}
        {filteredGroups.pfaAssets?.length ? (
          <div>
            <SectionHeader>
              <>
                Protocol.Fee Accrual (<span className='text-green'>$PFA</span>)
                Assets
              </>
            </SectionHeader>
            {filteredGroups.pfaAssets.map(makeKernelInputLine)}
          </div>
        ) : null}
        {filteredGroups.emitterAssets?.length ? (
          <div>
            <Button
              active={showEmitterAssets}
              onClick={() => setShowEmitterAssets(!showEmitterAssets)}
              color='white'
              className={`w-full flex justify-center`}
            >
              <SlashesPrefix slashColor='green'>
                <>
                  BIOS Emitter Assets
                  {
                    <img
                      className='ml-6'
                      src={showEmitterAssets ? upIcon : downIcon}
                      alt={showEmitterAssets ? 'close' : 'open'}
                    />
                  }
                </>
              </SlashesPrefix>
            </Button>
            {showEmitterAssets ? (
              <div className='mt-6'>
                {filteredGroups.emitterAssets.map(makeKernelInputLine)}
              </div>
            ) : null}
          </div>
        ) : null}
      </>
    );
  }

  if (isStrategyManage && selectedStrategy && underlying && assetData) {
    return (
      <div>
        <StrategyManageModalInputLineContainer
          key={`${selectedStrategy.chainId}-${selectedStrategy.id}-${underlying}`}
          address={underlying}
          symbol={assetData.symbol}
          decimals={assetData.decimals}
          strategy={selectedStrategy}
          isInDepositingTab={isInDepositingTab}
          setAssetsWithAmounts={setAssetsWithAmounts}
          setTotals={setTotals}
        />
      </div>
    );
  }

  return <>Loading...</>;
};

const SectionHeader = ({ children }: { children: JSX.Element | string }) => (
  <div className='flex justify-between'>
    <SlashPrefix>{children}</SlashPrefix>
    <div className='text-gray'>Deposited</div>
  </div>
);

export default ManageModalTokenList;
