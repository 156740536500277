import { useQueries, useQueryClient } from 'react-query';
import { useBiosEmitterContract } from 'hooks/useContract';
import { useWeb3 } from '../../web3';
import { networks } from '@0xnodes/shared/networks';
import { BigNumber } from 'ethers';

// just hard code for now
const ASSETS = [
  '0x31024A4C3e9aEeb256B825790F5cb7ac645e7cD5', // xiot
  '0x37E808F084101F75783612407e7C3f5F92d8ee3F', // ri
  '0x5c4ac68aAc56eBe098D621Cd8CE9F43270Aaa355', // bxiot
  '0x8b6DD24Bcb2D0aeA92c3AbD4eB11103A5DB6D714', // dxiot
  '0xE9a889E6963f122a98f8083d951c71329c726c0A', // BIOS SLP
];

const BIG_ZERO = BigNumber.from(0);

export const useUserClaimableBiosRewardsAmount = () => {
  const { account, chainId } = useWeb3();
  const biosEmitter = useBiosEmitterContract();
  const queryClient = useQueryClient();

  const ready = !!(
    (chainId === networks.ethereum || chainId === networks.localhost) &&
    account &&
    biosEmitter &&
    ASSETS
  );

  // once BiosEmitter.pendingBiosTotal is deployed we can use that instead of looping over assets
  const queries = useQueries(
    ASSETS.map((asset) => ({
      queryKey: [
        chainId,
        account,
        'biosRewards',
        'claimable',
        'byAsset',
        asset,
      ],
      queryFn: async () => {
        if (!ready) {
          throw new Error('Query is not ready!');
        }
        return biosEmitter.pendingBios(asset, account);
      },
      enabled: ready,
      staleTime: 16000,
      refetchInterval: 30000,
    }))
  );

  return {
    isSuccess: true,
    data: queries.reduce(
      (acc, each) => acc.add(each.data || BIG_ZERO),
      BIG_ZERO
    ),
    invalidator: () =>
      queryClient.invalidateQueries([chainId, account, 'biosRewards']),
  };

  // return useReactQuery(
  //   [chainId, account, 'biosRewards', 'claimable', 'total'],
  //   () => {
  //     if (!allReady) {
  //       throw new Error('Query is not ready!');
  //     }
  //     return queries.reduce(
  //       (acc, { data }) => acc.add(data || BigNumber.from(0)), // data should already exist here, but typescript
  //       BigNumber.from(0)
  //     );
  //   },
  //   { enabled: allReady, staleTime: 10000, refetchInterval: 10000 }
  // );
};
