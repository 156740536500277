import { BigNumber, utils } from 'ethers';
import { useEffect, useState } from 'react';
import {
  BiosRewards,
  EtherRewards,
  Kernel,
  YieldManager,
} from '../assets/typechain';
import { useWeb3 } from '../web3';
import { AssetType } from './tokens';

const useGlobalTotalPaidEthRewardsAmount = (
  kernel: Kernel | undefined,
  etherRewardsContract: EtherRewards | undefined
) => {
  const { account } = useWeb3();
  const [rewards, setRewards] = useState(BigNumber.from(0));
  useEffect(() => {
    if (!kernel || !etherRewardsContract) {
      setRewards((prev) => (prev.gt(0) ? BigNumber.from(0) : prev));
      return;
    }

    etherRewardsContract
      .getTotalClaimedEthRewards()
      .then(setRewards)
      .catch(console.error);

    // Listeners if user connected
    if (account) {
      const eventCallback = (
        _: string,
        __: BigNumber[],
        amount: BigNumber,
        ___: any
      ) => {
        setRewards((rewards) => rewards.add(amount));
      };

      const filter = kernel.filters.ClaimEthRewards(null, null);
      kernel.on(filter, eventCallback);

      return () => {
        kernel.removeListener(filter, eventCallback);
      };
    }
  }, [kernel, etherRewardsContract, account]);

  const [readableRewards, setReadableRewards] = useState(0);
  useEffect(() => {
    setReadableRewards(Number(utils.formatEther(rewards)));
  }, [rewards]);

  return readableRewards;
};

const useGlobalTotalPaidBiosRewardsAmount = (
  kernel: Kernel | undefined,
  biosRewardsContract: BiosRewards | undefined,
  tokens: string[] | undefined,
  decimals: { [address: string]: number | undefined } | undefined,
  assetTypes: { [address: string]: AssetType | undefined } | undefined
) => {
  const { account } = useWeb3();
  const [rewards, setRewards] = useState(BigNumber.from(0));
  useEffect(() => {
    if (!kernel || !biosRewardsContract) {
      return;
    }

    biosRewardsContract
      .getTotalClaimedBiosRewards()
      .then(setRewards)
      .catch(console.error);

    // Listeners if user connected
    if (account) {
      const eventCallback = (_: string, amount: BigNumber, __: any) => {
        setRewards((rewards) => rewards.add(amount));
      };

      const filter = kernel.filters.ClaimBiosRewards(null, null);
      kernel.on(filter, eventCallback);

      return () => {
        kernel.removeListener(filter, eventCallback);
      };
    }
  }, [biosRewardsContract, kernel, account]);

  const [readableRewards, setReadableRewards] = useState(0);
  useEffect(() => {
    if (!tokens || !decimals || !assetTypes) {
      return;
    }

    const bios = tokens.find((t) => assetTypes[t] === AssetType.BIOS);
    if (!bios || !decimals[bios]) {
      return;
    }

    setReadableRewards(Number(utils.formatUnits(rewards, decimals[bios])));
  }, [assetTypes, decimals, rewards, tokens]);

  return readableRewards;
};

const useUserTotalEthRewardsAmount = (
  etherRewardsContract: EtherRewards | undefined,
  kernelContract: Kernel | undefined
) => {
  const { account } = useWeb3();
  const [rewards, setRewards] = useState(BigNumber.from(0));
  const [display, setDisplay] = useState(0);

  useEffect(() => {
    if (!account || !etherRewardsContract || !kernelContract) {
      setRewards((prev) => (prev.gt(0) ? BigNumber.from(0) : prev));
      return;
    }

    etherRewardsContract
      // @ts-ignore
      .getUserEthRewards(account)
      .then(setRewards)
      .catch(console.error);

    // Listeners if user connected
    if (account) {
      const eventCallback = (
        _: string,
        __: BigNumber[],
        amount: BigNumber,
        ___: any
      ) => {
        setRewards((rewards) => rewards.add(amount));
      };

      const filter = kernelContract.filters.ClaimEthRewards(account, null);
      kernelContract.on(filter, eventCallback);

      return () => {
        kernelContract.removeListener(filter, eventCallback);
      };
    }
  }, [account, etherRewardsContract, kernelContract]);

  useEffect(() => {
    setDisplay(Number(utils.formatEther(rewards)));
  }, [rewards]);

  return display;
};

const useUserTotalBiosRewardsAmount = (
  biosRewardsContract: BiosRewards | undefined,
  tokens: string[] | undefined,
  decimals: { [address: string]: number | undefined } | undefined,
  assetTypes: { [address: string]: AssetType | undefined } | undefined
) => {
  const { account } = useWeb3();

  const [rewards, setRewards] = useState(BigNumber.from(0));
  useEffect(() => {
    if (!account || !biosRewardsContract) {
      setRewards((prev) => (prev.gt(0) ? BigNumber.from(0) : prev));
      return;
    }

    biosRewardsContract
      .getUserBiosRewards(account)
      .then(setRewards)
      .catch(console.error);
  }, [account, biosRewardsContract]);

  const [readableRewards, setReadableRewards] = useState(0);
  useEffect(() => {
    if (!tokens || !decimals || !assetTypes) {
      setReadableRewards(0);
      return;
    }

    const bios = tokens.find((t) => assetTypes[t] === AssetType.BIOS);
    if (!bios || !decimals[bios]) {
      setReadableRewards(0);
      return;
    }

    setReadableRewards(Number(utils.formatUnits(rewards, decimals[bios])));
  }, [assetTypes, decimals, rewards, tokens]);

  return readableRewards;
};

const useUserTotalPaidEthRewardsAmount = (
  kernel: Kernel | undefined,
  etherRewards: EtherRewards | undefined
) => {
  const { account } = useWeb3();

  const [rewards, setRewards] = useState(BigNumber.from(0));
  useEffect(() => {
    if (!kernel || !etherRewards || !account) {
      setRewards((prev) => (prev.gt(0) ? BigNumber.from(0) : prev));
      return;
    }

    etherRewards
      .getTotalUserClaimedEthRewards(account)
      .then(setRewards)
      .catch(console.error);

    // Listeners if user connected
    if (account) {
      const eventCallback = (
        _: string,
        __: BigNumber[],
        amount: BigNumber,
        ___: any
      ) => {
        setRewards((rewards) => rewards.add(amount));
      };

      const filter = kernel.filters.ClaimEthRewards(account, null);
      kernel.on(filter, eventCallback);

      return () => {
        kernel.removeListener(filter, eventCallback);
      };
    }
  }, [kernel, etherRewards, account]);

  const [readableReward, setReadableReward] = useState(0);
  useEffect(() => {
    setReadableReward(Number(utils.formatEther(rewards)));
  }, [rewards]);

  return readableReward;
};

const useUserTotalPaidBiosRewardsAmount = (
  kernel: Kernel | undefined,
  biosRewards: BiosRewards | undefined,
  tokens: string[] | undefined,
  decimals: { [address: string]: number | undefined } | undefined,
  assetTypes: { [address: string]: AssetType | undefined } | undefined
) => {
  const { account } = useWeb3();

  const [reward, setReward] = useState(BigNumber.from(0));
  useEffect(() => {
    if (!kernel || !biosRewards || !account) {
      return;
    }

    biosRewards
      .getTotalUserClaimedBiosRewards(account)
      .then(setReward)
      .catch(console.error);

    // Listeners if user connected
    if (account) {
      const eventCallback = (_: string, amount: BigNumber, __: any) => {
        setReward((reward) => reward.add(amount));
      };

      const filter = kernel.filters.ClaimBiosRewards(account, null);
      kernel.on(filter, eventCallback);

      return () => {
        kernel.removeListener(filter, eventCallback);
      };
    }
  }, [account, biosRewards, kernel]);

  const [readableReward, setReadableReward] = useState(0);
  useEffect(() => {
    if (!tokens || !decimals || !assetTypes) {
      return;
    }

    const bios = tokens.find((t) => assetTypes[t] === AssetType.BIOS);
    if (!bios || !decimals[bios]) {
      return;
    }

    setReadableReward(Number(utils.formatUnits(reward, decimals[bios])));
  }, [assetTypes, decimals, reward, tokens]);

  return readableReward;
};

const useTotalGlobalBiosRewardRate = (
  biosRewards: BiosRewards | undefined,
  supportedTokens: string[] | undefined,
  kernel: Kernel | undefined,
  decimals: { [address: string]: number | undefined } | undefined,
  assetTypes: { [address: string]: AssetType | undefined } | undefined
) => {
  const { account } = useWeb3();
  const [totalRate, setTotalRate] = useState(0);

  useEffect(() => {
    if (
      !biosRewards ||
      !supportedTokens ||
      !kernel ||
      !decimals ||
      !assetTypes
    ) {
      setTotalRate(0);
      return;
    }

    const bios = supportedTokens.find((t) => assetTypes[t] === AssetType.BIOS);
    if (!bios || !decimals[bios]) {
      setTotalRate(0);
      return;
    }

    const getData = () => {
      Promise.all(supportedTokens.map((t) => biosRewards.rewardRate(t)))
        .then((rates) =>
          setTotalRate(
            Number(
              utils.formatUnits(
                rates.reduce((p, c) => p.add(c), BigNumber.from(0)),
                decimals[bios]
              )
            )
          )
        )
        .catch(console.error);
    };

    getData();

    // Listeners if user connected
    if (account) {
      const filter = kernel.filters.BiosBuyBack();
      kernel.on(filter, getData);

      return () => {
        kernel.removeListener(filter, getData);
      };
    }
  }, [biosRewards, supportedTokens, kernel, decimals, assetTypes, account]);

  return totalRate;
};

const useTotalGlobalDepositedValue = (
  totalEthDepositedValue: number,
  totalBiosDepositedValue: number,
  totalStablesDepositedValue: number,
  totalAltsDepositedValue: number
) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(
      totalEthDepositedValue +
        totalBiosDepositedValue +
        totalStablesDepositedValue +
        totalAltsDepositedValue
    );
  }, [
    totalAltsDepositedValue,
    totalBiosDepositedValue,
    totalEthDepositedValue,
    totalStablesDepositedValue,
  ]);

  return value;
};

const useTotalEtherGeneratingDepositedValue = (
  totalEthDepositedValue: number,
  totalBiosDepositedValue: number,
  totalStablesDepositedValue: number
) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(
      totalEthDepositedValue +
        totalBiosDepositedValue +
        totalStablesDepositedValue
    );
  }, [
    totalBiosDepositedValue,
    totalEthDepositedValue,
    totalStablesDepositedValue,
  ]);

  return value;
};

const useSecondsPerYear = () => {
  const [secondsPerYear] = useState(31536000);
  return secondsPerYear;
};

const useBiosApy = (
  totalRewardRateValue: number,
  secondsPerYear: number,
  totalDepositedValue: number
) => {
  const [apy, setApy] = useState(0);

  useEffect(() => {
    if (totalDepositedValue === 0) {
      setApy(0);
      return;
    }

    setApy((totalRewardRateValue * secondsPerYear) / totalDepositedValue);
  }, [totalRewardRateValue, totalDepositedValue, secondsPerYear]);

  return apy;
};

const useBiosRewardEpochTimestamp = (
  biosRewards: BiosRewards | undefined,
  supportedAssetNames: { [address: string]: string } | undefined
) => {
  const [timestamp, setTimestamp] = useState(0);

  useEffect(() => {
    if (!biosRewards || !supportedAssetNames) {
      setTimestamp(0);
      return;
    }

    const xiot = Object.keys(supportedAssetNames).find(
      (a) => supportedAssetNames[a] === 'Xiotri'
    );
    if (!xiot) {
      setTimestamp(0);
      return;
    }

    const getData = () => {
      biosRewards
        .periodFinish(xiot)
        .then((ts) => setTimestamp(Number(ts)))
        .catch(console.error);
    };

    getData();
  }, [biosRewards, supportedAssetNames]);

  return timestamp;
};

const useLastEthRewardsAmount = (
  yieldManager: YieldManager | undefined,
  kernel: Kernel | undefined
) => {
  const { account } = useWeb3();
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (!yieldManager || !kernel) {
      setAmount(0);
      return;
    }

    const getData = () => {
      yieldManager
        .getLastEthRewardsAmount()
        .then((amount) => setAmount(Number(utils.formatEther(amount))))
        .catch(console.error);
    };

    getData();

    // Listeners if user connected
    if (account) {
      const filter = kernel.filters.DistributeEth();
      kernel.on(filter, getData);

      return () => {
        kernel.removeListener(filter, getData);
      };
    }
  }, [yieldManager, kernel, account]);

  return amount;
};

const useEtherRewardsTimePeriod = (kernel: Kernel | undefined) => {
  const { account } = useWeb3();
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (!kernel) {
      setTime(0);
      return;
    }

    const getData = () => {
      kernel
        .getEthRewardsTimePeriod()
        .then((time) => setTime(time.toNumber()))
        .catch(console.error);
    };

    getData();

    // Listeners if user connected
    if (account) {
      const filter = kernel.filters.DistributeEth();
      kernel.on(filter, getData);

      return () => {
        kernel.removeListener(filter, getData);
      };
    }
  }, [kernel, account]);

  return time;
};

const useEtherApy = (
  lastEthRewardsValue: number,
  secondsPerYear: number,
  totalEtherGeneratingDepositedValue: number,
  etherRewardsTimePeriod: number
) => {
  const [apy, setApy] = useState(0);

  useEffect(() => {
    if (
      totalEtherGeneratingDepositedValue === 0 ||
      etherRewardsTimePeriod === 0
    ) {
      setApy(0);
      return;
    }

    setApy(
      (lastEthRewardsValue * secondsPerYear) /
        (totalEtherGeneratingDepositedValue * etherRewardsTimePeriod)
    );
  }, [
    lastEthRewardsValue,
    etherRewardsTimePeriod,
    secondsPerYear,
    totalEtherGeneratingDepositedValue,
  ]);

  return apy;
};

export {
  useGlobalTotalPaidEthRewardsAmount,
  useGlobalTotalPaidBiosRewardsAmount,
  useUserTotalEthRewardsAmount,
  useUserTotalBiosRewardsAmount,
  useUserTotalPaidEthRewardsAmount,
  useUserTotalPaidBiosRewardsAmount,
  useTotalGlobalBiosRewardRate,
  useTotalGlobalDepositedValue,
  useSecondsPerYear,
  useBiosApy,
  useLastEthRewardsAmount,
  useTotalEtherGeneratingDepositedValue,
  useEtherRewardsTimePeriod,
  useEtherApy,
  useBiosRewardEpochTimestamp,
};
