import { useState, useEffect } from 'react';

const supportedChains = () => {
  const networks = getChainNames();
  return Object.keys(networks).map((n) => parseInt(n, 10));
};

interface IDictionary {
  [index: number]: string;
}
const productionChains: IDictionary = {
  1: 'Ethereum',
  137: 'Polygon',
  250: 'Fantom',
  43114: 'Avalanche',
  56: 'BSC',
  1088: 'Andromeda',
};
const testChains: IDictionary = {
  3: 'Ropsten',
  4: 'Rinkeby',
  42: 'Kovan',
  80001: 'Mumbai',
  31337: 'Local-Ethereum',
  31338: 'Local-Polygon',
  31339: 'Local-Avalanche',
  31340: 'Local-Fantom',
  31341: 'Local-BSC',
  31343: 'Local-Andromeda',
};

export const getChainNames = (): Record<number, string> => {
  const configNetworks = (process.env.REACT_APP_SUPPORTED_CHAIN_IDS || '')
    .split(',')
    .map((id) => parseInt(id.trim(), 10));
  const configProductionChains = configNetworks.reduce(
    (p, id) =>
      productionChains[id] ? { ...p, [id]: productionChains[id] } : p,
    {}
  );
  const configTestChains = configNetworks.reduce(
    (p, id) => (testChains[id] ? { ...p, [id]: testChains[id] } : p),
    {}
  );

  return process.env.NODE_ENV !== 'production'
    ? Object.assign(configProductionChains, configTestChains)
    : configProductionChains;
};

const supportedChainNames = (networkId: number): string | null =>
  getChainNames()[networkId] || null;

const useModuleMapAddress = (chainId: number | undefined) => {
  const [addresses, setAddresses] = useState<string>();

  useEffect(() => {
    if (!chainId) return;

    if (
      process.env.REACT_APP_LOCAL_CHAIN_ID &&
      chainId === parseInt(process.env.REACT_APP_LOCAL_CHAIN_ID, 10)
    ) {
      if (!process.env.REACT_APP_LOCAL_MODULE_MAP_ADDRESS) {
        console.error('No local addresses have been set!');
        setAddresses(undefined);
        return;
      }

      setAddresses(process.env.REACT_APP_LOCAL_MODULE_MAP_ADDRESS);
    } else {
      if (!process.env.REACT_APP_MODULE_MAP_ADDRESSES) {
        console.error('No addresses have been set!');
        setAddresses(undefined);
        return;
      }

      const networksAddresses = JSON.parse(
        process.env.REACT_APP_MODULE_MAP_ADDRESSES
      );
      const moduleMapAddress: string = networksAddresses[chainId];

      if (!moduleMapAddress) {
        console.error(`Address for network ${chainId} is not set!`);
        setAddresses(undefined);
        return;
      }

      setAddresses(moduleMapAddress);
    }
  }, [chainId]);

  return addresses;
};

export { supportedChains, supportedChainNames, useModuleMapAddress };
