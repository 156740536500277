function X() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M21.4721 22.1301L6.93807 7.59611L8.18384 6.35034L22.7178 20.8843L21.4721 22.1301Z" fill="#00FF8B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.57056 21.1045L21.1046 6.57045L22.3503 7.81622L7.81634 22.3502L6.57056 21.1045Z" fill="#00FF8B" />
    </svg>
  )
}

export default X;
