import { CrocPrefix } from './../prefixes/Prefix';

export interface SectionTitleProps {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  description: React.ReactNode;
  small?: boolean;
}

const SectionTitle = ({
  title,
  subtitle,
  description,
  small,
}: SectionTitleProps) => {
  return (
    <div>
      <div className={`text-white text-${small ? 'lg' : '4xl'}`}>{title}</div>
      <div className={`text-${small ? 'lg' : '4xl'}`}>
        {subtitle && (
          <CrocPrefix textColor={`gray-light`}>{subtitle}</CrocPrefix>
        )}
      </div>
      <div className={`text-white`}>{description}</div>
    </div>
  );
};

export default SectionTitle;
