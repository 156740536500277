import { useQuery as useReactQuery } from 'react-query';
import { useEtherRewardsContract } from 'hooks/useContract';
import { useWeb3 } from '../../web3';

export const useUserClaimedRewardsTotal = () => {
  const { chainId, account } = useWeb3();
  const etherRewards = useEtherRewardsContract();

  const ready = !!(chainId && account && etherRewards);

  return useReactQuery(
    [chainId, account, 'rewards', 'claimed', 'total'],
    () => {
      if (!ready) throw new Error('useUserClaimedRewardsTotal is not ready!');

      return etherRewards.getTotalUserClaimedEthRewards(account);
    },
    { enabled: ready }
  );
};

export default useUserClaimedRewardsTotal;
