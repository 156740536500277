function SlashesPrefix({
  children,
  className,
  slashColor,
  noWrap
}: {
  children: React.ReactNode,
  className?: string
  slashColor?: string
  noWrap?: boolean
}) {
  return (
    <div className={`flex text-left ${className && className}`}>
      <div className={`mr-2 text-${slashColor ? slashColor : 'orange'}`}>{'//'}</div>
      <div className={`flex ${!noWrap ? 'flex-wrap' : ''}`}>{children}</div>
    </div>
  );
}

export default SlashesPrefix;
