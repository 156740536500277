import { BigNumber } from 'ethers';
import { useEffect, useState } from 'react';
import { useQuery as useReactQuery } from 'react-query';
import { Kernel, StrategyMap, UserPositionsV2 } from '../assets/typechain';
import { useWeb3 } from '../web3';
import { useCachedData } from './data';

export interface IStrategy {
  id: number;
  name: string;
  totalWeight: number[];
  tokens: string[];
  integrations: { integration: string }[];
  totalBalances: { [token: string]: BigNumber };
}

const useStrategies = () => {
  const { chainId } = useWeb3();
  const { data } = useCachedData(chainId);
  return useReactQuery(
    [chainId, 'system', 'strategies'],
    async () => {
      if (typeof data === 'undefined') {
        throw new Error('Missing data');
      }
      const {
        strategies: { strategies: _strategies },
      } = data;
      const strategies = _strategies.map((each) => ({
        ...each,
        totalBalances: Object.fromEntries(
          Object.entries(each.totalBalances).map(([key, value]) => [
            key,
            BigNumber.from(value),
          ])
        ),
      }));
      return strategies;
    },
    {
      enabled: !!data && !!chainId,
    }
  );
};

// OLD
const useUserStrategiesBalances = (
  userPositionsContract: UserPositionsV2 | undefined,
  strategyMapContract: StrategyMap | undefined,
  strategies:
    | {
        id: number;
        name: string;
        totalWeight: number[];
        tokens: string[];
        integrations: { integration: string }[];
      }[]
    | undefined
) => {
  const { account } = useWeb3();
  const [tokenBalancesSet, setTokenBalancesSet] = useState<
    { [strategyId: number]: { [address: string]: BigNumber } } | undefined
  >({});

  useEffect(() => {
    if (
      !strategyMapContract ||
      !userPositionsContract ||
      !account ||
      !strategies
    ) {
      setTokenBalancesSet(undefined);
      return;
    }

    const getData = async () => {
      let totalUserBalances = {};
      const len = strategies.length;
      for (let i = 0; i < len; i++) {
        const strategy = strategies[i];
        let strategyTokenBalance: { [address: string]: BigNumber } = {};
        const { strategyBalance } = await userPositionsContract.getUserBalances(
          account,
          [strategy.id],
          strategy.tokens
        );
        const tokenAmounts: { [token: string]: BigNumber } | undefined =
          strategyBalance
            ? strategyBalance[0][1].reduce(
                (p, t) => ({ ...p, [t.token.toLowerCase()]: t.balance }),
                {}
              )
            : undefined;
        for (let t = 0; t < strategy.tokens.length; t++) {
          const token = strategy.tokens[t];
          const amount = tokenAmounts ? tokenAmounts[token] : BigNumber.from(0);
          strategyTokenBalance[token] = amount;
        }
        totalUserBalances = {
          ...totalUserBalances,
          [strategy.id]: strategyTokenBalance,
        };
      }
      setTokenBalancesSet(totalUserBalances);
    };

    getData();

    // no account is already short circuited earlier
    const enterFilter = userPositionsContract.filters.EnterStrategy(
      null,
      null,
      null
    );
    const exitFilter = userPositionsContract.filters.ExitStrategy(
      null,
      null,
      null
    );
    userPositionsContract.on(enterFilter, getData);
    userPositionsContract.on(exitFilter, getData);

    return () => {
      userPositionsContract.removeListener(enterFilter, getData);
      userPositionsContract.removeListener(exitFilter, getData);
    };
  }, [
    userPositionsContract,
    strategyMapContract,
    strategies?.length,
    account,
    strategies,
  ]);

  return tokenBalancesSet;
};

const useLastKernelDeployment = (
  kernelContract: Kernel | undefined
): number => {
  const { account } = useWeb3();
  const [lastDeploymentTimestamp, setLastDeploymentTimestamp] =
    useState<number>(0);

  useEffect(() => {
    if (!kernelContract) {
      return;
    }
    const getData = async () => {
      const lastDeployment = await kernelContract.getLastDeployTimestamp();
      setLastDeploymentTimestamp(BigNumber.from(lastDeployment).toNumber());
    };

    getData();

    if (account) {
      const deployed = kernelContract.filters.Deploy();
      kernelContract.on(deployed, getData);

      return () => {
        kernelContract.removeListener(deployed, getData);
      };
    }
  }, [kernelContract, account]);

  return lastDeploymentTimestamp;
};

export { useStrategies, useUserStrategiesBalances, useLastKernelDeployment };
