import { useStrategyMapContract } from 'hooks/useContract';
import { useQuery as useReactQuery } from 'react-query';
import { useWeb3 } from '../../web3';

// returns the number of strategies that exist (i.e. the strategyId of the newest strat)
export const useStrategyCount = () => {
  const { chainId } = useWeb3();
  const strategyMap = useStrategyMapContract();
  const ready = !!(chainId && strategyMap);

  return useReactQuery(
    [chainId, 'strategyCount'],
    async () => {
      if (!ready) throw new Error('not ready');

      return (await strategyMap.idCounter()).toNumber();
    },
    { enabled: ready }
  );
};

export default useStrategyCount;
