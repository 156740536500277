import { networkMetadata } from '@0xnodes/shared/networks';
import { nativeCurrencies } from '@0xnodes/shared/addresses';
import { infoToast } from './toastHelper';

export const addChainHelper = async (chainId: number) => {
  try {
    const requestParams = {
      ...networkMetadata[chainId],
      nativeCurrency: nativeCurrencies[chainId],
    };
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [requestParams],
    });
  } catch (error) {
    infoToast('Error switching networks', {
      toastId: 'addNetworkToWallet',
    });
  }
};

export const switchChainHelper = async (chainId: number) => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: networkMetadata[chainId].chainId }],
    });
  } catch (error: any) {
    if (error.code === 4902) {
      await addChainHelper(chainId);
    }
  }
};
