const OutlineIcon = ({ color: inputColor }: { color?: string }) => {
  const color = inputColor ? inputColor : '#FAFF00';

  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='20' cy='20' r='19.5' stroke={color} />
      <g clip-path='url(#clip0_1140_60693)'>
        <g clip-path='url(#clip1_1140_60693)'>
          <path
            d='M15.339 18.085L20 13.424L24.6632 18.087L27.3752 15.375L20 8L12.6272 15.3728L15.3391 18.0848L15.339 18.085ZM8 20L10.7121 17.2875L13.424 19.9994L10.7119 22.7115L8 20ZM15.339 21.9153L20 26.576L24.6631 21.9131L27.3765 24.6236L27.3752 24.6251L20 32L12.6272 24.6272L12.6234 24.6234L15.3393 21.915L15.339 21.9153ZM26.576 20.0012L29.2881 17.2891L32 20.001L29.288 22.7131L26.576 20.0012Z'
            fill={color}
          />
          <path
            d='M22.7505 19.9985H22.7517L20 17.2466L17.966 19.28L17.7323 19.5137L17.2504 19.9957L17.2466 19.9995L17.2504 20.0034L20 22.7533L22.7519 20.0014L22.7532 19.9999L22.7507 19.9985'
            fill='#00FF8B'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_1140_60693'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(8 8)'
          />
        </clipPath>
        <clipPath id='clip1_1140_60693'>
          <rect
            width='24.0001'
            height='24'
            fill='white'
            transform='translate(8 8)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OutlineIcon;
