const FillIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='20' cy='20' r='19.5' stroke={color ? color : '#8247E5'} />
      <path
        d='M26.1107 16.004C25.6679 15.7464 25.0922 15.7464 24.6052 16.004L21.1513 17.9789L18.8044 19.267L15.3506 21.2419C14.9078 21.4995 14.3321 21.4995 13.845 21.2419L11.0996 19.6963C10.6568 19.4387 10.3469 18.9664 10.3469 18.4512V15.4029C10.3469 14.8877 10.6126 14.4154 11.0996 14.1578L13.8007 12.6551C14.2435 12.3975 14.8192 12.3975 15.3063 12.6551L18.0074 14.1578C18.4502 14.4154 18.7602 14.8877 18.7602 15.4029V17.3779L21.107 16.0469V14.0719C21.107 13.5568 20.8413 13.0845 20.3543 12.8269L15.3506 9.99325C14.9078 9.73565 14.3321 9.73565 13.845 9.99325L8.75278 12.8269C8.26567 13.0845 8 13.5568 8 14.0719V19.7821C8 20.2973 8.26567 20.7696 8.75278 21.0272L13.845 23.8609C14.2878 24.1185 14.8635 24.1185 15.3506 23.8609L18.8044 21.9288L21.1513 20.5979L24.6052 18.6659C25.048 18.4083 25.6236 18.4083 26.1107 18.6659L28.8118 20.1685C29.2546 20.4262 29.5646 20.8984 29.5646 21.4137V24.4619C29.5646 24.9772 29.2989 25.4494 28.8118 25.707L26.1107 27.2527C25.6679 27.5102 25.0922 27.5102 24.6052 27.2527L21.9041 25.7499C21.4613 25.4924 21.1513 25.0201 21.1513 24.5049V22.5299L18.8044 23.8609V25.8358C18.8044 26.351 19.0701 26.8233 19.5572 27.0809L24.6495 29.9145C25.0922 30.1721 25.6679 30.1721 26.155 29.9145L31.2472 27.0809C31.69 26.8233 32 26.351 32 25.8358V20.1256C32 19.6104 31.7343 19.1382 31.2472 18.8805L26.1107 16.004Z'
        fill={color ? color : '#8247E5'}
      />
    </svg>
  );
};

export default FillIcon;
