import { strategyMetadata } from '@0xnodes/shared/metadata';
import { supportedChainIds } from '@0xnodes/shared/networks';
import {
  IQueryResponse,
  IStrategy,
  TChainId,
  TSubgraphStrategyId,
  TSubgraphUserId,
} from '@0xnodes/shared/types';
import { gql, useQuery } from '@apollo/client';
import SectionTitle from 'components/ui/sectionTitle/SectionTitle';
import { ArrowPrefix } from 'components/ui/prefixes/Prefix';
import StrategyCard, { StrategyCardProps } from './StrategyCard/StrategyCard';
import { useContext, useEffect, useState } from 'react';
import StrategiesHeader from './StrategiesHeader/StrategiesHeader';
import { StrategyContext } from 'contexts/strategyContext_new';
import { TTokenAddress } from '@0xnodes/shared/types';
import { BigNumber } from 'ethers';
import { useWeb3 } from '../../../web3';
import StrategyManageModal from 'components/ManageModal/StrategyManageModal';
import { errorToast } from 'components/utils/toastHelper';
import Skeletons from 'components/ui/skeletonContainer/Skeletons';
import { useUserClaimableRewardsByStrategy } from 'hooks/rewards/useUserClaimableRewardsByStrategy';
import { KernelManageModal } from 'components/ManageModal/KernelManageModal';
import { useNativeSymbol } from 'hooks/tokens/useNativeSymbol';
import { ManageModalState } from 'components/ManageModal/ManageModalContainer';
import useUserStrategyBalance, {
  useUserStrategyTokenBalance,
} from 'hooks/strategies/useUserStrategyBalance';
import { useIsTokenized } from 'hooks/network/useIsTokenized';

/**
 * GraphQL Query & Types
 */
export interface IBalancesData {
  token: TTokenAddress;
  amount: BigNumber;
}

export interface IStrategyData {
  id: TSubgraphStrategyId;
  name: string;
  totalBalances: IBalancesData[];
  tokens: TTokenAddress[];
  users: {
    id: TSubgraphUserId;
    balances: IBalancesData[];
  }[];
}

export const GET_STRATEGIES_SECTION_DATA = gql`
  query GetStrategiesData($chainId: Int!) {
    strategies(chainId: $chainId) {
      id
      chainId
      name
      description
      farms {
        symbol
        averageEntry
      }
      accepts
      returns
      risk
      apy
      availableRewards
      globalDeposits
      pendingYield
      underlying
    }
  }
`;

/**
 * Data Fetching Hook
 */
export interface IStrategiesSectionData extends IQueryResponse {
  strategies: IStrategy[] | undefined;
}

export const useStrategiesSectionData = (
  chainId?: TChainId
): IStrategiesSectionData => {
  const { loading, error, data } = useQuery(GET_STRATEGIES_SECTION_DATA, {
    variables: { chainId, global: true },
    skip: typeof chainId === 'undefined',
  });
  const [strategies, setStrategies] = useState<IStrategy[]>();
  // const useBalances = useUserBalancesData(chainId);

  useEffect(() => {
    if (!data) return;
    setStrategies(
      data.strategies?.filter(
        (each: IStrategy) => !strategyMetadata[each.id]?.hidden
      ) || []
    );
  }, [chainId, data, setStrategies]);

  return { loading, error, strategies };
};

// const useUserBalancesData = (chainId?: TChainId) => {
//   const moduleMapContract = useModuleMapContract();
//   const userPositionsContract = useUserPositionsContract(moduleMapContract);

//   // !!!!!!!! THIS DOES NOT WORK IF UNCOMMENTED !!!!!!!!!!

//   // const { data } = useEtherSWR(() => [
//   //   userPositionsContract?.address || '',
//   //   '',
//   //   '',
//   // ]);

//   const [userBalances, setUserBalances] = useState<any>();

//   // useEffect(() => {
//   //   if (!data) return;
//   //   console.log(data);
//   // }, [data]);

//   return userBalances;
// };

const UID = 'uid';
const APY = 'apy';
const INC = 'inc';
const DEC = 'dec';

const sorts = [
  {
    label: 'Newest First',
    value: `${UID}-${DEC}`,
  },
  {
    label: 'Oldest First',
    value: `${UID}-${INC}`,
  },
  {
    label: 'APY: High to Low',
    value: `${APY}-${DEC}`,
  },
  {
    label: 'APY: Low to High',
    value: `${APY}-${INC}`,
  },
];

/**
 * React Component
 */
const StrategiesSection = () => {
  const { chainId } = useWeb3();
  const { loading, error, strategies } = useStrategiesSectionData(chainId);
  const nativeSymbol = useNativeSymbol();

  const [kernelModalState, setKernelState] = useState(ManageModalState.closed);
  const [strategyModalState, setStrategyState] = useState(
    ManageModalState.closed
  );
  const [tableView, setTableView] = useState(false);
  const [filteredChains, setFilteredChains] = useState(supportedChainIds);

  const { setSelectedStrategy } = useContext(StrategyContext);

  const [currentSort, setCurrentSort] = useState(sorts[0]);

  const handleChainClick = (chainId: TChainId) => {
    let tempFilteredChains = [...filteredChains];
    const chainIdIndex = tempFilteredChains.indexOf(chainId);
    if (chainIdIndex >= 0) {
      tempFilteredChains.splice(chainIdIndex, 1);
    } else {
      tempFilteredChains.push(chainId);
    }
    setFilteredChains(tempFilteredChains);
  };

  const handleSortClick = (sortValue: any) => {
    const newSort = sorts.find((s) => s.value === sortValue);
    if (newSort) setCurrentSort(newSort);
  };

  const [sortBy, sortDir] = currentSort.value.split('-');

  useEffect(() => {
    if (error !== undefined && loading) {
      errorToast(
        <div>
          <div>Error getting strategies</div>
          <div className='text-sm'>{error.message}</div>
        </div>,
        { toastId: 'strategiesError' }
      );
    }
  }, [error, loading]);

  return (
    <div className='grid grid-cols-1 gap-12'>
      <SectionTitle
        title={'Strategies'}
        subtitle={'Native asset YIELDING STRATEGIES'}
        description={
          <div>
            <ArrowPrefix>
              {nativeSymbol ? (
                <>
                  <span className='text-green mr-2'>Deposit</span>
                  {` ${nativeSymbol} assets to the (_kernel)`}
                </>
              ) : (
                '...'
              )}
            </ArrowPrefix>
            <ArrowPrefix>
              {nativeSymbol ? (
                <>
                  <span className='text-green mr-2'>Manage</span>(_strategies)
                  to allocate assets
                </>
              ) : (
                '...'
              )}
            </ArrowPrefix>
            <ArrowPrefix>
              {nativeSymbol ? (
                <>
                  <span className='text-green mr-2'>Earn</span>
                  {nativeSymbol}
                </>
              ) : (
                '...'
              )}
            </ArrowPrefix>
          </div>
        }
      />
      <StrategiesHeader
        tableView={tableView}
        setTableView={setTableView}
        filteredChains={filteredChains}
        handleChainClick={handleChainClick}
        currentSort={currentSort}
        handleSortClick={handleSortClick}
        sorts={sorts}
        setModalState={setKernelState}
      />
      {loading || error ? (
        <Skeletons
          gridClassNames={`gap-8 grid-cols-1 ${
            !tableView && 'md:grid-cols-2 lg:grid-cols-3'
          }`}
          name={'Strategies'}
          error={error !== undefined}
          height={tableView ? 174 : 618}
          quantity={3}
        />
      ) : (
        strategies && (
          <div
            className={`grid grid-cols-1 ${
              !tableView && 'md:grid-cols-2 lg:grid-cols-3'
            } gap-8`}
          >
            {strategies
              .filter((s) => filteredChains.includes(s.chainId))
              .sort((a, b) => {
                let a2, b2;
                if (sortBy === APY) {
                  a2 = a.apy;
                  b2 = b.apy;
                } else if (sortBy === UID) {
                  a2 = a.id;
                  b2 = b.id;
                } else {
                  return 0;
                }
                if (sortDir === INC) {
                  if (sortBy === UID) {
                    if (a.chainId === chainId && b.chainId !== chainId)
                      return -1;
                    if (a.chainId !== chainId && b.chainId === chainId)
                      return 1;
                  }
                  return a2 > b2 ? 1 : -1;
                } else if (sortDir === DEC) {
                  if (sortBy === UID) {
                    if (a.chainId === chainId && b.chainId !== chainId)
                      return -1;
                    if (a.chainId !== chainId && b.chainId === chainId)
                      return 1;
                  }
                  return a2 < b2 ? 1 : -1;
                } else {
                  return 0;
                }
              })
              .map((strategy, index) => (
                <UserStrategyCard
                  key={strategy.id}
                  index={index}
                  strategy={strategy}
                  onClickHandler={() => {
                    setSelectedStrategy(strategy);
                    setStrategyState(ManageModalState.deposit);
                  }}
                  tableView={tableView}
                />
              ))}
          </div>
        )
      )}
      <KernelManageModal state={kernelModalState} setState={setKernelState} />
      <StrategyManageModal
        state={strategyModalState}
        setState={setStrategyState}
      />
    </div>
  );
};

const UserStrategyCard = (props: StrategyCardProps) => {
  const { account, chainId } = useWeb3();
  const strategyId = parseInt(props.strategy.id.split('#')[1], 10);
  const networkConnected = chainId === props.strategy.chainId;
  const userConnected = !!account;
  const { data: userPosition } = useUserStrategyBalance(
    strategyId,
    props.strategy.underlying
  );
  const { data: stratTokenBalance } = useUserStrategyTokenBalance(strategyId);
  const tokenized = useIsTokenized();
  const { data: claimableRewards } =
    useUserClaimableRewardsByStrategy(strategyId);

  return (
    <StrategyCard
      userPosition={tokenized ? stratTokenBalance : userPosition}
      availableYield={claimableRewards}
      userConnected={userConnected}
      networkConnected={networkConnected}
      {...props}
    />
  );
};

export default StrategiesSection;

// const DUMMY_STRAT: IStrategy = {
//   id: 'mainnet#4',
//   chainId: 1,
//   name: 'DRO',
//   description: 'UniswapV3: Dynamic Range Order',
//   farms: [
//     {
//       symbol: 'BIOS',
//       averageEntry: ethers.BigNumber.from('420'),
//     },
//   ],
//   accepts: ['USDT'],
//   returns: ['USDT'],
//   risk: 1,
//   apy: 16.56,
//   availableRewards: ethers.BigNumber.from('12345'),
//   globalDeposits: '324599643835423490643',
//   pendingYield: 0,
// };
