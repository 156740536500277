import { supportedChainIds } from '@0xnodes/shared/networks';
import { INetworkStats } from '@0xnodes/shared/types';
import { getNetworkName } from '@0xnodes/shared/utils';
import { ArrowPrefix } from 'components/ui/prefixes/Prefix';
import SlashesPrefix from 'components/ui/SlashesPrefix';
import SmallContainer from 'components/ui/smallContainer/SmallContainer';
import StatusCircle from './StatusCircle';

export interface NetworkStatsContainerProps {
  networkStats?: INetworkStats[];
}

const NetworkStatusContainer = ({
  networkStats,
}: NetworkStatsContainerProps) => (
  <SmallContainer title={'Network Status'}>
    {supportedChainIds.map((chainId) => (
      <div key={chainId} className='flex justify-between'>
        <div className='flex'>
          <StatusCircle on={true} />
          <SlashesPrefix slashColor='gray'>
            <div className='text-white'>{getNetworkName(chainId)}</div>
          </SlashesPrefix>
        </div>
        <ArrowPrefix
          prefixColor={true ? 'green' : 'gray-light'}
          textColor={true ? 'green' : 'gray-light'}
        >
          {true ? 'Enabled' : 'Disabled'}
        </ArrowPrefix>
      </div>
    ))}
  </SmallContainer>
);

export default NetworkStatusContainer;
