/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  IKernel,
  IKernelInterface,
} from "../../../contracts/interfaces/IKernel";

const _abi = [
  {
    anonymous: false,
    inputs: [],
    name: "BiosBuyBack",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "biosRewards",
        type: "uint256",
      },
    ],
    name: "ClaimBiosRewards",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "strategies",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ethRewards",
        type: "uint256",
      },
    ],
    name: "ClaimEthRewards",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "Deploy",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "DistributeEth",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint32",
        name: "biosBuyBackEthWeight",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "treasuryEthWeight",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "protocolFeeEthWeight",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "rewardsEthWeight",
        type: "uint32",
      },
      {
        indexed: false,
        internalType: "uint32",
        name: "syntheticPoolSupplementWeight",
        type: "uint32",
      },
    ],
    name: "EthDistributionWeightsUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "gasAccountTargetEthBalance",
        type: "uint256",
      },
    ],
    name: "GasAccountTargetEthBalanceUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "gasAccount",
        type: "address",
      },
    ],
    name: "GasAccountUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "HarvestYield",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "contractAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "string",
        name: "name",
        type: "string",
      },
    ],
    name: "IntegrationAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "biosAmount",
        type: "uint256",
      },
    ],
    name: "SeedBiosRewards",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint32",
        name: "biosRewardsDuration",
        type: "uint32",
      },
    ],
    name: "SetBiosRewardsDuration",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "acceptingDeposits",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "acceptingWithdrawals",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "acceptingLping",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "acceptingBridging",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "biosRewardWeight",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "reserveRatioNumerator",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "targetLiquidityRatioNumerator",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "transferFeeKValueNumerator",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "transferFeePlatformRatioNumerator",
        type: "uint256",
      },
    ],
    name: "TokenAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "treasuryAccount",
        type: "address",
      },
    ],
    name: "TreasuryAccountUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "tokens",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "tokenAmounts",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ethAmount",
        type: "uint256",
      },
    ],
    name: "Withdraw",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address[]",
        name: "tokens",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "strategies",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "withdrawWethAsEth",
        type: "bool",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "tokenAmounts",
        type: "uint256[]",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ethWithdrawn",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ethRewards",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "biosRewards",
        type: "uint256",
      },
    ],
    name: "WithdrawAllAndClaim",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "isLiquidityProvider",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "isManager",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "isOwner",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class IKernel__factory {
  static readonly abi = _abi;
  static createInterface(): IKernelInterface {
    return new utils.Interface(_abi) as IKernelInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IKernel {
    return new Contract(address, _abi, signerOrProvider) as IKernel;
  }
}
