import { useYieldManagerContract } from 'hooks/useContract';
import { useQuery as useReactQuery } from 'react-query';
import { useWeb3 } from '../../web3';

export const useYieldDistributionSettings = () => {
  const { chainId } = useWeb3();
  const yieldManager = useYieldManagerContract();
  const ready = !!(chainId && yieldManager);

  return useReactQuery(
    [chainId, 'yieldDistribution'],
    async () => {
      if (!ready) throw new Error('useYieldDistributionSettings is not ready!');
      const [biosBuyBack, treasury, protocol, strategy] =
        await yieldManager.getEthDistributionWeights();
      return { biosBuyBack, treasury, protocol, strategy };
    },
    { enabled: ready }
  );
};
