import SmallContainer from 'components/ui/smallContainer/SmallContainer';
import { SlashPrefix } from 'components/ui/prefixes/Prefix';
import { DotPrefix } from './../../../../components/ui/prefixes/Prefix';
import Button from 'components/ui/button/Button';
import { CSSProperties } from 'react';
import { switchChainHelper } from 'components/utils/metamaskHelper';
import { assetFormat, dollar } from 'components/ui/numberFormats';
import { networkColors, networks } from '@0xnodes/shared/networks';
import { nativeCurrencies } from '@0xnodes/shared/addresses';
import { networkStyling } from 'data/chainData';
import { BigNumber, ethers } from 'ethers';
import { TChainId, TTokenSymbol } from '@0xnodes/shared/types';
import { formatUnits } from 'ethers/lib/utils';
import { useUserClaimableEthRewardsAmountsByChain } from 'hooks/rewards/useUserClaimableEthRewardsAmount';
import { useTransaction } from 'data/transactions';
import { useKernelContract } from 'hooks/useContract';
import {
  failedClaimRewardsToast,
  successfulClaimRewardsToast,
} from 'components/utils/toastHelper';
import BiosIcon from 'assets/icons/assets/BiosIcon';
import { useUserClaimableBiosRewardsAmount } from 'hooks/rewards/useUserClaimableBiosRewardsAmount';
import useStrategyCount from 'hooks/strategies/useStrategyCount';
import { useQueryClient } from 'react-query';
import { useWeb3 } from '../../../../web3';

const AssetBalanceWithValue = ({
  Icon,
  balance,
  decimals,
  value,
  big,
  color,
  dashIfZero,
  hideValue = false,
  overwrite,
}: {
  Icon: any;
  balance: BigNumber | undefined;
  decimals: number | undefined;
  value: BigNumber;
  big?: boolean;
  color?: string;
  dashIfZero?: boolean;
  hideValue?: boolean;
  overwrite?: string;
}) => {
  return (
    <div className='flex gap-2'>
      <div className='flex flex-col justify-center w-8'>
        <div className='h-8 w 8'>
          <Icon color={networkColors[networks.ethereum]} />
        </div>
      </div>
      {overwrite ? (
        overwrite
      ) : (
        <div className='flex flex-col justify-center ml-2'>
          {dashIfZero && (!balance || balance.eq(0)) ? (
            <></>
          ) : (
            <div
              className={`text-${
                !balance || balance.eq(0) ? 'gray-light' : color || 'white'
              } text-${big ? '2xl' : 'lg'}`}
            >
              {assetFormat(balance ? formatUnits(balance, decimals) : 0)}
            </div>
          )}
          {!hideValue ? (
            <div className='text-gray-light text-sm'>
              {dashIfZero && (!balance || balance.eq(0))
                ? '---'
                : dollar(value.toNumber())}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const Line = ({
  width,
  height,
  left,
  right,
  top,
  bottom,
}: {
  width: string;
  height: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
}) => {
  const style: CSSProperties = {
    width,
    height,
  };
  left && (style.left = left);
  right && (style.right = right);
  top && (style.top = top);
  bottom && (style.bottom = bottom);
  return (
    <div
      style={style}
      className='absolute rounded-bl-md border-l border-b border-gray-light'
    ></div>
  );
};

export const OtherNetworkRewardBoxContainer = ({
  chainId,
}: {
  chainId: TChainId;
}) => {
  const queries = useUserClaimableEthRewardsAmountsByChain(chainId);
  const pendingRewards = queries.reduce<BigNumber | undefined>(
    (acc, each) => (acc && each.isSuccess ? acc.add(each.data) : undefined),
    BigNumber.from(0)
  );

  const { name, symbol, decimals } = nativeCurrencies[chainId];
  const { color, Icon } = networkStyling[chainId];
  return (
    <OtherNetworkRewardBox
      chainId={chainId}
      name={name}
      symbol={symbol}
      decimals={decimals}
      pendingRewards={pendingRewards}
      color={color}
      Icon={Icon}
    />
  );
};

export interface OtherNetworkRewardBoxProps {
  chainId: TChainId;
  name: string;
  symbol: TTokenSymbol;
  decimals: number;
  pendingRewards: BigNumber | undefined;
  color: string;
  Icon: any;
}

export const OtherNetworkRewardBox = ({
  chainId,
  name,
  symbol,
  decimals,
  pendingRewards,
  color,
  Icon,
}: OtherNetworkRewardBoxProps) => {
  return (
    <SmallContainer containerColor={color} className='border-dashed'>
      <div className='flex justify-between h-12'>
        <div className='flex '>
          <div style={{ width: '10rem' }}>
            <SlashPrefix textColor='green' className='text-lg'>
              {name}
            </SlashPrefix>
            <DotPrefix prefixColor='white' className='text-xs'>
              {symbol} rewards
            </DotPrefix>
          </div>
          <AssetBalanceWithValue
            Icon={Icon}
            balance={pendingRewards}
            decimals={decimals}
            value={ethers.BigNumber.from(1337)}
            hideValue
            dashIfZero
          />
        </div>
        <Button onClick={() => switchChainHelper(chainId)}>Connect</Button>
      </div>
    </SmallContainer>
  );
};

export const OtherNetworkRewardContainer = ({
  children,
}: {
  children: React.ReactNode[];
}) => {
  return (
    <div className='ml-12 relative'>
      <div className='grid grid-cols-1 gap-4'>{children}</div>
      <div className='absolute border-t border-l border-gray-light rounded-bl-md rounded-tl-md top-12 w-8 -left-8'>
        {children.map((x, i) => (
          <div
            className={`h-24 relative ${
              i === children.length - 1 ? '' : 'border-b border-gray-light'
            }`}
            key={i}
          >
            {i === children.length - 1 && (
              <div className='absolute border-b rounded-bl-md border-gray-light h-24 w-screen'></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export interface RewardBoxProps {
  openModal: Function;
  chainId: TChainId;
  userPendingRewards: BigNumber;
  userPendingRewardsValue: BigNumber;
  userRewardsEarned: BigNumber;
  userRewardsEarnedValue: BigNumber;
  roi: number;
}

export const RewardBox = ({
  openModal,
  chainId,
  userPendingRewards,
  userPendingRewardsValue,
  userRewardsEarned,
  userRewardsEarnedValue,
}: RewardBoxProps) => {
  const { account } = useWeb3();
  const { symbol, decimals } = nativeCurrencies[chainId];
  const { color, Icon } = networkStyling[chainId];
  const { data: strategyCount } = useStrategyCount();
  const kernel = useKernelContract();
  const queryClient = useQueryClient();

  // claim rewards
  const { contractCallNew, pending } = useTransaction();
  const claimAllRewards = () => {
    if (account && kernel && strategyCount && queryClient && !pending) {
      contractCallNew(
        () => kernel.claimEthRewards([...Array(strategyCount + 1).keys()]), // we include 0 for PFA
        'Claiming rewards',
        () =>
          failedClaimRewardsToast(chainId, userPendingRewards, {
            toastId: 'failed-claim-rewards',
          }),
        () =>
          successfulClaimRewardsToast(chainId, userPendingRewards, {
            toastId: 'succeeded-claim-rewards',
          }),
        undefined,
        () => queryClient.invalidateQueries([chainId, account, 'rewards'])
      );
    }
  };

  return (
    <div className='relative'>
      <Line width={'50vw'} height={'0px'} top='4rem' left='-50vw' />
      <SmallContainer containerColor={color} className='border-dashed'>
        <div className='flex justify-between'>
          <div>
            <div>
              (_Kernel){' '}
              <span className={`text-chain-${chainId}`}>{symbol}</span> rewards
            </div>
            <AssetBalanceWithValue
              big
              color={'green'}
              Icon={Icon}
              balance={userPendingRewards}
              value={userPendingRewardsValue}
              decimals={18}
              hideValue
            />
          </div>
          <div className='flex flex-col justify-center'>
            <Button
              secondary
              disabled={pending || userPendingRewards.eq(0)}
              onClick={claimAllRewards}
            >
              Claim {symbol} rewards
            </Button>
          </div>
        </div>
      </SmallContainer>
      <div className='flex flex-row-reverse'>
        <div style={{ width: 'calc(100% - 9rem)' }}>
          <Line width={'110px'} height={'70px'} left='35px' />
          <SmallContainer compact className='mt-6 bg-black'>
            <div className='flex justify-between'>
              <div>
                <SlashPrefix textColor='gray-light'>
                  Total {symbol} rewards claimed
                </SlashPrefix>
                <AssetBalanceWithValue
                  Icon={Icon}
                  balance={userRewardsEarned}
                  decimals={decimals}
                  value={userRewardsEarnedValue}
                  hideValue
                />
              </div>
              {/* TODO fix roi */}
              {/* <div className='flex flex-col justify-center'>
                <SlashPrefix textColor='gray-light'>ROI</SlashPrefix>
                {roi === 0 ? (
                  <div className='text-right text-gray-light'>---</div>
                ) : (
                  <div className='text-right '>+{roi.toString()}%</div>
                )}
              </div> */}
            </div>
          </SmallContainer>
        </div>
      </div>
    </div>
  );
};

export const BiosRewardBox = ({
  openModal,
  chainId,
  userPendingRewards,
  userPendingRewardsValue,
  userRewardsEarned,
  userRewardsEarnedValue,
}: RewardBoxProps) => {
  const { color } = networkStyling[chainId];
  const kernel = useKernelContract();
  const { invalidator } = useUserClaimableBiosRewardsAmount();

  // claim rewards
  const { contractCallNew, pending } = useTransaction();
  const claimBiosRewards = () => {
    if (kernel && !pending) {
      contractCallNew(
        () => kernel.claimBiosRewards(),
        'Claiming BIOS rewards',
        () =>
          failedClaimRewardsToast(chainId, userPendingRewards, {
            toastId: 'failed-claim-bios-rewards',
          }),
        () => {
          successfulClaimRewardsToast(chainId, userPendingRewards, {
            toastId: 'succeeded-claim-bios-rewards',
          });
          invalidator();
        }
      );
    }
  };

  return (
    <div className='relative mt-8'>
      <Line width={'50vw'} height={'0px'} top='4rem' left='-50vw' />
      <SmallContainer containerColor={color} className='border-dashed'>
        <div className='flex justify-between'>
          <div>
            <div>
              (_Kernel) <span className={`text-chain-${chainId}`}>BIOS</span>{' '}
              rewards
            </div>
            <AssetBalanceWithValue
              big
              color={'green'}
              Icon={BiosIcon}
              balance={userPendingRewards}
              value={userPendingRewardsValue}
              decimals={18}
              hideValue
            />
          </div>
          <div className='flex flex-col justify-center'>
            <Button
              secondary
              // onClick={() =>
              //   infoToast('wen BIOS emitter?', { toastId: 'wen-bios-emitter' })
              // }
              disabled={pending || userPendingRewards.eq(0)}
              onClick={claimBiosRewards}
            >
              claim BIOS rewards
            </Button>
          </div>
        </div>
      </SmallContainer>
      <div className='flex flex-row-reverse'>
        <div style={{ width: 'calc(100% - 9rem)' }}>
          <Line width={'110px'} height={'70px'} left='35px' />
          <SmallContainer compact className='mt-6 bg-black'>
            <div className='flex justify-between'>
              <div>
                <SlashPrefix textColor='gray-light'>
                  Total BIOS rewards claimed
                </SlashPrefix>
                <AssetBalanceWithValue
                  Icon={BiosIcon}
                  balance={userRewardsEarned}
                  decimals={18}
                  value={userRewardsEarnedValue}
                  hideValue
                  overwrite='{subgraph is indexing}'
                />
              </div>
              {/* TODO fix roi */}
              {/* <div className='flex flex-col justify-center'>
                <SlashPrefix textColor='gray-light'>ROI</SlashPrefix>
                {roi === 0 ? (
                  <div className='text-right text-gray-light'>---</div>
                ) : (
                  <div className='text-right '>+{roi.toString()}%</div>
                )}
              </div> */}
            </div>
          </SmallContainer>
        </div>
      </div>
    </div>
  );
};
