import { INetworkStats, IPFAStats } from '@0xnodes/shared/types';
import { assetFormat, percentFormat } from 'components/ui/numberFormats';
import { ArrowPrefix } from 'components/ui/prefixes/Prefix';
import SmallContainer from 'components/ui/smallContainer/SmallContainer';
import { formatEther } from 'ethers/lib/utils';

export interface PlatformFeeContainerProps {
  networkStats?: INetworkStats;
  pfaStats?: IPFAStats | null;
  showAPR?: boolean;
}

const PlatformFeeContainer = ({
  networkStats,
  pfaStats,
  showAPR = false,
}: PlatformFeeContainerProps) => (
  <SmallContainer title={'Protocol.fee accrual'}>
    <>
      <div className='flex'>
        <div className='mr-2 text-gray-light'>STAKED</div>
        <div className='text-white'>
          {pfaStats?.stakedAmount && pfaStats?.stakedAsset
            ? `${assetFormat(formatEther(pfaStats.stakedAmount), 0)} ${
                pfaStats.stakedAsset
              }`
            : '---'}
        </div>
      </div>
      <div className='flex'>
        <div className='mr-2 text-gray-light'>Rewards Paid</div>
        <div className='text-white'>
          {pfaStats?.rewardAmount && pfaStats?.rewardAsset
            ? `${assetFormat(formatEther(pfaStats.rewardAmount), 3)} ${
                pfaStats.rewardAsset
              }`
            : '---'}
        </div>
      </div>
      {showAPR ? (
        <div className='flex'>
          <ArrowPrefix centerPrefix>
            <div className='mr-2 text-2xl text-green'>
              {networkStats?.apr ? percentFormat(networkStats.apr) : '---'}
            </div>
          </ArrowPrefix>
          <div className='text-gray-light mt-1'>APR</div>
        </div>
      ) : null}
    </>
  </SmallContainer>
);

export default PlatformFeeContainer;
