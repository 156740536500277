import * as React from 'react';

export interface IconWithTextProps {
  text: React.ReactNode;
  subText: React.ReactNode;
  icon?: any;
  onClick?: () => {};
  disabled?: boolean;
  className?: string;
  textProps?: object;
  subTextProps?: object;
}

// TODO: find a better name for this component
const IconWithText = ({
  text,
  subText,
  icon,
  onClick,
  disabled = false,
  className,
  textProps,
  subTextProps,
}: IconWithTextProps) => {
  return (
    <div
      className={`flex ${onClick && !disabled ? 'cursor-pointer ' : ''}${
        className ? className : ''
      }`}
      onClick={onClick && !disabled ? onClick : undefined}
    >
      {icon && (
        <div className='flex flex-col justify-center'>
          <div className='h-10 mr-3'>{icon({})}</div>
        </div>
      )}
      <div className='flex flex-col justify-center text-white'>
        <div {...textProps}>{text}</div>
        <div className='text-gray-light' {...subTextProps}>
          {subText}
        </div>
      </div>
    </div>
  );
};

export default IconWithText;
