import { CSSProperties, ReactNode } from 'react';
import { SlashPrefix } from 'components/ui/prefixes/Prefix';
import linePattern from 'assets/images/linePattern.png';

export interface SmallContainerProps {
  title?: ReactNode;
  children: ReactNode;
  containerColor?: string;
  className?: string;
  titlePrefixColor?: string;
  compact?: boolean;
  borderColor?: string;
  lineBackground?: boolean;
}

const SmallContainer = ({
  title,
  children,
  containerColor,
  className,
  titlePrefixColor,
  compact,
  borderColor,
  lineBackground,
}: SmallContainerProps) => {
  let parentStyle: CSSProperties = {};
  let titleStyle: CSSProperties = {};
  if (containerColor) {
    parentStyle.borderColor = containerColor;
    parentStyle.backgroundColor = containerColor + '08';
    titleStyle.borderColor = containerColor;
  }

  if (lineBackground) parentStyle.background = `url(${linePattern}), #111111`;

  return (
    <div
      className={`${className ? className : ''} rounded-md border border-${
        borderColor ? borderColor : 'gray-light'
      } p-${compact ? '3' : '4'}`}
      style={parentStyle}
    >
      {title && (
        <div
          className={`border-b border-gray-light pb-${compact ? '3' : '4'} mb-${
            compact ? '3' : '4'
          }`}
          style={titleStyle}
        >
          <SlashPrefix
            className='text-white'
            prefixColor={titlePrefixColor ? titlePrefixColor : 'green'}
          >
            {title}
          </SlashPrefix>
        </div>
      )}
      <div className='text-white'>{children}</div>
    </div>
  );
};

export default SmallContainer;
