const NoSenseText = ({ className }: { className?: string }) => {
  return (
    <div className={`text-3xl ${className ? className : ''}`}>
      <div>{`7n?fauqhrg0w2d>fct.b.cekg9+. )9x [J^|`}</div>
      <div>
        {`X”1f+vtxia’,`}
        <span className='text-green'>risb^rsbx%^t4c,; .64%t</span>
        {`I3x`}
      </div>
      <div>{`=8cdi\rga:+nex8g5w^e:640=yxqzu4?>uvg.`}</div>
      <div>{`vp}~’gf?oa[`}</div>
    </div>
  );
};

export default NoSenseText;
